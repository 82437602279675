import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  agencyCountReport: {},
  agencyCountReportLoading: true,
  agencyCountReportRefresh: true,

  agreementBenefitReport: {},
  agreementBenefitReportLoading: true,
  agreementBenefitReportRefresh: true,

  isLoading: true,
};

export const fetchAgencyCountReport = createAsyncThunk(
  "dashboard/fetchAgencyCountReport",
  async () => {
    try {
      const response = await baseApi.get(`/ReportContoller/AgencyCountReport`);

      return response.data.Value;
    } catch (error) {
      throw new Error(error.response?.data?.Error?.Description || error.response?.status);
    }
  }
);

export const fetchAgreementBenefitReport = createAsyncThunk(
  "dashboard/fetchAgreementBenefitReport",
  async () => {
    try {
      const response = await baseApi.get(
        `/ReportContoller/AggrementBenefitReport`
      );

      return response.data.Value;
    } catch (error) {
      throw new Error(error.response?.data?.Error?.Description || error.response?.status);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgencyCountReport.pending, (state, action) => {
        state.agencyCountReport = {};
        state.agencyCountReportLoading = true;
      })
      .addCase(fetchAgencyCountReport.fulfilled, (state, action) => {
        state.agencyCountReport = action.payload;
        state.agencyCountReportLoading = false;
      })
      .addCase(fetchAgencyCountReport.rejected, (state, action) => {
        state.agencyCountReport = {};
        state.agencyCountReportLoading = false;
      });

    builder
      .addCase(fetchAgreementBenefitReport.pending, (state, action) => {
        state.agreementBenefitReport = {};
        state.agreementBenefitReportLoading = true;
      })
      .addCase(fetchAgreementBenefitReport.fulfilled, (state, action) => {
        state.agreementBenefitReport = action.payload;
        state.agreementBenefitReportLoading = false;
      })
      .addCase(fetchAgreementBenefitReport.rejected, (state, action) => {
        state.agreementBenefitReport = {};
        state.agreementBenefitReportLoading = false;
      });
  },
});

export default dashboardSlice.reducer;
