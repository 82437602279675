import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  user: {},
  userLoading: true,
  userRefresh: true,

  userProfile: {},
  userProfileLoading: true,
  userProfileRefresh: true,

  users: [],
  usersLoading: true,
  usersRefresh: true,

  salesmans: [],
  salesmansLoading: true,
  salesmansRefresh: true,

  roles: [],
  rolesLoading: true,
  rolesRefresh: true,

  createUserLoading: false,
  updateUserLoading: false,
};

export const fetchUser = createAsyncThunk("user/fetchUser", async (id) => {
  if (!id) throw new Error("Id bulunamadı!");

  const loadingToast = toast.loading("Yükleniyor...", {
    position: "top-center",
    transition: Slide,
  });

  try {
    const response = await baseApi.get(`/Account/GetUserById?userId=${id}`);

    toast.dismiss();

    return response.data.Value;
  } catch (error) {
    toast.update(loadingToast, {
      render: "Hata: " + error.response.data.Error.Description,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      transition: Slide,
      hideProgressBar: true,
    });

    throw new Error(
      error.response?.data?.Error?.Description || error.response?.status
    );
  }
});

export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    try {
      const response = await baseApi.get(`/Account/GetUserById?userId=${id}`);

      toast.dismiss();

      return response.data.Value;
    } catch (error) {
      toast.error("Hata: " + error.response.data.Error.Description, {
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  try {
    const response = await baseApi.get(`/Account/UserList`);

    return response.data.Value;
  } catch (error) {
    throw new Error(
      error.response?.data?.Error?.Description || error.response?.status
    );
  }
});

export const fetchSalesman = createAsyncThunk(
  "user/fetchSalesman",
  async () => {
    try {
      const response = await baseApi.get(`/Account/SalesmanList`);

      return response.data.Value;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchRoles = createAsyncThunk("user/fetchRoles", async () => {
  try {
    const response = await baseApi.get(`/Account/RoleList`);

    return response.data.Value;
  } catch (error) {
    throw new Error(
      error.response?.data?.Error?.Description || error.response?.status
    );
  }
});

export const createUser = createAsyncThunk(
  "user/createUser",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      email: values.email,
      nameSurname: values.nameSurname,
      password: values.password,
      userRole: values.userRole,
      smsAuthorized: values.smsAuthorized,
      phoneNumber: values.phoneNumber,
      salesmanCode: values.salesmanCode,
      ...(values.agencyId !== "" && { agencyId: parseInt(values.agencyId) }),
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Account/CreateUser`, data);

      toast.update(loadingToast, {
        render: "Kaydedildi.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      email: values.email,
      nameSurname: values.nameSurname,
      userRole: values.userRole,
      isActive: values.isActive,
      phoneNumber: values.phoneNumber,
      smsAuthorized: values.smsAuthorized,
      salesmanCode: values.salesmanCode,
      ...(values.agencyId !== "" && { agencyId: parseInt(values.agencyId) }),
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Account/UpdateUser`, data);

      toast.update(loadingToast, {
        render: "Kaydedildi.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      userId: values.userId,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Account/ChangePassword`, data);

      toast.update(loadingToast, {
        render: "Kaydedildi.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.user = {};
        state.userLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userLoading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.user = {};
        state.userLoading = false;
      });

    builder
      .addCase(fetchUserProfile.pending, (state, action) => {
        state.userProfile = {};
        state.userProfileLoading = true;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload;
        state.userProfileLoading = false;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.userProfile = {};
        state.userProfileLoading = false;
      });

    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.users = [];
        state.usersLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.usersLoading = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.users = [];
        state.usersLoading = false;
      });

    builder
      .addCase(fetchSalesman.pending, (state, action) => {
        state.salesmans = [];
        state.salesmansLoading = true;
      })
      .addCase(fetchSalesman.fulfilled, (state, action) => {
        state.salesmans = action.payload;
        state.salesmansLoading = false;
      })
      .addCase(fetchSalesman.rejected, (state, action) => {
        state.salesmans = [];
        state.salesmansLoading = false;
      });

    builder
      .addCase(fetchRoles.pending, (state, action) => {
        state.roles = [];
        state.rolesLoading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.rolesLoading = false;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.roles = [];
        state.rolesLoading = false;
      });

    builder
      .addCase(createUser.pending, (state, action) => {
        state.createUserLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.createUserLoading = false;
        state.usersRefresh = !state.usersRefresh;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUserLoading = false;
      });

    builder
      .addCase(updateUser.pending, (state, action) => {
        state.updateUserLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.usersRefresh = !state.usersRefresh;
        state.userProfileRefresh = !state.userProfileRefresh;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserLoading = false;
      });

    builder
      .addCase(changePassword.pending, (state, action) => {
        state.updateUserLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.usersRefresh = !state.usersRefresh;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.updateUserLoading = false;
      });
  },
});

export default userSlice.reducer;
