import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchRoles, fetchUsers } from "../../slices/apps/userSlice";
import {
  fetchAgencies,
  fetchSubAgencies,
} from "../../slices/apps/agencySlices";

// sayfalar, modallar vb.
import UserTable from "./UserTable";

const Users = () => {
  const dispatch = useDispatch();

  const { usersRefresh } = useSelector((state) => {
    return {
      usersRefresh: state.user.usersRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchUsers());
      await dispatch(fetchRoles());
      await dispatch(fetchAgencies());
      await dispatch(fetchSubAgencies());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, usersRefresh]);

  document.title = "Kullanıcı Listesi | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <UserTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
