import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// diğer importlar
import { Slide, toast } from "react-toastify";
import axios from "axios";

import baseApi from "../../api/baseApi";
const loginApi = "https://api.globalyolyardim.com";
// const loginApi = "http://192.168.50.12:68";

const initialState = {
  isUserData: null,
  authCheckLoading: true,
  authCheckRefresh: true,

  registerLoading: false,
  forgotPasswordLoading: false,
  phoneCodeCheckLoading: false,
  emailCodeCheckLoading: false,
  resetPasswordLoading: false,
  registerAgencyLoading: false,
  forgotPasswordLoading: false,

  // login
  user: {},
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

export const loginUser = (user, history) => async (dispatch) => {
  dispatch(loadingStart());

  const data = {
    ...user,
    twoFactorCode: "yufus",
    twoFactorRecoveryCode: "yufus",
  };

  const handleLogin = () => {
    axios
      .post(`${loginApi}/Account/Login`, data)
      .then(async (response) => {
        const responseData = {
          data: response.data.Value,
          status: "success",
          token: response.data.Value.AccessToken,
        };

        // const responseData = {
        //   data: response.data.result,
        //   status: "success",
        //   token: response.data.result.accessToken,
        // };

        localStorage.setItem("authUser", JSON.stringify(responseData));
        history("/dashboard");
        dispatch(loginSuccess(responseData));
      })
      .catch((err) => {
        dispatch(
          apiError(
            err?.response ? err.response.data.Error.Description : err.message
          )
        );
      });
  };
  try {
    handleLogin();
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const authCheck = createAsyncThunk("auth/authCheck", async () => {
  const user = localStorage.getItem("authUser");

  try {
    const response = await baseApi.get(`/Account/AuthCheck`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(user).token}`,
      },
    });

    return response.data.Value;
  } catch (error) {
    throw new Error(error);
  }
});

export const registerAgency = createAsyncThunk(
  "auth/registerAgency",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      agency: {
        id: 0,
        companyCode: "",
        companyName: values.companyName,
        phoneNumber: values.agencyPhoneNumber,
        email: values.agencyEmail,
        address: values.address,
        city: values.city,
        district: values.district,
        postCode: values.postCode.trim(),
        taxNumber: values.taxNumber.trim(),
        taxOffice: values.taxOffice,
        bankIban: values.bankIban,
        bankName: values.bankName,
        agencyType: parseInt(values.agencyType),
        isActive: false,
        isApproved: false,
        commissionRate: 20,
        referenceCode: values.referenceCode,
      },
      nameSurname: values.nameSurname,
      email: values.email,
      password: values.password,
      phoneNumber: values.phoneNumber,
    };

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Account/CreateAgency`, data);

      toast.update(loadingToast, {
        render: "Kayıt Başarılı.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (phoneNumber) => {
    if (!phoneNumber) throw new Error("Telefon numarası bulunamadı!");

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      transition: Slide,
    });

    try {
      const response = await baseApi.post(
        `/Account/ForgotPassword?phoneNumber=${phoneNumber}`
      );

      toast.update(loadingToast, {
        render: "Şifreniz SMS ile gönderildi.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: error?.response.data?.Error
          ? `Hata: ${error.response.data.Error.Description}`
          : "Hata: Diğer hatalar!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state) {
      state.isUserLogout = true;
    },
    loadingStart(state) {
      state.loading = true;
    },
    reset_login_flag(state) {
      state.error = null;
      state.loading = false;
      state.errorMsg = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authCheck.pending, (state, action) => {
        state.isUserData = null;
        state.authCheckLoading = true;
      })
      .addCase(authCheck.fulfilled, (state, action) => {
        state.isUserData = action.payload;
        state.authCheckLoading = false;
      })
      .addCase(authCheck.rejected, (state, action) => {
        state.isUserData = null;
        state.authCheckLoading = false;
      });

    builder
      .addCase(registerAgency.pending, (state, action) => {
        state.registerAgencyLoading = true;
      })
      .addCase(registerAgency.fulfilled, (state, action) => {
        state.registerAgencyLoading = false;
      })
      .addCase(registerAgency.rejected, (state, action) => {
        state.registerAgencyLoading = false;
      });

    builder
      .addCase(forgotPassword.pending, (state, action) => {
        state.forgotPasswordLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordLoading = false;
      });
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  loadingStart,
} = authSlice.actions;

export default authSlice.reducer;
