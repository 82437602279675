import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Row, Table } from "reactstrap";

// react table
import {
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// router
import { Link } from "react-router-dom";

// Column Definitions
const columnHelper = createColumnHelper();

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      id="search-bar-0"
      className="form-control border-0 search"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const EarningAgreementTable = ({ data }) => {
  // Hooks
  const columns = useMemo(
    () => [
      columnHelper.accessor("AggreementName", {
        header: "Paket Adı",
        size: "auto",
        minSize: 250,
        maxSize: 350,
        enableResizing: false,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fs-12 fw-semibold">
              {row.original.AggreementName}
            </div>
          );
        },
      }),

      columnHelper.accessor("CarPlate", {
        header: "Plaka",
        size: 120,
        minSize: 120,
        maxSize: 120,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-medium fs-12">
              {row.original.CarPlate}
            </div>
          );
        },
      }),

      columnHelper.accessor("EarningAmount", {
        header: "Hak Ediş",
        size: 120,
        minSize: 120,
        maxSize: 120,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-medium fs-12">
              <b className="text-dark">
                {new Intl.NumberFormat("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(row.original.EarningAmount)}{" "}
                TL
              </b>
            </div>
          );
        },
      }),

      columnHelper.accessor("TotalAmount", {
        header: "Fiyat",
        size: 120,
        minSize: 120,
        maxSize: 120,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-medium fs-12">
              <b className="text-dark">
                {new Intl.NumberFormat("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(row.original.TotalAmount)}{" "}
                TL
              </b>
            </div>
          );
        },
      }),
    ],
    []
  );

  // Hooks
  const table = useReactTable({
    data: data || [],
    columns,
    state: {},
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
  } = table;

  document.title = "Ödeme Talepleri Listesi | Global Yol Yardım";
  return (
    <Fragment>
      <div
        style={{
          overflowX: "auto",
        }}
      >
        <Table>
          <thead>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="text-muted"
                    style={{
                      width: header.column.columnDef.size,
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                      letterSpacing: "0.8px",
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <i className="ri-arrow-down-s-line"></i>,
                          desc: <i className="ri-arrow-up-s-line"></i>,
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {data?.length === 0 ? (
              <tr>
                <td colSpan="100%" className="text-center pt-5 pb-5 text-muted">
                  <h1>
                    <i className="ri-error-warning-line text-muted"></i>
                  </h1>
                  <p>Kayıt bulunamadı!</p>
                </td>
              </tr>
            ) : (
              getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          style={{
                            width: cell.column.columnDef.size,
                            minWidth: cell.column.columnDef.minSize,
                            maxWidth: cell.column.columnDef.maxSize,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>

      <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            {data?.length > 0
              ? `Toplam ${table.getFilteredRowModel().rows.length} kayıttan ${
                  table.getFilteredRowModel().rows.length === 0
                    ? 0
                    : table.getState().pagination.pageIndex *
                        table.getState().pagination.pageSize +
                      1
                }
                  ile ${Math.min(
                    (table.getState().pagination.pageIndex + 1) *
                      table.getState().pagination.pageSize,
                    table.getFilteredRowModel().rows.length
                  )} arası gösteriliyor`
              : "Kayıt Yok"}
          </div>
        </div>
        {data?.length > 0 && (
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li
                className={
                  !getCanPreviousPage() ? "page-item disabled" : "page-item"
                }
              >
                <Link to="#" className="page-link" onClick={previousPage}>
                  {"<"}
                </Link>
              </li>
              {getPageOptions().map((item, key) => {
                const pageIndex = getState().pagination.pageIndex;
                const totalPages = getPageOptions().length;
                const firstPage = item === 0;
                const lastPage = item === totalPages - 1;
                const nearCurrentPage = Math.abs(pageIndex - item) <= 1;

                // Display logic
                if (firstPage || lastPage || nearCurrentPage) {
                  return (
                    <React.Fragment key={key}>
                      <li className="page-item">
                        <Link
                          to="#"
                          className={
                            pageIndex === item
                              ? "page-link active"
                              : "page-link"
                          }
                          onClick={() => setPageIndex(item)}
                        >
                          {item + 1}
                        </Link>
                      </li>
                    </React.Fragment>
                  );
                } else if (item === 1 && pageIndex > 2) {
                  return null;
                } else if (
                  item === totalPages - 2 &&
                  pageIndex < totalPages - 3
                ) {
                  return null;
                } else {
                  return null;
                }
              })}
              <li
                className={
                  !getCanNextPage() ? "page-item disabled" : "page-item"
                }
              >
                <Link to="#" className="page-link" onClick={nextPage}>
                  {">"}
                </Link>
              </li>
            </ul>
          </div>
        )}
      </Row>
    </Fragment>
  );
};

export default EarningAgreementTable;
