import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Progress,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

// react table
import {
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAgreements } from "../../slices/apps/agreementSlice";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// react select
import Select from "react-select";

// router
import { Link } from "react-router-dom";

// diğer importlar
import moment from "moment/moment";

// sayfalar, modallar vb.
import AgreementUpdateModal from "./AgreementUpdateModal";
import AgreementViewModal2 from "./AgreementViewModal2";
import AgreementViewModal from "./AgreementViewModal";
import AgreementPayModal from "./AgreementPayModal";
import AgreementSmsModal from "./AgreementSmsModal";
import AgreementInvoiceModal from "./AgreementInvoiceModal";
import AgreementViewInvoiceModal from "./AgreementViewInvoiceModal";
import AgreementPassiveModal from "./AgreementPassiveModal";
import AgreementPayApproveModal from "./AgreementPayApproveModal";

// değişkenler
const paymentTypes = { 0: "KART", 1: "EFT", 2: "HEDİYE" };

// Column Definitions
const columnHelper = createColumnHelper();

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      id="search-bar-0"
      className="form-control border-0 search"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const AgreementTable = () => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  const { agreements, agreementsLoading } = useSelector((state) => {
    return {
      agreements: state.agreement.agreements,
      agreementsLoading: state.agreement.agreementsLoading,
    };
  }, shallowEqual);

  // States
  const [data, setData] = useState([]); // table için data dosyası
  const [globalFilter, setGlobalFilter] = useState(""); // arama çubuğu

  const [filterAgency, setFilterAgency] = useState(""); // Acente filtresi

  // filtreleme işlemi için
  useEffect(() => {
    setData([]);

    const filteredData = agreements?.filter((data) => {
      if (
        filterAgency.toString() &&
        data.AgencyId.toString() != filterAgency.toString()
      )
        return false;
      if (
        globalFilter &&
        !Object.values(data).some(
          (value) =>
            typeof value === "string" &&
            (value
              .toLocaleLowerCase()
              .includes(globalFilter.toLocaleLowerCase()) ||
              value
                .toLocaleUpperCase()
                .includes(globalFilter.toLocaleUpperCase()))
        )
      )
        return false;
      return true;
    });

    setData(filteredData);
  }, [globalFilter, filterAgency, agreements]);

  // Hooks
  const columns = useMemo(
    () => [
      columnHelper.accessor("actions", {
        header: "",
        size: 120,
        minSize: 120,
        maxSize: 120,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
              <AgreementSmsModal id={row.original.Id} />
              {!row.original.IsPaid ? (
                <AgreementPayModal id={row.original.Id} />
              ) : (
                <AgreementViewModal id={row.original.Id} />
              )}
              <AgreementUpdateModal id={row.original.Id} />
              {row.original.IsPaid && (
                <AgreementPassiveModal
                  id={row.original.Id}
                  isActive={row.original.IsActive}
                />
              )}
              <AgreementInvoiceModal
                id={row.original.Id}
                isDisabled={
                  row.original.InvoiceUUID != null ||
                  !row.original.IsActive ||
                  !row.original.IsPaid ||
                  row.original.PaymentType == 2
                    ? true
                    : false
                }
              />
              <AgreementViewInvoiceModal id={row.original.Id} />
              {userProfile.data.Role == "SuperAdmin" &&
                !row.original.IsPaid &&
                row.original.PaymentType == 1 && (
                  <AgreementPayApproveModal id={row.original.Id} />
                )}
            </div>
          );
        },
      }),
      columnHelper.accessor("PaymentType", {
        header: "",
        size: 120,
        minSize: 120,
        maxSize: 120,
        cell: ({ row }) => {
          return (
            <div>
              <div className="text-truncate  fw-bold fs-12 ">
                <i className="bx bxs-circle align-middle me-1 fs-14 text-dark"></i>
                {paymentTypes[row.original.PaymentType]}
              </div>

              {row.original.IsPaid ? (
                <div className="text-truncate  fw-bold fs-12 ">
                  <i className="bx bxs-check-circle align-middle me-1 fs-14 text-info"></i>
                  Ödendi
                </div>
              ) : (
                <div className="text-truncate fw-bold fs-12 ">
                  <i className="bx bxs-info-circle align-middle me-1 fs-14 text-danger"></i>
                  Ödenmedi
                </div>
              )}
              {row.original.IsActive ? (
                <div className="text-truncate  fw-bold fs-12 ">
                  <i className="bx bxs-circle align-middle me-1 fs-14 text-success"></i>
                  Aktif
                </div>
              ) : (
                <div className="text-truncate fw-bold fs-12 ">
                  <i className="bx bxs-circle align-middle me-1 fs-14 text-warning"></i>
                  Pasif
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("AggreementName", {
        header: "Sözleşme Bilgileri",
        size: "auto",
        minSize: 250,
        maxSize: 350,
        enableResizing: false,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fs-16 fw-semibold">
              {row.original.AggreementName}
              <div className="text-truncate fw-medium fs-12">
                {row.original.AggrementNo} -{" "}
                {moment(row.original.CreateDatetime).format("DD.MM.YYYY")}
              </div>
            </div>
          );
        },
      }),

      columnHelper.accessor("Earning", {
        header: "Hak Ediş",
        size: 120,
        minSize: 120,
        maxSize: 120,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-medium fs-12">
              <b className="text-dark">
                {new Intl.NumberFormat("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(row.original.Earning)}{" "}
                TL
              </b>
            </div>
          );
        },
      }),

      columnHelper.accessor("Price", {
        header: "Fiyat",
        size: 120,
        minSize: 120,
        maxSize: 120,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-medium fs-12">
              <b className="text-dark">
                {new Intl.NumberFormat("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(row.original.Price)}{" "}
                TL
              </b>
            </div>
          );
        },
      }),

      columnHelper.accessor("AgencyName", {
        header: "Acente",
        size: 150,
        minSize: 150,
        maxSize: 150,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-semibold fs-12">
              {row.original.AgencyName ? row.original.AgencyName : "-"}
            </div>
          );
        },
      }),

      columnHelper.accessor("CustomerName", {
        header: "Müşteri Bilgiler",
        size: 200,
        minSize: 200,
        maxSize: 200,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-medium fs-12">
              <div className="text-truncate">
                <i
                  className="bx bxs-user align-middle text-dark me-1"
                  id="tooltipTopActive"
                ></i>
                <b className="text-dark">{row.original.CustomerName}</b>
              </div>
              <div className="text-truncate">
                <i className="mdi mdi-phone align-middle text-dark me-1"></i>
                <b className="text-dark">
                  +90{" "}
                  {row.original.PhoneNumber?.toString().replace(
                    /(\d{3})(\d{3})(\d{2})(\d{2})/,
                    "$1 $2 $3 $4"
                  )}
                </b>
              </div>
            </div>
          );
        },
      }),

      columnHelper.accessor("CarCategory", {
        header: "Araç Bilgiler",
        size: 250,
        minSize: 250,
        maxSize: 250,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-semibold">
              <div className="text-truncate fw-medium fs-12">
                <i
                  className="bx bxs-category align-middle text-dark me-1"
                  id="tooltipTopActive"
                ></i>
                <b className="text-dark">
                  {row.original.CarCategory ? row.original.CarCategory : "-"}
                </b>
              </div>
              <div className="text-truncate fw-medium fs-12">
                <i
                  className="bx bxs-car align-middle text-dark me-1"
                  id="tooltipTopActive"
                ></i>
                <b className="text-dark">
                  {row.original.CarBrand} - {row.original.CarModel}
                </b>
              </div>
              <div className="text-truncate fw-medium fs-12">
                <i
                  className="bx bxs-right-arrow align-middle text-dark me-1"
                  id="tooltipTopActive"
                ></i>
                <b className="text-dark">{row.original.CarPlate}</b>
              </div>
              <div className="text-truncate fw-medium fs-12">
                <i
                  className="mdi mdi-calendar-month align-middle text-dark me-1"
                  id="tooltipTopActive"
                ></i>
                <b className="text-dark">{row.original.CarYear}</b>
              </div>
            </div>
          );
        },
      }),

      columnHelper.accessor("StartDate", {
        header: "Tarih",
        size: 180,
        minSize: 180,
        maxSize: 180,
        cell: ({ row }) => {
          const startDate = moment(row.original.StartDate);
          const endDate = moment(row.original.EndDate);
          const today = moment();

          const hasStarted = today.isSameOrAfter(startDate);

          if (!hasStarted) {
            const daysUntilStart = startDate.diff(today, "days"); // Başlamasına kalan gün sayısı
            return (
              <div className="text-truncate fw-semibold">
                <div className="d-flex fw-medium fs-12 text-muted">
                  <i
                    className="ri-calendar-2-fill align-middle text-success me-1"
                    id="tooltipTopActive"
                  ></i>
                  <div style={{ width: "65px" }}>Başlangıç:</div>
                  <b className="text-dark">
                    {moment(row.original.StartDate).format("DD.MM.YYYY")}
                  </b>
                </div>
                <div className="d-flex fw-medium fs-12 text-muted">
                  <i
                    className="ri-calendar-2-fill align-middle text-danger me-1"
                    id="tooltipTopActive"
                  ></i>
                  <div style={{ width: "65px" }}>Bitiş:</div>
                  <b className="text-dark">
                    {moment(row.original.EndDate).format("DD.MM.YYYY")}
                  </b>
                </div>
                <div className="d-flex fw-medium fs-12 text-muted">
                  <i
                    className="ri-error-warning-fill align-middle text-warning me-1"
                    id="tooltipTopActive"
                  ></i>
                  <b className="text-warning">Henüz başlamadı</b>
                </div>
              </div>
            );
          }

          const totalDays = endDate.diff(startDate, "days");
          const remainingDays = endDate.diff(today, "days");
          const percentage = (remainingDays / totalDays) * 100;

          const getColor = () => {
            if (percentage <= 30) return "danger";
            if (percentage <= 70) return "warning";
            return "success";
          };

          return (
            <div className="text-truncate fw-semibold">
              <div className="d-flex fw-medium fs-12 text-muted">
                <i
                  className="ri-calendar-2-fill align-middle text-success me-1"
                  id="tooltipTopActive"
                ></i>
                <div style={{ width: "65px" }}>Başlangıç:</div>
                <b className="text-dark">
                  {moment(row.original.StartDate).format("DD.MM.YYYY")}
                </b>
              </div>
              <div className="d-flex fw-medium fs-12 text-muted">
                <i
                  className="ri-calendar-2-fill align-middle text-danger me-1"
                  id="tooltipTopActive"
                ></i>
                <div style={{ width: "65px" }}>Bitiş:</div>
                <b className="text-dark">
                  {moment(row.original.EndDate).format("DD.MM.YYYY")}
                </b>
              </div>
              <div className="d-flex fw-medium fs-12 text-muted">
                <i
                  className="ri-indeterminate-circle-fill align-middle text-info me-1"
                  id="tooltipTopActive"
                ></i>
                <div style={{ width: "65px" }}>Kalan:</div>
                <b className="text-dark">{remainingDays} Gün</b>
              </div>
              <Progress
                color={getColor()}
                value={percentage}
                striped
                animated
                className="mt-2"
                style={{ height: "8px" }}
              />
            </div>
          );
        },
      }),
    ],
    []
  );

  // Hooks
  const table = useReactTable({
    data: data,
    columns,
    state: {},
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
  } = table;

  return (
    <Fragment>
      {/* <AgencyAddModal modal={addModal} setModal={setAddModal} /> */}
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Sözleşme Listesi</h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown">
              <DropdownToggle
                tag="a"
                className="text-reset"
                role="button"
                caret
              >
                İşlemler
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => dispatch(fetchAgreements())}>
                  <i className="ri-refresh-line label-icon align-middle me-2"></i>
                  Yenile
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>
        <CardBody>
          <Row className="mb-3">
            <CardBody className="border border-dashed border-end-0 border-start-0 border-top-0">
              <form>
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
                    <div style={{ width: "100px" }}>
                      <select
                        className="form-select mb-3"
                        aria-label="yufus"
                        value={getState().pagination.pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        <option defaultValue="10">10</option>
                        <option defaultValue="25">25</option>
                        <option defaultValue="50">50</option>
                        <option defaultValue="100">100</option>
                        <option defaultValue="200">200</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div
                      className="search-box me-2 mb-2 d-inline-block border"
                      style={{ borderRadius: "0.25em" }}
                    >
                      <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(value)}
                        placeholder={"Ara..."}
                      />
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </form>
              <Row>
                {userProfile.data.Role == "SuperAdmin" && (
                  <Col md={6} lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="agenciesSelect" className="form-label">
                        Acente
                      </Label>
                      <Select
                        placeholder="Seçiniz"
                        id="agenciesSelect"
                        name="agencies"
                        options={Array.from(
                          new Map(
                            agreements.map((item) => [item.AgencyId, item])
                          ).values()
                        ).map((item) => ({
                          label: item.AgencyName,
                          value: item.AgencyId,
                        }))}
                        isClearable
                        noOptionsMessage={() => "Seçenek bulunamadı"}
                        onChange={(value) => {
                          setFilterAgency(value ? value.value : "");
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Row>

          <div
            style={{
              overflowX: "auto",
            }}
          >
            <Table>
              <thead>
                {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="text-muted"
                        style={{
                          width: header.column.columnDef.size,
                          minWidth: header.column.columnDef.minSize,
                          maxWidth: header.column.columnDef.maxSize,
                          letterSpacing: "0.8px",
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              userSelect: "none",
                            }}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <i className="ri-arrow-down-s-line"></i>,
                              desc: <i className="ri-arrow-up-s-line"></i>,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {agreementsLoading ? (
                  <tr>
                    <td colSpan="100%" className="text-center pt-5 pb-5">
                      <Spinner color="primary" />
                      <p className="text-dark">Yükleniyor...</p>
                    </td>
                  </tr>
                ) : data.length === 0 ? (
                  <tr>
                    <td
                      colSpan="100%"
                      className="text-center pt-5 pb-5 text-muted"
                    >
                      <h1>
                        <i className="ri-error-warning-line text-muted"></i>
                      </h1>
                      <p>Kayıt bulunamadı!</p>
                    </td>
                  </tr>
                ) : (
                  getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              style={{
                                width: cell.column.columnDef.size,
                                minWidth: cell.column.columnDef.minSize,
                                maxWidth: cell.column.columnDef.maxSize,
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>

          <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
            <div className="col-sm">
              <div className="text-muted">
                {data.length > 0
                  ? `Toplam ${
                      table.getFilteredRowModel().rows.length
                    } kayıttan ${
                      table.getFilteredRowModel().rows.length === 0
                        ? 0
                        : table.getState().pagination.pageIndex *
                            table.getState().pagination.pageSize +
                          1
                    }
                  ile ${Math.min(
                    (table.getState().pagination.pageIndex + 1) *
                      table.getState().pagination.pageSize,
                    table.getFilteredRowModel().rows.length
                  )} arası gösteriliyor`
                  : "Kayıt Yok"}
              </div>
            </div>
            {data.length > 0 && (
              <div className="col-sm-auto">
                <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                  <li
                    className={
                      !getCanPreviousPage() ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link to="#" className="page-link" onClick={previousPage}>
                      {"<"}
                    </Link>
                  </li>
                  {getPageOptions().map((item, key) => {
                    const pageIndex = getState().pagination.pageIndex;
                    const totalPages = getPageOptions().length;
                    const firstPage = item === 0;
                    const lastPage = item === totalPages - 1;
                    const nearCurrentPage = Math.abs(pageIndex - item) <= 1;

                    // Display logic
                    if (firstPage || lastPage || nearCurrentPage) {
                      return (
                        <React.Fragment key={key}>
                          <li className="page-item">
                            <Link
                              to="#"
                              className={
                                pageIndex === item
                                  ? "page-link active"
                                  : "page-link"
                              }
                              onClick={() => setPageIndex(item)}
                            >
                              {item + 1}
                            </Link>
                          </li>
                        </React.Fragment>
                      );
                    } else if (item === 1 && pageIndex > 2) {
                      return null;
                    } else if (
                      item === totalPages - 2 &&
                      pageIndex < totalPages - 3
                    ) {
                      return null;
                    } else {
                      return null;
                    }
                  })}
                  <li
                    className={
                      !getCanNextPage() ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link to="#" className="page-link" onClick={nextPage}>
                      {">"}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AgreementTable;
