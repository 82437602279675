import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAgencies } from "../../slices/apps/agencySlices";
import { fetchSalesman } from "../../slices/apps/userSlice";

// sayfalar, modallar vb.
import AgencyTable from "./AgencyTable";

const Agencies = () => {
  const dispatch = useDispatch();

  const { agenciesRefresh } = useSelector((state) => {
    return {
      agenciesRefresh: state.agency.agenciesRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchAgencies());
      await dispatch(fetchSalesman());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, agenciesRefresh]);

  document.title = "Acente Listesi | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <AgencyTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Agencies;
