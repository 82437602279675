import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  agreement: {},
  agreementLoading: true,
  agreementRefresh: true,

  agreementDetails: {},
  agreementDetailsLoading: true,
  agreementDetailsRefresh: true,

  agreements: [],
  agreementsLoading: true,
  agreementsRefresh: true,

  agreementsAgency: [],
  agreementsAgencyLoading: true,
  agreementsAgencyRefresh: true,

  agreementInvoice: null,
  agreementInvoiceLoading: true,
  agreementInvoiceRefresh: true,

  createAgreementLoading: false,
  updateAgreementLoading: false,
  deleteAgreementLoading: false,
  payAgreementLoading: false,
  resendAgreementSmsLoading: false,
  resendAgreementInvoiceLoading: false,
  passiveAgreementLoading: false,
  payApproveAgreementLoading: false,
};

export const fetchAgreement = createAsyncThunk(
  "agreement/fetchAgreement",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.get(`/Aggreement/${id}`);

      toast.dismiss();

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchAgreementDetails = createAsyncThunk(
  "agreement/fetchAgreementDetails",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.get(
        `/Aggreement/GetAggreementWithUserInfo?AggreementId=${id}`
      );

      toast.dismiss();

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchAgreements = createAsyncThunk(
  "agreement/fetchAgreements",
  async () => {
    try {
      const response = await baseApi.get(`/Aggreement`);

      return response.data.Value;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

// pasif
export const createAgreement = createAsyncThunk(
  "agreement/createAgreement",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: 0,
      agreementName: values.agreementName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      isActive: true,
      taxNumber: values.taxNumber.trim(),
      taxOffice: values.taxOffice,
      address: values.address,
      agencyId: values.agencyId,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Aggreement`, data);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const updateAgreement = createAsyncThunk(
  "agreement/updateAgreement",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      aggrementNo: values.aggrementNo,
      aggreementName: values.aggreementName,
      createDatetime: values.createDatetime,
      description: values.description,
      startDate: values.startDate,
      endDate: values.endDate,
      price: values.price,
      isPaid: values.isPaid,
      isActive: values.isActive,
      earning: values.earning,
      paymentOrderNumber: values.paymentOrderNumber,
      posTransactionID: values.posTransactionID,

      totalLimit: values.totalLimit,
      towingLimit: values.towingLimit,
      ageLimit: values.ageLimit,
      accident: values.accident,
      fault: values.fault,
      tire: values.tire,
      fuel: values.fuel,
      totalRight: values.totalRight,

      agencyId: values.agencyId,
      customerId: values.customerId,

      carCategory: values.carCategory,
      carBrand: values.carBrand,
      carModel: values.carModel,
      carPlate: values.carPlate,
      carYear: values.carYear,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Aggreement/${values.id}`, data);

      toast.update(loadingToast, {
        render: "Kaydedildi.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: error?.response.data?.Error
          ? `Hata: ${error.response.data.Error.Description}`
          : "Hata: Diğer hatalar!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

// pasif
export const deleteAgreement = createAsyncThunk(
  "agreement/deleteAgreement",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.delete(`/Aggreement/${id}`);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const createPayAgreement = createAsyncThunk(
  "agreement/createPayAgreement",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      cardHolderName: values.cardHolderName,
      cardNumber: values.cardNumber,
      expMonth: values.expMonth,
      expYear: values.expYear,
      cvc: values.cvc,
      installmentNumber: values.installmentNumber,
      posId: values.posId,
      plate: values.plate,
      modelYear: values.modelYear,
      usageType: values.usageType,
      brand: values.brand,
      model: values.model,
      customerName: values.customerName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      taxNumber: values.taxNumber.trim(),
      taxOffice: values.taxOffice,
      city: values.city,
      district: values.district,
      address: values.address,
      agencyId: values.agencyId,
      packageId: values.packageId,
      description: values.description,
      customerId: values.customerId,
      appType: "app",
      isSecure: values.isSecure,
      paymentType: parseInt(values.paymentType),
    };

    const loadingToast = toast.loading("Lütfen bekleyin!", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Aggreement/CreateAggreement`, data);

      if (parseInt(values.paymentType) == 0) {
        if (values.isSecure) {
          document.open();
          document.write(response.data.Value);
          document.close();
        } else {
          window.location.href = response.data.Value;
        }
      }

      toast.dismiss(loadingToast);

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        JSON.stringify(error.response?.data?.Error) || error.response?.status
      );
    }
  }
);

export const payAgreement = createAsyncThunk(
  "agreement/payAgreement",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      cardHolderName: values.cardHolderName,
      cardNumber: values.cardNumber,
      expMonth: values.expMonth,
      expYear: values.expYear,
      cvc: values.cvc,
      installmentNumber: values.installmentNumber,
      posId: values.posId,
      agreementId: values.agreementId,
      appType: "app",
      isSecure: values.isSecure,
      paymentType: parseInt(values.paymentType),
    };

    const loadingToast = toast.loading("Lütfen bekleyin!", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Aggreement/PayAgreement`, data);

      if (parseInt(values.paymentType) == 0) {
        if (values.isSecure) {
          document.open();
          document.write(response.data.Value);
          document.close();
        } else {
          window.location.href = response.data.Value;
        }
      }

      toast.dismiss(loadingToast);

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const resendAgreementSms = createAsyncThunk(
  "agreement/resendAgreementSms",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(
        `/Aggreement/ResendAgreementSms?AgreementId=${id}`
      );

      toast.update(loadingToast, {
        render: response.data.Value,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const resendAgreementInvoice = createAsyncThunk(
  "agreement/resendAgreementInvoice",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(
        `/Aggreement/ResendAgreementInvoice?AgreementId=${id}`
      );

      toast.update(loadingToast, {
        render: response.data.Value,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchAgreementInvoice = createAsyncThunk(
  "agreement/fetchAgreementInvoice",
  async (values) => {
    if (!values) throw new Error("Veri bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(
        `/Aggreement/GetAgreementInvoice?AgreementId=${values.id}&ContentType=${values.type}`
      );

      toast.dismiss();

      const byteCharacters = atob(response.data.Value);
      const byteNumbers = new Array(byteCharacters.length)
        .fill()
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blob);

      window.open(fileURL, "_blank");

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const passiveAgreement = createAsyncThunk(
  "agreement/passiveAgreement",
  async (values) => {
    if (!values) throw new Error("Veri bulunamadı!");

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    const data = {
      agreementId: values.agreementId,
      isActive: values.isActive,
    };

    try {
      const response = await baseApi.post(
        `/Aggreement/SetStatusAgreement`,
        data
      );

      toast.update(loadingToast, {
        render: response.data.Value,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const payApproveAgreement = createAsyncThunk(
  "agreement/payApproveAgreement",
  async (values) => {
    if (!values) throw new Error("Veri bulunamadı!");

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    const data = {
      agreementId: values.agreementId,
      isPaid: true,
    };

    try {
      const response = await baseApi.post(
        `/Aggreement/SetPaymentStatusAgreement`,
        data
      );

      toast.update(loadingToast, {
        render: response.data.Value,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const agreementSlice = createSlice({
  name: "agreement",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgreement.pending, (state, action) => {
        state.agreement = {};
        state.agreementLoading = true;
      })
      .addCase(fetchAgreement.fulfilled, (state, action) => {
        state.agreement = action.payload;
        state.agreementLoading = false;
      })
      .addCase(fetchAgreement.rejected, (state, action) => {
        state.agreement = {};
        state.agreementLoading = false;
      });

    builder
      .addCase(fetchAgreementDetails.pending, (state, action) => {
        state.agreementDetails = {};
        state.agreementDetailsLoading = true;
      })
      .addCase(fetchAgreementDetails.fulfilled, (state, action) => {
        state.agreementDetails = action.payload;
        state.agreementDetailsLoading = false;
      })
      .addCase(fetchAgreementDetails.rejected, (state, action) => {
        state.agreementDetails = {};
        state.agreementDetailsLoading = false;
      });

    builder
      .addCase(fetchAgreements.pending, (state, action) => {
        state.agreements = [];
        state.agreementsLoading = true;
      })
      .addCase(fetchAgreements.fulfilled, (state, action) => {
        state.agreements = action.payload;
        state.agreementsLoading = false;
      })
      .addCase(fetchAgreements.rejected, (state, action) => {
        state.agreements = [];
        state.agreementsLoading = false;
      });

    builder
      .addCase(createAgreement.pending, (state, action) => {
        state.createAgreementLoading = true;
      })
      .addCase(createAgreement.fulfilled, (state, action) => {
        state.createAgreementLoading = false;
        state.agreementsRefresh = !state.agreementsRefresh;
      })
      .addCase(createAgreement.rejected, (state, action) => {
        state.createAgreementLoading = false;
      });

    builder
      .addCase(updateAgreement.pending, (state, action) => {
        state.updateAgreementLoading = true;
      })
      .addCase(updateAgreement.fulfilled, (state, action) => {
        state.updateAgreementLoading = false;
        state.agreementsRefresh = !state.agreementsRefresh;
      })
      .addCase(updateAgreement.rejected, (state, action) => {
        state.updateAgreementLoading = false;
      });

    builder
      .addCase(deleteAgreement.pending, (state, action) => {
        state.deleteAgreementLoading = true;
      })
      .addCase(deleteAgreement.fulfilled, (state, action) => {
        state.deleteAgreementLoading = false;
        state.agreementsRefresh = !state.agreementsRefresh;
      })
      .addCase(deleteAgreement.rejected, (state, action) => {
        state.deleteAgreementLoading = false;
      });

    builder
      .addCase(payAgreement.pending, (state, action) => {
        state.payAgreementLoading = true;
      })
      .addCase(payAgreement.fulfilled, (state, action) => {
        state.payAgreementLoading = false;
        state.agreementsRefresh = !state.agreementsRefresh;
      })
      .addCase(payAgreement.rejected, (state, action) => {
        state.payAgreementLoading = false;
      });

    builder
      .addCase(resendAgreementSms.pending, (state, action) => {
        state.resendAgreementSmsLoading = true;
      })
      .addCase(resendAgreementSms.fulfilled, (state, action) => {
        state.resendAgreementSmsLoading = false;
      })
      .addCase(resendAgreementSms.rejected, (state, action) => {
        state.resendAgreementSmsLoading = false;
      });

    builder
      .addCase(resendAgreementInvoice.pending, (state, action) => {
        state.resendAgreementInvoiceLoading = true;
      })
      .addCase(resendAgreementInvoice.fulfilled, (state, action) => {
        state.resendAgreementInvoiceLoading = false;
        state.agreementsRefresh = !state.agreementsRefresh;
      })
      .addCase(resendAgreementInvoice.rejected, (state, action) => {
        state.resendAgreementInvoiceLoading = false;
      });

    builder
      .addCase(fetchAgreementInvoice.pending, (state, action) => {
        state.agreementInvoice = null;
        state.agreementInvoiceLoading = true;
      })
      .addCase(fetchAgreementInvoice.fulfilled, (state, action) => {
        state.agreementInvoice = action.payload;
        state.agreementInvoiceLoading = false;
      })
      .addCase(fetchAgreementInvoice.rejected, (state, action) => {
        state.agreementInvoice = null;
        state.agreementInvoiceLoading = false;
      });

    builder
      .addCase(passiveAgreement.pending, (state, action) => {
        state.passiveAgreementLoading = true;
      })
      .addCase(passiveAgreement.fulfilled, (state, action) => {
        state.passiveAgreementLoading = false;
        state.agreementsRefresh = !state.agreementsRefresh;
      })
      .addCase(passiveAgreement.rejected, (state, action) => {
        state.passiveAgreementLoading = false;
      });

    builder
      .addCase(payApproveAgreement.pending, (state, action) => {
        state.payApproveAgreementLoading = true;
      })
      .addCase(payApproveAgreement.fulfilled, (state, action) => {
        state.payApproveAgreementLoading = false;
        state.agreementsRefresh = !state.agreementsRefresh;
      })
      .addCase(payApproveAgreement.rejected, (state, action) => {
        state.payApproveAgreementLoading = false;
      });
  },
});

export default agreementSlice.reducer;
