import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Label,
  Row,
  Button,
  Form,
  Spinner,
  InputGroup,
} from "reactstrap";

// redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { authCheck, forgotPassword } from "../../slices/apps/authSlice";

// router
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// diğer importlar
import ReactInputMask from "react-input-mask";

// regex, değişkenler
const phoneRegExp =
  /^(?:[1-9][0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}|[1-9][0-9]{9})$/;

import Logo from "../../assets/images/logo/global-assistance-logo.png";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { forgotPasswordLoading } = useSelector((state) => {
    return {
      forgotPasswordLoading: state.auth.forgotPasswordLoading,
    };
  }, shallowEqual);

  // kullanıcı giriş yapmış ise ana sayfaya yönlendirir.
  useEffect(() => {
    const authCheckFunc = async () => {
      try {
        const resultAction = await dispatch(authCheck());
        if (resultAction.meta.requestStatus === "fulfilled") {
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Failed:", error);
      }
    };
    authCheckFunc();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
    }),
    onSubmit: async (values) => {
      const resultAction = await dispatch(forgotPassword(values.phoneNumber));

      if (resultAction.meta.requestStatus === "fulfilled") {
        navigate("/login");
      }
    },
  });

  document.title = "Şifremi Unuttum | Global Yol Yardım";
  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "#FDFDFD",
        }}
      >
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="text-center pt-4 pb-4 text-white-50 bg-dark">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img src={Logo} alt="" height="85" />
                      </Link>
                    </div>
                  </div>
                  <div className="p-lg-5 p-4">
                    <div>
                      <div className="mb-3">
                        <h5 className="text-primary ">Şifremi Unuttum</h5>
                      </div>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="phoneNumber">
                            Telefon Numarası
                          </Label>
                          <InputGroup>
                            <span
                              className="input-group-text "
                              style={{
                                width: "44px",
                                justifyContent: "center",
                              }}
                              id="inputGroupPrepend2"
                            >
                              0
                            </span>
                            <ReactInputMask
                              mask="999 999 99 99"
                              maskChar=" "
                              name="phoneNumber"
                              placeholder="Telefon Numarası"
                              className={
                                validation.touched.phoneNumber &&
                                validation.errors.phoneNumber
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={validation.handleChange}
                              value={validation.values.phoneNumber || ""}
                            />
                          </InputGroup>
                          {validation.touched.phoneNumber &&
                            validation.errors.phoneNumber && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {validation.errors.phoneNumber}
                              </div>
                            )}
                        </div>

                        <div className="mt-4">
                          {!forgotPasswordLoading ? (
                            <Button
                              color="primary"
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Şifre Gönder
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              disabled={forgotPasswordLoading ? true : false}
                              className="btn btn-primary w-100"
                            >
                              <Spinner size="sm" className="me-2" /> Yükleniyor
                            </Button>
                          )}
                        </div>

                        <hr className="my-4 text-muted" />

                        <div className="text-center">
                          <Link
                            to="/login"
                            className="text-primary text-decoration-underline fst-normal fw-medium"
                          >
                            Giriş Yap
                          </Link>
                          <hr className="my-4 text-muted" />

                          <h5 className="text-primary mt-2 mb-0">
                            0 850 242 70 42
                          </h5>
                          <h5 className="text-primary mt-2 mb-0">
                            info@globalyolyardim.com
                          </h5>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer mb-2">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p
                    className="mb-2"
                    style={{ color: "#808080", letterSpacing: "0.8px" }}
                  >
                    Global Yol Yardım &copy; {new Date().getFullYear()} | 0 850
                    242 70 42 | info@globalyolyardim.com
                    <br />
                    <i className="mdi mdi-heart text-danger"></i> by{" "}
                    <a
                      href="https://cemrek.com.tr"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Çemrek Bilgi Teknolojileri
                    </a>
                    <br />
                    <a
                      href="https://globalassistance.com.tr/kvkk"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Kişisel Verilerin Korunması Kanunu (KVKK)
                    </a>{" "}
                    |{" "}
                    <a
                      href="https://globalassistance.com.tr/mesafeli-satis-sozlesmesi"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Mesafeli Satış Sözleşmesi
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgotPassword);
