import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  fetchUserProfile,
  updateUser,
} from "../../slices/apps/userSlice";
import { logoutUser } from "../../slices/apps/authSlice";

// router
import { useNavigate, useSearchParams } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// diğer importlar
import classnames from "classnames";
import ReactInputMask from "react-input-mask";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// görsel importlar
import logo from "../../assets/images/avatar.png";

// regex, değişkenler
const phoneRegExp = /^(?:[1-9][0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}|[1-9][0-9]{9})$/;

const Profile = () => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const [tabs] = useState(["0", "1"]);
  const [activeTab, setActiveTab] = useState("0");
  const [isProfileEdit, setIsProfileEdit] = useState(false);

  const { userProfileState, userProfileLoading, userProfileRefresh } =
    useSelector((state) => {
      return {
        userProfileState: state.user.userProfile,
        userProfileLoading: state.user.userProfileLoading,
        userProfileRefresh: state.user.userProfileRefresh,
      };
    }, shallowEqual);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchUserProfile(userProfile.data.Id));
      } catch (error) {
        console.log(error);
      }
    };

    if (userProfile) {
      fetchData();
    }
  }, [userProfileRefresh]);

  useEffect(() => {
    if (tab && tabs.includes(tab)) {
      setActiveTab(tab);
    } else {
      setActiveTab("0");
    }
  }, [userProfileState]);

  const tabChange = (newTab) => {
    if (activeTab !== newTab) {
      setActiveTab(newTab);
      searchParams.set("tab", newTab);
      navigate({ search: searchParams.toString() });
    }
  };

  //#region profil düzenleme
  const profileValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: userProfileState?.Id,
      nameSurname: userProfileState?.NameSurname,
      phoneNumber: userProfileState?.PhoneNumber,
      email: userProfileState?.Email,
      isActive: userProfileState?.IsActive,
      agencyId: userProfileState?.AgencyId,
      userRole: userProfileState?.UserRole,
      smsAuthorized: userProfileState?.SmsAuthorized,
    },

    validationSchema: Yup.object({
      nameSurname: Yup.string().required("Lütfen ad soyad girin"),
      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
      email: Yup.string()
        .nullable()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .notRequired(),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(updateUser(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setIsProfileEdit(false);
      }
    },
  });

  //#region şifre değiştirme
  const passwordValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      userId: userProfileState?.Id,
      oldPassword: "",
      newPassword: "",
      newPasswordAgain: "",
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required("Lütfen eski şifrenizi girin")
        .min(6, "Lütfen eski şifrenizi giriniz"),
      newPassword: Yup.string()
        .required("Lütfen yeni şifrenizi girin")
        .min(6, "Yeni şifreniz en az 6 karakter olmalı"),
      newPasswordAgain: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Şifreler eşleşmiyor")
        .required("Lütfen yeni şifrenizi tekrar girin"),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(changePassword(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        await dispatch(logoutUser());
        window.location.reload();
      }
    },
  });
  //#endregion

  if (
    userProfileLoading ||
    (userProfileState && Object.keys(userProfileState).length == 0)
  ) {
    return (
      <Spinner
        className="position-absolute top-50 start-50"
        animation="border"
        color="primary"
      />
    );
  }

  document.title = "Profilim | Global Yol Yardım";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={3}>
              <Card>
                <CardBody className="p-4 text-center">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <img
                        src={logo}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <h5 className="fs-16 mb-1">
                      {userProfileState?.NameSurname}
                    </h5>
                    <p className="text-muted mb-3">{userProfileState?.Email}</p>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => setIsProfileEdit(true)}
                    disabled={isProfileEdit}
                  >
                    Düzenle
                  </button>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9}>
              <Card>
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === tabs[0],
                        })}
                        onClick={() => {
                          tabChange("0");
                        }}
                        type="button"
                      >
                        Profil Bilgileri
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === tabs[1],
                        })}
                        onClick={() => {
                          tabChange("1");
                        }}
                        type="button"
                      >
                        Şifre Değiştir
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={tabs[0]}>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          profileValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="nameSurname"
                                className="form-label"
                              >
                                Ad Soyad
                              </Label>
                              <Input
                                disabled={!isProfileEdit}
                                name="nameSurname"
                                className="form-control"
                                placeholder="Ad Soyad"
                                type="text"
                                autoComplete="off"
                                onChange={profileValidation.handleChange}
                                onBlur={profileValidation.handleBlur}
                                value={
                                  profileValidation.values.nameSurname || ""
                                }
                                invalid={
                                  profileValidation.touched.nameSurname &&
                                  profileValidation.errors.nameSurname
                                    ? true
                                    : false
                                }
                              />
                              {profileValidation.touched.nameSurname &&
                                profileValidation.errors.nameSurname && (
                                  <FormFeedback type="invalid">
                                    {profileValidation.errors.nameSurname}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                Telefon Numarası
                              </Label>
                              <InputGroup>
                                <span
                                  className="input-group-text "
                                  style={{
                                    width: "44px",
                                    justifyContent: "center",
                                  }}
                                  id="inputGroupPrepend2"
                                >
                                  0
                                </span>
                                <ReactInputMask
                                  disabled
                                  mask="999 999 99 99"
                                  maskChar=" "
                                  name="phoneNumber"
                                  placeholder="Telefon Numarası"
                                  className={
                                    profileValidation.touched.phoneNumber &&
                                    profileValidation.errors.phoneNumber
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={profileValidation.handleChange}
                                  value={
                                    profileValidation.values.phoneNumber || ""
                                  }
                                  style={{ zIndex: 0 }}
                                />
                              </InputGroup>
                              {profileValidation.touched.phoneNumber &&
                                profileValidation.errors.phoneNumber && (
                                  <div
                                    type="invalid"
                                    className="d-block invalid-feedback"
                                  >
                                    {profileValidation.errors.phoneNumber}
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                E-posta Adresi
                              </Label>
                              <Input
                                disabled
                                name="email"
                                className="form-control"
                                placeholder="E-posta Adresi"
                                type="text"
                                autoComplete="off"
                                onChange={profileValidation.handleChange}
                                onBlur={profileValidation.handleBlur}
                                value={profileValidation.values.email || ""}
                                invalid={
                                  profileValidation.touched.email &&
                                  profileValidation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {profileValidation.touched.email &&
                                profileValidation.errors.email && (
                                  <FormFeedback type="invalid">
                                    {profileValidation.errors.email}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              {isProfileEdit && (
                                <>
                                  <button
                                    className="btn btn-light btn-sm"
                                    onClick={() => {
                                      setIsProfileEdit(false);
                                      profileValidation.resetForm();
                                    }}
                                  >
                                    İptal
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => {
                                      if (!userProfileLoading) {
                                        profileValidation.handleSubmit();
                                      }
                                    }}
                                  >
                                    Kaydet
                                  </button>
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>

                    <TabPane tabId={tabs[1]}>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          passwordValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="g-2">
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="oldPassword"
                                className="form-label"
                              >
                                Eski Şifre
                              </Label>
                              <Input
                                id="oldPassword"
                                name="oldPassword"
                                value={passwordValidation.values.oldPassword}
                                type="password"
                                className="form-control pe-5"
                                placeholder="Eski şifreniz"
                                onChange={passwordValidation.handleChange}
                                onBlur={passwordValidation.handleBlur}
                                invalid={
                                  passwordValidation.touched.oldPassword &&
                                  passwordValidation.errors.oldPassword
                                    ? true
                                    : false
                                }
                              />
                              {passwordValidation.touched.oldPassword &&
                                passwordValidation.errors.oldPassword && (
                                  <FormFeedback type="invalid">
                                    {passwordValidation.errors.oldPassword}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="newPassword"
                                className="form-label"
                              >
                                Yeni Şifre
                              </Label>
                              <InputGroup>
                                <Input
                                  id="newPassword"
                                  name="newPassword"
                                  value={passwordValidation.values.newPassword}
                                  type="password"
                                  className="form-control pe-5"
                                  placeholder="Yeni şifreniz"
                                  onChange={passwordValidation.handleChange}
                                  onBlur={passwordValidation.handleBlur}
                                  invalid={
                                    passwordValidation.touched.newPassword &&
                                    passwordValidation.errors.newPassword
                                      ? true
                                      : false
                                  }
                                />
                              </InputGroup>
                              {passwordValidation.touched.newPassword &&
                                passwordValidation.errors.newPassword && (
                                  <div
                                    type="invalid"
                                    className="d-block invalid-feedback"
                                  >
                                    {passwordValidation.errors.newPassword}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="newPasswordAgain"
                                className="form-label"
                              >
                                Şifre Tekrarı
                              </Label>
                              <Input
                                id="newPasswordAgain"
                                name="newPasswordAgain"
                                value={
                                  passwordValidation.values.newPasswordAgain
                                }
                                type="password"
                                className="form-control pe-5"
                                placeholder="Şifrenizi doğrulayın"
                                onChange={passwordValidation.handleChange}
                                onBlur={passwordValidation.handleBlur}
                                invalid={
                                  passwordValidation.touched.newPasswordAgain &&
                                  passwordValidation.errors.newPasswordAgain
                                    ? true
                                    : false
                                }
                              />
                              {passwordValidation.touched.newPasswordAgain &&
                                passwordValidation.errors.newPasswordAgain && (
                                  <FormFeedback type="invalid">
                                    {passwordValidation.errors.newPasswordAgain}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col lg={12} className="mt-3">
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  passwordValidation.handleSubmit();
                                }}
                              >
                                Şifremi Değiştir
                              </button>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <p className="text-muted fs-12">
                              *Şifrenizi değiştirdiğinizde, güvenliğiniz için
                              yeniden giriş yapmanız gerekecektir.
                            </p>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
