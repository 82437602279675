import React, { Fragment, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { findBin } from "../../slices/apps/paymentSlice";
import {
  fetchAllPackages,
  saleBulkPackage,
} from "../../slices/apps/packageSlice";
import { fetchAgencies } from "../../slices/apps/agencySlices";

// router
import { useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import ReactInputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";

// toaster
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data dosyaları

const BulkSaleCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      await dispatch(fetchAllPackages());
      await dispatch(fetchAgencies());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // states
  const { agencies, allPackages, cardInfo } = useSelector((state) => {
    return {
      agencies: state.agency.agencies,
      allPackages: state.package.allPackages,
      customers: state.customer.customers,
      cardInfo: state.payment.cardInfo,
    };
  }, shallowEqual);

  const [packageState, setPackageState] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      packageId: null,
      agencyId: null,
      quantity: 0,
      unitPrice: 0,

      paymentType: 0,
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
    },

    validationSchema: Yup.object({
      packageId: Yup.string().required("Lütfen paket seçin"),
      agencyId: Yup.string().required("Lütfen acente seçin"),
      quantity: Yup.number()
        .typeError("Geçerli bir sayı girin")
        .moreThan(0, "Paket adedi 0'dan büyük olmalıdır")
        .required("Paket adedi girin"),
      unitPrice: Yup.number()
        .typeError("Geçerli bir sayı girin")
        .moreThan(0, "Paket birim fiyatı 0'dan büyük olmalıdır")
        .required("Paket birim fiyatı girin"),

      cardHolderName: Yup.string().test(
        "card-required",
        "Lütfen ad soyad girin",
        function (value) {
          return this.parent.paymentType != 0 || !!value;
        }
      ),
      cardNumber: Yup.string()
        .matches(
          /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
          "Geçersiz kart numarası"
        )
        .test("card-required", "Lütfen kart numarası girin", function (value) {
          return this.parent.paymentType != 0 || !!value;
        }),
      expiryDate: Yup.string()
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Geçersiz tarih formatı (AA/YY)"
        )
        .test(
          "expiry-required",
          "Lütfen son kullanma tarihini girin",
          function (value) {
            return this.parent.paymentType != 0 || !!value;
          }
        ),
      cvc: Yup.string()
        .matches(/^[0-9]{3}$/, "Geçersiz CVV")
        .test("cvc-required", "Lütfen CVV girin", function (value) {
          return this.parent.paymentType != 0 || !!value;
        }),
    }),

    onSubmit: async (values) => {
      const { cardHolderName, cardNumber, expiryDate, cvc } = values;
      const [expMonth, expYear] = expiryDate.split("/");
      const cardNumberEnd = cardNumber.replaceAll(" ", "");

      const paymentData = {
        ...values,
        cardNumber: cardNumberEnd,
        cardHolderName,
        expireMonth: parseInt(expMonth, 10) || null,
        expireYear: parseInt(expYear, 10) || null,
        cvc,
        posId: cardInfo.PosId || null,
        isSecure: cardInfo.IsSecure || false,
      };

      try {
        const resultAction = await dispatch(saleBulkPackage(paymentData));

        if (resultAction.meta.requestStatus === "fulfilled") {
          navigate("/bulk-sale");
        }
      } catch (error) {
        console.error("Failed:", error);
      }
    },
  });

  useEffect(() => {
    const cardNumber = validation.values.cardNumber.replaceAll(" ", "");

    if (cardNumber.length === 6 || cardNumber.length === 16) {
      try {
        dispatch(findBin(cardNumber.slice(0, 6)));
      } catch (error) {
        console.error("Failed:", error);
      }
    }
  }, [validation.values.cardNumber]);

  document.title = "Toplu Paket Sat | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Col lg={7}>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#"
                      autoComplete="off"
                    >
                      <Card>
                        <CardBody>
                          <h4 className="mt-4">Toplu Paket Satışı</h4>
                          <hr className="mb-4 text-muted" />

                          <Row className="align-items-start">
                            <Col sm={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="packageIdSelect"
                                  className="form-label"
                                >
                                  Paket
                                </Label>
                                <Select
                                  isClearable
                                  placeholder="Paket seçiniz"
                                  id="packageIdSelect"
                                  name="packageId"
                                  options={allPackages
                                    ?.filter(
                                      (item) => item.isActive && item.isBulkSale
                                    )
                                    .map((item) => ({
                                      label: item.packageName,
                                      value: item.id,
                                    }))}
                                  noOptionsMessage={() => "Seçenek bulunamadı"}
                                  value={
                                    allPackages
                                      ?.map((item) => ({
                                        label: item.packageName,
                                        value: item.id,
                                      }))
                                      .find(
                                        (item) =>
                                          item.value ===
                                          validation.values.packageId
                                      ) || {
                                      label: "Paket seçiniz",
                                      value: 0,
                                    }
                                  }
                                  onChange={(value) => {
                                    validation.setFieldValue(
                                      "packageId",
                                      value ? value.value : ""
                                    );

                                    const item = allPackages.find(
                                      (item) => item.id === value?.value || ""
                                    );

                                    setPackageState(item);
                                  }}
                                  onBlur={validation.handleBlur}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validation.touched.packageId &&
                                        validation.errors.packageId &&
                                        !state.isFocused
                                          ? "#e30a17"
                                          : "#e9ebec",
                                    }),
                                  }}
                                />
                                {validation.touched.packageId &&
                                  validation.errors.packageId && (
                                    <div className="d-block invalid-feedback">
                                      {validation.errors.packageId}
                                    </div>
                                  )}
                              </div>
                            </Col>

                            <Col sm={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="agencyIdSelect"
                                  className="form-label"
                                >
                                  Acente
                                </Label>
                                <Select
                                  isClearable
                                  placeholder="Acente seçiniz"
                                  id="agencyIdSelect"
                                  name="agencyId"
                                  options={agencies
                                    ?.filter((item) => item.IsActive)
                                    .map((item) => ({
                                      label: item.CompanyName,
                                      value: item.Id,
                                    }))}
                                  noOptionsMessage={() => "Seçenek bulunamadı"}
                                  value={
                                    agencies
                                      ?.map((item) => ({
                                        label: item.CompanyName,
                                        value: item.Id,
                                      }))
                                      .find(
                                        (item) =>
                                          item.value ===
                                          validation.values.agencyId
                                      ) || {
                                      label: "Acente seçiniz",
                                      value: 0,
                                    }
                                  }
                                  onChange={(value) => {
                                    validation.setFieldValue(
                                      "agencyId",
                                      value ? value.value : ""
                                    );
                                  }}
                                  onBlur={validation.handleBlur}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor:
                                        validation.touched.agencyId &&
                                        validation.errors.agencyId &&
                                        !state.isFocused
                                          ? "#e30a17"
                                          : "#e9ebec",
                                    }),
                                  }}
                                />
                                {validation.touched.agencyId &&
                                  validation.errors.agencyId && (
                                    <div className="d-block invalid-feedback">
                                      {validation.errors.agencyId}
                                    </div>
                                  )}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="quantity"
                                >
                                  Paket Adedi
                                </Label>
                                <InputGroup>
                                  <NumericFormat
                                    name="quantity"
                                    id="quantity"
                                    className={
                                      validation.touched.quantity &&
                                      validation.errors.quantity
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    value={validation.values.quantity}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale={0}
                                    allowNegative={false}
                                    onValueChange={(values) => {
                                      const { floatValue } = values;
                                      validation.setFieldValue(
                                        "quantity",
                                        floatValue || 0
                                      );
                                    }}
                                    inputMode="numeric"
                                    style={{ zIndex: 0 }}
                                  />
                                </InputGroup>
                                {validation.touched.quantity &&
                                  validation.errors.quantity && (
                                    <div
                                      type="invalid"
                                      className="d-block invalid-feedback"
                                    >
                                      {validation.errors.quantity}
                                    </div>
                                  )}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="unitPrice"
                                >
                                  Paket Birim Fiyatı
                                </Label>
                                <InputGroup>
                                  <NumericFormat
                                    name="unitPrice"
                                    id="unitPrice"
                                    className={
                                      validation.touched.unitPrice &&
                                      validation.errors.unitPrice
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    value={validation.values.unitPrice}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    onValueChange={(values) => {
                                      const { floatValue } = values;
                                      validation.setFieldValue(
                                        "unitPrice",
                                        floatValue || 0
                                      );
                                    }}
                                    inputMode="numeric"
                                    style={{ zIndex: 0 }}
                                  />
                                </InputGroup>
                                {validation.touched.unitPrice &&
                                  validation.errors.unitPrice && (
                                    <div
                                      type="invalid"
                                      className="d-block invalid-feedback"
                                    >
                                      {validation.errors.unitPrice}
                                    </div>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h4 className="mt-4">Ödeme</h4>
                          <hr className="mb-4 text-muted" />
                          <Row className="g-4 mb-4">
                            <Col sm={6}>
                              <div>
                                <div className="form-check card-radio">
                                  <Input
                                    id="paymentMethod01"
                                    name="paymentType"
                                    type="radio"
                                    className="form-check-input"
                                    value={0}
                                    checked={validation.values.paymentType == 0}
                                    onChange={(e) => {
                                      validation.handleChange(e);

                                      validation.setFieldValue(
                                        "cardHolderName",
                                        ""
                                      );
                                      validation.setFieldValue(
                                        "cardNumber",
                                        ""
                                      );
                                      validation.setFieldValue(
                                        "expiryDate",
                                        ""
                                      );
                                      validation.setFieldValue("cvc", "");
                                    }}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="paymentMethod01"
                                  >
                                    <span className="fs-16 text-muted me-2">
                                      <i className="ri-bank-card-fill align-bottom"></i>
                                    </span>
                                    <span className="fs-12 text-wrap">
                                      Kredi / Banka Kartı
                                    </span>
                                  </Label>
                                </div>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div>
                                <div className="form-check card-radio">
                                  <Input
                                    id="paymentMethod02"
                                    name="paymentType"
                                    type="radio"
                                    className="form-check-input"
                                    value={1}
                                    checked={validation.values.paymentType == 1}
                                    onChange={(e) => {
                                      validation.handleChange(e);

                                      validation.setFieldValue(
                                        "cardHolderName",
                                        ""
                                      );
                                      validation.setFieldValue(
                                        "cardNumber",
                                        ""
                                      );
                                      validation.setFieldValue(
                                        "expiryDate",
                                        ""
                                      );
                                      validation.setFieldValue("cvc", "");
                                    }}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="paymentMethod02"
                                  >
                                    <span className="fs-16 text-muted me-2">
                                      <i className="ri-send-plane-line align-bottom"></i>
                                    </span>
                                    <span className="fs-12 text-wrap">
                                      Havale / EFT
                                    </span>
                                  </Label>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          {validation.values.paymentType == 0 ? (
                            <Row className="g-2 mb-2">
                              <Col lg={12}>
                                <div>
                                  <Label
                                    htmlFor="cardHolderName"
                                    className="form-label"
                                  >
                                    Ad Soyad
                                  </Label>
                                  <Input
                                    id="cardHolderName"
                                    name="cardHolderName"
                                    value={validation.values.cardHolderName}
                                    type="text"
                                    className="form-control pe-5"
                                    placeholder="Kart Sahibi Adı Soyadı"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.cardHolderName &&
                                      validation.errors.cardHolderName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.cardHolderName &&
                                    validation.errors.cardHolderName && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.cardHolderName}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>

                              <Col lg={12}>
                                <div>
                                  <Label
                                    className="form-label"
                                    htmlFor="cardNumber"
                                  >
                                    Kart Numarası
                                  </Label>
                                  <InputGroup>
                                    <ReactInputMask
                                      mask="9999 9999 9999 9999"
                                      maskChar=""
                                      name="cardNumber"
                                      alwaysShowMask={false}
                                      inputMode="numeric"
                                      type="tel"
                                      className={
                                        validation.touched.cardNumber &&
                                        validation.errors.cardNumber
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      placeholder="Kart Numarası"
                                      onChange={validation.handleChange}
                                      value={validation.values.cardNumber}
                                      style={{ zIndex: 0 }}
                                    />
                                  </InputGroup>
                                  {validation.touched.cardNumber &&
                                    validation.errors.cardNumber && (
                                      <div
                                        type="invalid"
                                        className="d-block invalid-feedback"
                                      >
                                        {validation.errors.cardNumber}
                                      </div>
                                    )}
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="expiryDate"
                                  >
                                    Son Kullanma Tarihi
                                  </Label>
                                  <ReactInputMask
                                    mask="99/99"
                                    maskChar=" "
                                    name="expiryDate"
                                    inputMode="numeric"
                                    className={
                                      validation.touched.expiryDate &&
                                      validation.errors.expiryDate
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    placeholder="AA/YY"
                                    onChange={validation.handleChange}
                                    value={validation.values.expiryDate}
                                    style={{ zIndex: 0 }}
                                  />
                                  {validation.touched.expiryDate &&
                                    validation.errors.expiryDate && (
                                      <div
                                        type="invalid"
                                        className="d-block invalid-feedback"
                                      >
                                        {validation.errors.expiryDate}
                                      </div>
                                    )}
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="cvc">
                                    CVV
                                  </Label>
                                  <Input
                                    id="cvc"
                                    name="cvc"
                                    inputMode="numeric"
                                    maxLength="3"
                                    className={
                                      validation.touched.cvc &&
                                      validation.errors.cvc
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    placeholder="CVV" // Placeholder eklendi
                                    onChange={validation.handleChange}
                                    value={validation.values.cvc}
                                  />
                                  {validation.touched.cvc &&
                                    validation.errors.cvc && (
                                      <div
                                        type="invalid"
                                        className="d-block invalid-feedback"
                                      >
                                        {validation.errors.cvc}
                                      </div>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <div className="sticky-side-div mb-4 mt-2">
                              <div className="table-responsive">
                                <table className="table mb-0">
                                  <tbody>
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>BANKA</span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span className="fw-semibold" id="bank">
                                          Türkiye İş Bankası
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>AD SOYAD</span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="nameSurname"
                                        >
                                          GLOBAL TAŞIT YOL YARDIM LİMİTED
                                          ŞİRKETİ
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>IBAN</span>
                                        </div>
                                      </th>
                                      <td className="text-end d-flex justify-content-end align-items-center">
                                        <span className="fw-semibold" id="iban">
                                          TR47 0006 4000 0015 3140 2369 06
                                        </span>
                                        <div
                                          onClick={() => {
                                            const ibanText =
                                              "TR47 0006 4000 0015 3140 2369 06";
                                            const textArea =
                                              document.createElement(
                                                "textarea"
                                              );
                                            textArea.value = ibanText;
                                            document.body.appendChild(textArea);
                                            textArea.select();
                                            document.execCommand("copy");
                                            document.body.removeChild(textArea);

                                            toast.success("IBAN Kopyalandı", {
                                              position: "top-center",
                                              isLoading: false,
                                              autoClose: 5000,
                                              transition: Slide,
                                              hideProgressBar: true,
                                            });
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "0.3rem",
                                          }}
                                        >
                                          <i className="ri-file-copy-line align-middle me-2 fs-14 text-dark"></i>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}

                          <div className="hstack gap-2 justify-content-end mt-3">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                validation.handleSubmit();
                              }}
                            >
                              Onayla
                            </button>
                          </div>
                        </CardBody>
                      </Card>
                    </Form>
                  </Col>

                  <Col lg={5}>
                    <Card>
                      <CardBody>
                        <h4 className="mt-4">Paket Detayları</h4>
                        <hr className="mb-4 text-muted" />

                        {validation.values.packageId && packageState ? (
                          <div className="sticky-side-div mb-4 mt-2">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  {packageState.totalLimit > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>
                                            Sözleşmeyi Kapsayan 1 Yıllık Tutar:
                                          </span>
                                          <span className="fw-light">
                                            {packageState.totalLimitDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.totalLimit}
                                        </span>
                                      </td>
                                    </tr>
                                  )}

                                  {packageState.accident > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>Kaza Durumunda:</span>
                                          <span className="fw-light">
                                            {packageState.accidentDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.accident}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.fault > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>Arıza Durumunda:</span>
                                          <span className="fw-light">
                                            {packageState.faultDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.fault}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.tire > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>Lastik Patlamasında:</span>
                                          <span className="fw-light">
                                            {packageState.tireDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.tire}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.fuel > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>Yakıt Bitmesinde:</span>
                                          <span className="fw-light">
                                            {packageState.fuelDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.fuel}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.windshield > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>
                                            Ön Cam Patlaması/Kırılması
                                            Durumunda:
                                          </span>
                                          <span className="fw-light">
                                            {packageState.windshieldDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.windshield}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.landslide > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>Heyelan Durumunda:</span>
                                          <span className="fw-light">
                                            {packageState.landslideDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.landslide}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.headlight > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>Far Arıza Durumunda:</span>
                                          <span className="fw-light">
                                            {packageState.headlightDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.headlight}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.health > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>
                                            Sağlık Sorunları Durumunda:
                                          </span>
                                          <span className="fw-light">
                                            {packageState.healthDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.health}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.tired > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>
                                            Yorgunluktan Kaynaklı Tek Sürücülü
                                            Araçlar İçin:
                                          </span>
                                          <span className="fw-light">
                                            {packageState.tiredDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.tired}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.evFault > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>
                                            Elektrikli Araçların Yolda Kalması
                                            Durumunda:
                                          </span>
                                          <span className="fw-light">
                                            {packageState.evFaultDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.evFault}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {packageState.wildAnimal > 0 && (
                                    <tr>
                                      <th>
                                        <div className="d-flex flex-column">
                                          <span>
                                            Yabani Hayvanların Aracı Hareket
                                            Edemez Hale Getirdiği Durumunda:
                                          </span>
                                          <span className="fw-light">
                                            {packageState.wildAnimalDesc}
                                          </span>
                                        </div>
                                      </th>
                                      <td className="text-end">
                                        <span
                                          className="fw-semibold"
                                          id="cart-total"
                                        >
                                          {packageState.wildAnimal}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  <tr className="table-active">
                                    <th>Fiyat:</th>
                                    <td className="text-end">
                                      <span
                                        className="fw-semibold"
                                        id="cart-total"
                                      >
                                        {new Intl.NumberFormat("tr-TR", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }).format(
                                          validation.values.quantity *
                                            validation.values.unitPrice
                                        )}{" "}
                                        TL
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div className="w-100 d-flex text-center flex-column">
                            <i className="bx bx-error-circle align-middle me-2 fs-48 mb-2 text-muted"></i>
                            <h5 className="text-muted">Paket Seçiniz!</h5>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BulkSaleCreate;
