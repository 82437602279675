import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//Authentication
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import AgencyRegister from "../pages/Authentication/AgencyRegister";

//Pages
import Agencies from "../pages/Agency";
import Announcements from "../pages/Announcement";
import Agreements from "../pages/Agreement";
import Profile from "../pages/Profile";
import Package from "../pages/Package";
import Ticket from "../pages/Ticket";
import Payment from "../pages/Payment";
import Customers from "../pages/Customer";
import PackageAdmin from "../pages/PackageAdmin";
import PackageAdd from "../pages/PackageAdmin/PackageAdd";
import PackageUpdate from "../pages/PackageAdmin/PackageUpdate";
import Users from "../pages/User";
import Success from "../pages/Payment/Success";
import Error from "../pages/Payment/Error";
import PaymentClaim from "../pages/PaymentClaim";
import TicketDetail from "../pages/Ticket/TicketDetail";
import BulkSale from "../pages/BulkSale";
import BulkSaleCreate from "../pages/BulkSale/BulkSaleCreate";
import SubAgencies from "../pages/SubAgency";
import ForgotPassword from "../pages/Authentication/ForgotPassword";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Dashboard />,
  },
  {
    path: "/",
    exact: true,
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Navigate to="/dashboard" />,
  },

  {
    path: "/profile",
    exact: true,
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Profile />,
  },

  {
    path: "/agencies",
    exact: true,
    roles: ["SuperAdmin"],
    component: <Agencies />,
  },

  {
    path: "/agreements",
    exact: true,
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Agreements />,
  },

  {
    path: "/announcements",
    exact: true,
    roles: ["SuperAdmin"],
    component: <Announcements />,
  },

  {
    path: "/bulk-sale/create",
    exact: true,
    roles: ["SuperAdmin"],
    component: <BulkSaleCreate />,
  },

  {
    path: "/bulk-sale",
    exact: true,
    roles: ["SuperAdmin"],
    component: <BulkSale />,
  },

  {
    path: "/customers",
    exact: true,
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Customers />,
  },

  {
    path: "/users",
    exact: true,
    roles: ["SuperAdmin"],
    component: <Users />,
  },

  {
    path: "/packages",
    exact: true,
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Package />,
  },

  {
    path: "/package-admin/create",
    exact: true,
    roles: ["SuperAdmin"],
    component: <PackageAdd />,
  },

  {
    path: "/package-admin/update",
    exact: true,
    roles: ["SuperAdmin"],
    component: <PackageUpdate />,
  },

  {
    path: "/package-admin",
    exact: true,
    roles: ["SuperAdmin"],
    component: <PackageAdmin />,
  },

  {
    path: "/payment/success",
    exact: true,
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Success />,
  },

  {
    path: "/payment/error",
    exact: true,
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Error />,
  },

  {
    path: "/payment",
    exact: true,
    roles: ["SuperAdmin", "Agency", "SubAgency"],
    component: <Payment />,
  },

  {
    path: "/payment-claims",
    exact: true,
    roles: ["SuperAdmin", "Agency"],
    component: <PaymentClaim />,
  },

  {
    path: "/sub-agencies",
    exact: true,
    roles: ["SuperAdmin", "Agency"],
    component: <SubAgencies />,
  },

  {
    path: "/ticket",
    exact: true,
    roles: ["SuperAdmin"],
    component: <Ticket />,
  },

  {
    path: "/ticket-detail",
    exact: true,
    roles: ["SuperAdmin"],
    component: <TicketDetail />,
  },

  //Pages
  // { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/agency-register", component: <AgencyRegister /> },
  { path: "*", component: <Navigate to="/404" /> },
];

export { authProtectedRoutes, publicRoutes };
