import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { payAgreement } from "../../slices/apps/agreementSlice";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// diğer importlar
import ReactInputMask from "react-input-mask";
import { findBin } from "../../slices/apps/paymentSlice";

// regex, değişkenler

// data dosyaları

const AgreementPayModal = ({ id }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);

  // states
  const { cardInfo, payAgreementLoading } = useSelector((state) => {
    return {
      cardInfo: state.payment.cardInfo,
      payAgreementLoading: state.agreement.payAgreementLoading,
    };
  }, shallowEqual);

  function tog_large(e) {
    if (e) {
      validation.resetForm();

      setModal(true);
    } else setModal(false);
  }

  // Sözleşme validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      agreementId: id,
      paymentType: 0,
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
    },

    validationSchema: Yup.object({
      cardHolderName: Yup.string().test(
        "card-required",
        "Lütfen ad soyad girin",
        function (value) {
          return this.parent.paymentType != 0 || !!value;
        }
      ),
      cardNumber: Yup.string()
        .matches(
          /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
          "Geçersiz kart numarası"
        )
        .test("card-required", "Lütfen kart numarası girin", function (value) {
          return this.parent.paymentType != 0 || !!value;
        }),
      expiryDate: Yup.string()
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Geçersiz tarih formatı (AA/YY)"
        )
        .test(
          "expiry-required",
          "Lütfen son kullanma tarihini girin",
          function (value) {
            return this.parent.paymentType != 0 || !!value;
          }
        ),
      cvc: Yup.string()
        .matches(/^[0-9]{3}$/, "Geçersiz CVV")
        .test("cvc-required", "Lütfen CVV girin", function (value) {
          return this.parent.paymentType != 0 || !!value;
        }),
    }),

    onSubmit: async (values) => {
      const { cardHolderName, cardNumber, expiryDate, cvc } = values;
      const [expMonth, expYear] = expiryDate.split("/");
      const cardNumberEnd = cardNumber.replaceAll(" ", "");

      const paymentData = {
        ...values,
        cardHolderName,
        cardNumber: cardNumberEnd,
        expMonth: parseInt(expMonth, 10) || null,
        expYear: parseInt(expYear, 10) || null,
        cvc,
        installmentNumber: 1,
        posId: cardInfo.PosId || null,
        isSecure: cardInfo.IsSecure || false,
      };

      try {
        const resultAction = await dispatch(payAgreement(paymentData));

        if (resultAction.meta.requestStatus === "fulfilled") {
          setModal(false);
        }
      } catch (error) {
        console.error("Failed:", error);
      }
    },
  });

  useEffect(() => {
    const cardNumber = validation.values.cardNumber.replaceAll(" ", "");

    if (cardNumber.length === 6 || cardNumber.length === 16) {
      try {
        dispatch(findBin(cardNumber.slice(0, 6)));
      } catch (error) {
        console.error("Failed:", error);
      }
    }
  }, [validation.values.cardNumber]);

  return (
    <Fragment>
      <span
        id={`agreement-pay-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-credit-card text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`agreement-pay-tooltipTop-${id}`}
      >
        Ödeme Yap
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Ödeme Yap
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="g-4 mb-4">
              <Col sm={6}>
                <div>
                  <div className="form-check card-radio">
                    <Input
                      id="paymentMethod01"
                      name="paymentType"
                      type="radio"
                      className="form-check-input"
                      value={0}
                      checked={validation.values.paymentType == 0}
                      onChange={(e) => {
                        validation.handleChange(e);

                        validation.setFieldValue("cardHolderName", "");
                        validation.setFieldValue("cardNumber", "");
                        validation.setFieldValue("expiryDate", "");
                        validation.setFieldValue("cvc", "");
                      }}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="paymentMethod01"
                    >
                      <span className="fs-16 text-muted me-2">
                        <i className="ri-bank-card-fill align-bottom"></i>
                      </span>
                      <span className="fs-12 text-wrap">
                        Kredi / Banka Kartı
                      </span>
                    </Label>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div>
                  <div className="form-check card-radio">
                    <Input
                      id="paymentMethod02"
                      name="paymentType"
                      type="radio"
                      className="form-check-input"
                      value={1}
                      checked={validation.values.paymentType == 1}
                      onChange={(e) => {
                        validation.handleChange(e);

                        validation.setFieldValue("cardHolderName", "");
                        validation.setFieldValue("cardNumber", "");
                        validation.setFieldValue("expiryDate", "");
                        validation.setFieldValue("cvc", "");
                      }}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="paymentMethod02"
                    >
                      <span className="fs-16 text-muted me-2">
                        <i className="ri-send-plane-line align-bottom"></i>
                      </span>
                      <span className="fs-12 text-wrap">Havale / EFT</span>
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>

            {validation.values.paymentType == 0 ? (
              <Row className="g-2 mb-2">
                <Col lg={12}>
                  <div>
                    <Label htmlFor="cardHolderName" className="form-label">
                      Ad Soyad
                    </Label>
                    <Input
                      disabled={payAgreementLoading}
                      id="cardHolderName"
                      name="cardHolderName"
                      value={validation.values.cardHolderName}
                      type="text"
                      className="form-control pe-5"
                      placeholder="Kart Sahibi Adı Soyadı"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.cardHolderName &&
                        validation.errors.cardHolderName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.cardHolderName &&
                      validation.errors.cardHolderName && (
                        <FormFeedback type="invalid">
                          {validation.errors.cardHolderName}
                        </FormFeedback>
                      )}
                  </div>
                </Col>

                <Col lg={12}>
                  <div>
                    <Label className="form-label" htmlFor="cardNumber">
                      Kart Numarası
                    </Label>
                    <InputGroup>
                      <ReactInputMask
                        disabled={payAgreementLoading}
                        mask="9999 9999 9999 9999"
                        maskChar=""
                        name="cardNumber"
                        alwaysShowMask={false}
                        inputMode="numeric"
                        type="tel"
                        className={
                          validation.touched.cardNumber &&
                          validation.errors.cardNumber
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Kart Numarası"
                        onChange={validation.handleChange}
                        value={validation.values.cardNumber}
                        style={{ zIndex: 0 }}
                      />
                    </InputGroup>
                    {validation.touched.cardNumber &&
                      validation.errors.cardNumber && (
                        <div
                          type="invalid"
                          className="d-block invalid-feedback"
                        >
                          {validation.errors.cardNumber}
                        </div>
                      )}
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="expiryDate">
                      Son Kullanma Tarihi
                    </Label>
                    <ReactInputMask
                      disabled={payAgreementLoading}
                      mask="99/99"
                      maskChar=" "
                      name="expiryDate"
                      inputMode="numeric"
                      className={
                        validation.touched.expiryDate &&
                        validation.errors.expiryDate
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="AA/YY"
                      onChange={validation.handleChange}
                      value={validation.values.expiryDate}
                      style={{ zIndex: 0 }}
                    />
                    {validation.touched.expiryDate &&
                      validation.errors.expiryDate && (
                        <div
                          type="invalid"
                          className="d-block invalid-feedback"
                        >
                          {validation.errors.expiryDate}
                        </div>
                      )}
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="cvc">
                      CVV
                    </Label>
                    <Input
                      disabled={payAgreementLoading}
                      id="cvc"
                      name="cvc"
                      inputMode="numeric"
                      maxLength="3"
                      className={
                        validation.touched.cvc && validation.errors.cvc
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="CVV" // Placeholder eklendi
                      onChange={validation.handleChange}
                      value={validation.values.cvc}
                    />
                    {validation.touched.cvc && validation.errors.cvc && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {validation.errors.cvc}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="sticky-side-div mb-4 mt-2">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>BANKA</span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="bank">
                            Türkiye İş Bankası
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>AD SOYAD</span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="nameSurname">
                            GLOBAL TAŞIT YOL YARDIM LİMİTED ŞİRKETİ
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>IBAN</span>
                          </div>
                        </th>
                        <td className="text-end d-flex justify-content-end align-items-center">
                          <span className="fw-semibold" id="iban">
                            TR47 0006 4000 0015 3140 2369 06
                          </span>
                          <div
                            onClick={() => {
                              const ibanText =
                                "TR47 0006 4000 0015 3140 2369 06";
                              const textArea =
                                document.createElement("textarea");
                              textArea.value = ibanText;
                              document.body.appendChild(textArea);
                              textArea.select();
                              document.execCommand("copy");
                              document.body.removeChild(textArea);

                              toast.success("IBAN Kopyalandı", {
                                position: "top-center",
                                isLoading: false,
                                autoClose: 5000,
                                transition: Slide,
                                hideProgressBar: true,
                              });
                            }}
                            style={{
                              cursor: "pointer",
                              marginLeft: "0.3rem",
                            }}
                          >
                            <i className="ri-file-copy-line align-middle me-2 fs-14 text-dark"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
                disabled={payAgreementLoading}
              >
                Ödeme Yap
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AgreementPayModal;
