import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Scrollbar

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// data
const rolesType = {
  SuperAdmin: { label: "Yönetici", color: "text-danger" },
  Agency: { label: "Acente", color: "text-warning" },
  Customer: { label: "Müşteri", color: "text-success" },
  Salesman: { label: "Pazarlamacı", color: "text-primary" },
  Support: { label: "Destek", color: "text-info" },
};

const TicketDetail = () => {
  const messageBoxRef = useRef(null);
  const { userProfile } = useProfile();

  const [settings_Menu, setsettings_Menu] = useState(false);
  const [curMessage, setcurMessage] = useState("");

  const [messages, setMessages] = useState([]);

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };

  // Mesaj ekleme işlevi
  const addMessage = () => {
    const newMessage = {
      id: messages.length,
      message: curMessage,
      role: userProfile.data.Role,
      nameSurname: userProfile.data.NameSurname,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setcurMessage("");

    // Mesaj kutusunu en alta kaydır
    setTimeout(() => {
      if (messageBoxRef.current) {
        messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
      }
    }, 100);
  };

  // enter ile mesaj gönderme
  const onKeyPress = (e) => {
    const { key, value } = e;
    if (key === "Enter") {
      e.preventDefault();
      setcurMessage(value);
      addMessage();
    }
  };

  // son mesaja inme
  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [messages]);

  console.log(messages);

  document.title = "Talepler | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Card>
                  <CardBody>
                    <div className="w-100">
                      <div className="chat-content d-lg-flex">
                        <div className="w-100 position-relative">
                          <div className="position-relative">
                            <div className="p-3">
                              <Row className="align-items-center">
                                <Col sm={4} xs={8}>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 ">
                                      <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 ">
                                          <h5 className="text-truncate mb-0 fs-16">
                                            Talep Başlığı
                                          </h5>
                                          <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                            <small>durum</small>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={8} xs={4}>
                                  <ul className="list-inline user-chat-nav text-end mb-0">
                                    <li className="list-inline-item m-0">
                                      <Dropdown
                                        isOpen={settings_Menu}
                                        toggle={toggleSettings}
                                      >
                                        <DropdownToggle
                                          className="btn btn-ghost-secondary btn-icon"
                                          tag="button"
                                        >
                                          <FeatherIcon
                                            icon="more-vertical"
                                            className="icon-sm"
                                          />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            href="#"
                                            className="d-block d-lg-none user-profile-show"
                                          >
                                            <i className="ri-user-2-fill align-bottom text-muted me-2"></i>{" "}
                                            Kullanıcı Bilgileri
                                          </DropdownItem>
                                          <DropdownItem href="#">
                                            <i className="ri-inbox-archive-line align-bottom text-muted me-2"></i>{" "}
                                            Talebi Kapat
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </div>

                            <div className="chat-input-section p-3">
                              <form id="chatinput-form">
                                <Row className="g-0 align-items-center">
                                  <div className="col">
                                    <div className="chat-input-feedback">
                                      Lütfen mesajınızı giriniz
                                    </div>
                                    <textarea
                                      value={curMessage}
                                      onKeyDown={onKeyPress}
                                      onChange={(e) =>
                                        setcurMessage(e.target.value)
                                      }
                                      className="form-control chat-input"
                                      id="chat-input"
                                      placeholder="Lütfen mesajınızı giriniz..."
                                      autoComplete="off"
                                      rows={3}
                                      style={{
                                        resize: "none",
                                        overflow: "hidden",
                                        background: "whitesmoke",
                                      }}
                                    />
                                  </div>
                                  <div className="col-auto">
                                    <div className="chat-input-links ms-2">
                                      <div className="links-list-item">
                                        <Button
                                          type="button"
                                          color="primary"
                                          onClick={() => {
                                            addMessage();
                                          }}
                                          className="chat-send waves-effect waves-light"
                                        >
                                          <i className="ri-send-plane-2-fill align-bottom"></i>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {messages.map((item) => (
                  <Card>
                    <CardHeader className="align-items-center d-flex">
                      <div className="flex-grow-1">
                        <h5 className={`${rolesType[item.role]?.color}`}>
                          {item.nameSurname}
                        </h5>
                        <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                          <small>{rolesType[item.role]?.label}</small>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <h6>saat</h6>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <h6>{item.message}</h6>
                    </CardBody>
                  </Card>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TicketDetail;
