import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  agency: {},
  agencyLoading: true,
  agencyRefresh: true,

  agencies: [],
  agenciesLoading: true,
  agenciesRefresh: true,

  subAgencies: [],
  subAgenciesLoading: true,
  subAgenciesRefresh: true,

  agencyCommission: 20,
  agencyCommissionLoading: false,
  agencyCommissionRefresh: true,

  earningInvoice: {},
  earningInvoiceLoading: true,
  earningInvoiceRefresh: true,

  earningReceipt: {},
  earningReceiptLoading: true,
  earningReceiptRefresh: true,

  agencyEarnings: [],
  agencyEarningsAmount: null,
  agencyMinimumAmount: null,
  agencyEarningsLoading: true,
  agencyEarningsRefresh: true,

  agencyEarningsAdmin: [],
  agencyEarningsAdminLoading: true,
  agencyEarningsAdminRefresh: true,

  earningRequestDetail: {},
  earningRequestDetailLoading: true,
  earningRequestDetailRefresh: true,

  createAgencyLoading: false,
  updateAgencyLoading: false,
  deleteAgencyLoading: false,
  approveAgencyLoading: false,
  earningRequestLoading: false,
};

export const fetchAgency = createAsyncThunk(
  "agency/fetchAgency",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.get(`/Agency/${id}`);

      toast.dismiss();

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchAgencies = createAsyncThunk(
  "agency/fetchAgencies",
  async () => {
    try {
      const response = await baseApi.get(`/Agency`);

      return response.data.Value;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchSubAgencies = createAsyncThunk(
  "agency/fetchSubAgencies",
  async () => {
    try {
      const response = await baseApi.get(`/Agency/GetSubAgencies`);

      return response.data.Value;
    } catch (error) {
      toast.error("Hata: " + error.response.data.Error.Description, {
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchAgencyCommission = createAsyncThunk(
  "agency/fetchAgencyCommission",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    try {
      const response = await baseApi.get(
        `/Agency/GetAgencyCommissionRate/${id}`
      );

      return response.data.Value;
    } catch (error) {
      toast.error("Hata: " + error.response.data.Error.Description, {
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const createAgency = createAsyncThunk(
  "agency/createAgency",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: 0,
      mainAgencyId: values.mainAgencyId || null,
      companyCode: "",
      companyName: values.companyName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      address: values.address,
      city: values.city,
      district: values.district,
      postCode: values.postCode.trim(),
      taxNumber: values.taxNumber.trim(),
      taxOffice: values.taxOffice,
      bankIban: values.bankIban,
      bankName: values.bankName,
      agencyType: parseInt(values.agencyType),
      isActive: false,
      isApproved: false,
      commissionRate: parseInt(values.commissionRate) || 20,
      referenceCode: values.referenceCode,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Agency`, data);

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const updateAgency = createAsyncThunk(
  "agency/updateAgency",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      mainAgencyId: values.mainAgencyId || null,
      companyCode: values.companyCode,
      companyName: values.companyName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      address: values.address,
      city: values.city,
      district: values.district,
      postCode: values.postCode.trim(),
      taxNumber: values.taxNumber.trim(),
      taxOffice: values.taxOffice,
      bankIban: values.bankIban,
      bankName: values.bankName,
      agencyType: parseInt(values.agencyType),
      isActive: values.isActive,
      isApproved: values.isApproved,
      commissionRate: parseInt(values.commissionRate),
      referenceCode: values.referenceCode,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Agency/${values.id}`, data);

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const deleteAgency = createAsyncThunk(
  "agency/deleteAgency",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Siliniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.delete(`/Agency/${id}`);

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const approveAgency = createAsyncThunk(
  "agency/approveAgency",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      agencyId: values.agencyId,
      commissionRate: parseInt(values.commissionRate),
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Agency/ApproveAgency`, data);

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchAgencyEarnings = createAsyncThunk(
  "agency/fetchAgencyEarnings",
  async () => {
    try {
      const response = await baseApi.get(`/Agency/GetEarningsList`);

      return response.data.Value;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchAgencyEarningsAdmin = createAsyncThunk(
  "agency/fetchAgencyEarningsAdmin",
  async () => {
    try {
      const response = await baseApi.get(`/Agency/GetEarningRequests`);

      return response.data.Value;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const earningRequest = createAsyncThunk(
  "agency/earningRequest",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      agreementIds: values.agreementIds,
      invoiceNumber: values.invoiceNumber ? values.invoiceNumber : "",
      issueDate: values.issueDate ? values.issueDate : null,
      invoicePdfBase64: values.invoicePdfBase64 ? values.invoicePdfBase64 : "",
    };

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Agency/SendEarningRequest`, data);

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const approveEarning = createAsyncThunk(
  "agency/approveEarning",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      invoiceId: values.invoiceId,
      requestStatusType: values.requestStatusType
        ? values.requestStatusType
        : "",
      receiptPdfBase64: values.receiptPdfBase64 ? values.receiptPdfBase64 : "",
      reasonDesc: values.reasonDesc,
    };

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(
        `/Agency/ApproveEarningRequest`,
        data
      );

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchEarningRequestDetail = createAsyncThunk(
  "agency/earningRequestDetail",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.get(
        `/Agency/GetEarningRequestDetail/${id}`
      );

      toast.dismiss();

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchEarningInvoice = createAsyncThunk(
  "agency/fetchEarningInvoice",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.get(
        `/Agency/GetEarningRequestInvoicePdf/${id}`
      );

      toast.dismiss();

      const byteCharacters = atob(response.data.Value);
      const byteNumbers = new Array(byteCharacters.length)
        .fill()
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blob);

      window.open(fileURL, "_blank");

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchEarningReceipt = createAsyncThunk(
  "agency/fetchEarningReceipt",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.get(
        `/Agency/GetEarningRequestReceiptPdf/${id}`
      );

      toast.dismiss();

      const byteCharacters = atob(response.data.Value);
      const byteNumbers = new Array(byteCharacters.length)
        .fill()
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blob);

      window.open(fileURL, "_blank");

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const uploadReceipt = createAsyncThunk(
  "agency/uploadReceipt",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      earningRequestId: values.earningRequestId,
      pdfbase64: values.pdfbase64,
    };

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(
        `/Agency/PutReceiptInEarningRequest`,
        data
      );

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const uploadInvoice = createAsyncThunk(
  "agency/uploadInvoice",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      earningRequestId: values.earningRequestId,
      pdfbase64: values.pdfbase64,
      issueDate: values.issueDate,
      invoiceNumber: values.invoiceNumber,
    };

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(
        `/Agency/PutInvoiceInEarningRequest`,
        data
      );

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const agencySlice = createSlice({
  name: "agency",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgency.pending, (state, action) => {
        state.agency = {};
        state.agencyLoading = true;
      })
      .addCase(fetchAgency.fulfilled, (state, action) => {
        state.agency = action.payload;
        state.agencyLoading = false;
      })
      .addCase(fetchAgency.rejected, (state, action) => {
        state.agency = {};
        state.agencyLoading = false;
      });

    builder
      .addCase(fetchAgencies.pending, (state, action) => {
        state.agencies = [];
        state.agenciesLoading = true;
      })
      .addCase(fetchAgencies.fulfilled, (state, action) => {
        state.agencies = action.payload;
        state.agenciesLoading = false;
      })
      .addCase(fetchAgencies.rejected, (state, action) => {
        state.agencies = [];
        state.agenciesLoading = false;
      });

    builder
      .addCase(fetchSubAgencies.pending, (state, action) => {
        state.subAgencies = [];
        state.subAgenciesLoading = true;
      })
      .addCase(fetchSubAgencies.fulfilled, (state, action) => {
        state.subAgencies = action.payload;
        state.subAgenciesLoading = false;
      })
      .addCase(fetchSubAgencies.rejected, (state, action) => {
        state.subAgencies = [];
        state.subAgenciesLoading = false;
      });

    builder
      .addCase(fetchAgencyCommission.pending, (state, action) => {
        state.agencyCommission = 20;
        state.agencyCommissionLoading = true;
      })
      .addCase(fetchAgencyCommission.fulfilled, (state, action) => {
        state.agencyCommission = action.payload;
        state.agencyCommissionLoading = false;
      })
      .addCase(fetchAgencyCommission.rejected, (state, action) => {
        state.agencyCommission = 20;
        state.agencyCommissionLoading = false;
      });

    builder
      .addCase(createAgency.pending, (state, action) => {
        state.createAgencyLoading = true;
      })
      .addCase(createAgency.fulfilled, (state, action) => {
        state.createAgencyLoading = false;
        state.agenciesRefresh = !state.agenciesRefresh;
        state.subAgenciesRefresh = !state.subAgenciesRefresh;
      })
      .addCase(createAgency.rejected, (state, action) => {
        state.createAgencyLoading = false;
      });

    builder
      .addCase(updateAgency.pending, (state, action) => {
        state.updateAgencyLoading = true;
      })
      .addCase(updateAgency.fulfilled, (state, action) => {
        state.updateAgencyLoading = false;
        state.agenciesRefresh = !state.agenciesRefresh;
        state.subAgenciesRefresh = !state.subAgenciesRefresh;
      })
      .addCase(updateAgency.rejected, (state, action) => {
        state.updateAgencyLoading = false;
      });

    builder
      .addCase(deleteAgency.pending, (state, action) => {
        state.deleteAgencyLoading = true;
      })
      .addCase(deleteAgency.fulfilled, (state, action) => {
        state.deleteAgencyLoading = false;
        state.agenciesRefresh = !state.agenciesRefresh;
        state.subAgenciesRefresh = !state.subAgenciesRefresh;
      })
      .addCase(deleteAgency.rejected, (state, action) => {
        state.deleteAgencyLoading = false;
      });

    builder
      .addCase(approveAgency.pending, (state, action) => {
        state.approveAgencyLoading = true;
      })
      .addCase(approveAgency.fulfilled, (state, action) => {
        state.approveAgencyLoading = false;
        state.agenciesRefresh = !state.agenciesRefresh;
        state.subAgenciesRefresh = !state.subAgenciesRefresh;
      })
      .addCase(approveAgency.rejected, (state, action) => {
        state.approveAgencyLoading = false;
      });

    builder
      .addCase(fetchAgencyEarnings.pending, (state, action) => {
        state.agencyEarnings = [];
        state.agencyEarningsAmount = null;
        state.agencyMinimumAmount = null;
        state.agencyEarningsLoading = true;
      })
      .addCase(fetchAgencyEarnings.fulfilled, (state, action) => {
        state.agencyEarnings = action.payload.list;
        state.agencyEarningsAmount = action.payload.totalAmount;
        state.agencyMinimumAmount = action.payload.minimumRequestLimit;
        state.agencyEarningsLoading = false;
      })
      .addCase(fetchAgencyEarnings.rejected, (state, action) => {
        state.agencyEarnings = [];
        state.agencyEarningsAmount = null;
        state.agencyMinimumAmount = null;
        state.agencyEarningsLoading = false;
      });

    builder
      .addCase(fetchAgencyEarningsAdmin.pending, (state, action) => {
        state.agencyEarningsAdmin = [];
        state.agencyEarningsAdminLoading = true;
      })
      .addCase(fetchAgencyEarningsAdmin.fulfilled, (state, action) => {
        state.agencyEarningsAdmin = action.payload;
        state.agencyEarningsAdminLoading = false;
      })
      .addCase(fetchAgencyEarningsAdmin.rejected, (state, action) => {
        state.agencyEarningsAdmin = [];
        state.agencyEarningsAdminLoading = false;
      });

    builder
      .addCase(earningRequest.pending, (state, action) => {
        state.earningRequestLoading = true;
      })
      .addCase(earningRequest.fulfilled, (state, action) => {
        state.earningRequestLoading = false;
        state.agencyEarningsRefresh = !state.agencyEarningsRefresh;
        state.agencyEarningsAdminRefresh = !state.agencyEarningsAdminRefresh;
      })
      .addCase(earningRequest.rejected, (state, action) => {
        state.earningRequestLoading = false;
      });

    builder
      .addCase(approveEarning.pending, (state, action) => {
        state.approveEarningLoading = true;
      })
      .addCase(approveEarning.fulfilled, (state, action) => {
        state.approveEarningLoading = false;
        state.agencyEarningsRefresh = !state.agencyEarningsRefresh;
        state.agencyEarningsAdminRefresh = !state.agencyEarningsAdminRefresh;
      })
      .addCase(approveEarning.rejected, (state, action) => {
        state.approveEarningLoading = false;
      });

    builder
      .addCase(uploadReceipt.pending, (state, action) => {
        state.approveEarningLoading = true;
      })
      .addCase(uploadReceipt.fulfilled, (state, action) => {
        state.approveEarningLoading = false;
        state.agencyEarningsRefresh = !state.agencyEarningsRefresh;
        state.agencyEarningsAdminRefresh = !state.agencyEarningsAdminRefresh;
      })
      .addCase(uploadReceipt.rejected, (state, action) => {
        state.approveEarningLoading = false;
      });

    builder
      .addCase(uploadInvoice.pending, (state, action) => {
        state.approveEarningLoading = true;
      })
      .addCase(uploadInvoice.fulfilled, (state, action) => {
        state.approveEarningLoading = false;
        state.agencyEarningsRefresh = !state.agencyEarningsRefresh;
        state.agencyEarningsAdminRefresh = !state.agencyEarningsAdminRefresh;
      })
      .addCase(uploadInvoice.rejected, (state, action) => {
        state.approveEarningLoading = false;
      });

    builder
      .addCase(fetchEarningInvoice.pending, (state, action) => {
        state.earningInvoice = {};
        state.earningInvoiceLoading = true;
      })
      .addCase(fetchEarningInvoice.fulfilled, (state, action) => {
        state.earningInvoice = action.payload;
        state.earningInvoiceLoading = false;
      })
      .addCase(fetchEarningInvoice.rejected, (state, action) => {
        state.earningInvoice = {};
        state.earningInvoiceLoading = false;
      });

    builder
      .addCase(fetchEarningReceipt.pending, (state, action) => {
        state.earningReceipt = {};
        state.earningReceiptLoading = true;
      })
      .addCase(fetchEarningReceipt.fulfilled, (state, action) => {
        state.earningReceipt = action.payload;
        state.earningReceiptLoading = false;
      })
      .addCase(fetchEarningReceipt.rejected, (state, action) => {
        state.earningReceipt = {};
        state.earningReceiptLoading = false;
      });

    builder
      .addCase(fetchEarningRequestDetail.pending, (state, action) => {
        state.earningRequestDetail = {};
        state.earningRequestDetailLoading = true;
      })
      .addCase(fetchEarningRequestDetail.fulfilled, (state, action) => {
        state.earningRequestDetail = action.payload;
        state.earningRequestDetailLoading = false;
      })
      .addCase(fetchEarningRequestDetail.rejected, (state, action) => {
        state.earningRequestDetail = {};
        state.earningRequestDetailLoading = false;
      });
  },
});

export default agencySlice.reducer;
