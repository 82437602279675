import { combineReducers } from "redux";

// Front
import LayoutReducer from "./other/layouts/reducer";

import authSlice from "../slices/apps/authSlice";
import agencySlice from "./apps/agencySlices";
import customerSlice from "./apps/customerSlice";
import packageSlice from "./apps/packageSlice";
import userSlice from "./apps/userSlice";
import agreementSlice from "./apps/agreementSlice";
import paymentSlice from "./apps/paymentSlice";
import dashboardSlice from "./apps/dashboardSlice";
import ticketSlice from "./apps/ticketSlice";

const rootReducer = combineReducers({
  Layout: LayoutReducer,

  auth: authSlice,
  agency: agencySlice,
  agreement: agreementSlice,
  customer: customerSlice,
  dashboard: dashboardSlice,
  package: packageSlice,
  payment: paymentSlice,
  user: userSlice,
  ticket: ticketSlice,
});

export default rootReducer;
