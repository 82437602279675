import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";

// redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchAgencyCountReport,
  fetchAgreementBenefitReport,
} from "../../slices/apps/dashboardSlice";

// sayfalar, modallar vs.
import Widgets from "./Widgets";
import Widgets2 from "./Widgets2";

const Dashboard = () => {
  const dispatch = useDispatch();

  // states
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      await dispatch(fetchAgencyCountReport());
      await dispatch(fetchAgreementBenefitReport());
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [dispatch]);

  document.title = "Ana Sayfa | Global Yol Yardım";
  if (loading) {
    return (
      <React.Fragment>
        <Spinner
          className="position-absolute top-50 start-50"
          animation="border"
          color="primary"
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Widgets />
                </Row>
                <Row>
                  <Widgets2 />
                </Row>
                {/* <Row>
                  <Col lg={6} xl={8}>
                    <Revenue />
                  </Col>
                  <Col lg={6} xl={4}>
                    <Pasta dataColors='["--vz-info", "--vz-primary"]' />
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
