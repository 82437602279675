import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";

// redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchPackage } from "../../slices/apps/packageSlice";

// router
import { useLocation, useNavigate } from "react-router-dom";

// sayfalar, modallar vb.
import Checkout from "./Checkout";

// görseller
import ErrorImg from "../../assets/images/pay-error.png";

const Error = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const encodedData = urlParams.get("m");
  const base64Decoded = decodeURIComponent(encodedData);
  const decodedString = decodeURIComponent(
    Array.from(atob(base64Decoded))
      .map((char) => `%${char.charCodeAt(0).toString(16).padStart(2, "0")}`)
      .join("")
  );

  document.title = "Ödeme Hatalı | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="auth-page-content" style={{ background: "white" }}>
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="auth-page-content overflow-hidden p-0">
            <Container>
              <Row className="justify-content-center">
                <Col xl={4} lg={5}>
                  <div className="text-center">
                    <img
                      src={ErrorImg}
                      alt="page not found img"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-4 text-primary text-center">
                    {decodedString}
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Error;
