import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { uploadInvoice } from "../../slices/apps/agencySlices";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

// Gerekli eklentiyi kayıt edin
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

const EarningInvoiceUploadModal = ({ id, isDisabled, data }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [base64File, setBase64File] = useState("");

  // states
  const { approveEarningLoading } = useSelector((state) => {
    return {
      approveEarningLoading: state.agency.approveEarningLoading,
    };
  }, shallowEqual);

  function tog_large(e) {
    if (e) {
      setFiles([]);
      setBase64File("");
      validation.resetForm();

      setModal(true);
    } else setModal(false);
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      earningRequestId: id,
      issueDate: data?.InvoiceDate,
      invoiceNumber: data?.InvoiceNumber,
      invoicePdfBase64: "",
    },

    validationSchema: Yup.object({
      issueDate: Yup.string(),
      invoiceNumber: Yup.string(),
    }),

    onSubmit: async (values) => {
      const data = {
        ...values,
        pdfbase64: base64File,
      };

      try {
        const resultAction = await dispatch(uploadInvoice(data));

        if (resultAction.meta.requestStatus === "fulfilled") {
          setModal(false);
        }
      } catch (error) {
        console.error("Failed:", error);
      }
    },
  });

  const handleFileProcess = (error, file) => {
    if (error) {
      console.error("File processing failed", error);
    } else {
      const base64File = file.getFileEncodeDataURL();
      const base64Value = base64File.replace(/^data:.*;base64,/, "");
      setBase64File(base64Value);
    }
  };

  const handleRemoveFile = (error, file) => {
    if (error) {
      console.error("File removal failed", error);
    } else {
      setFiles([]);
      setBase64File("");
    }
  };

  if (isDisabled) {
    return (
      <Fragment>
        <span
          style={{
            display: "flex",
            padding: "3px",
          }}
        >
          <i
            className="bx bxs-file-export text-muted"
            style={{
              fontSize: "20px",
            }}
          />
        </span>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <span
        id={`receipt-upload-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bxs-file-export text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`receipt-upload-tooltipTop-${id}`}
      >
        Fatura Yükle
      </UncontrolledTooltip>

      <Modal
        size="md"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Fatura Yükle
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="g-2 mb-2">
              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="invoiceNumber" className="form-label">
                    Fatura Numarası
                  </Label>
                  <Input
                    name="invoiceNumber"
                    className="form-control"
                    placeholder="Fatura Numarası"
                    type="text"
                    autoComplete="off"
                    maxLength={50}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.invoiceNumber || ""}
                    invalid={
                      validation.touched.invoiceNumber &&
                      validation.errors.invoiceNumber
                        ? true
                        : false
                    }
                  />
                  {validation.touched.invoiceNumber &&
                    validation.errors.invoiceNumber && (
                      <FormFeedback type="invalid">
                        {validation.errors.invoiceNumber}
                      </FormFeedback>
                    )}
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label">Fatura Tarihi</Label>
                  <Flatpickr
                    className={
                      validation.touched.issueDate &&
                      validation.errors.issueDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    options={{
                      dateFormat: "d.m.Y",
                      // minDate: "today",
                      locale: { ...Turkish },
                    }}
                    value={
                      validation.values.issueDate
                        ? new Date(validation.values.issueDate)
                        : null
                    }
                    onChange={(date) => {
                      const selectedDate = new Date(date[0]);

                      selectedDate.setHours(6, 0, 0, 0);

                      validation.setFieldValue(
                        "issueDate",
                        selectedDate.toISOString()
                      );
                    }}
                  />
                  {validation.touched.issueDate &&
                    validation.errors.issueDate && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {validation.errors.issueDate}
                      </div>
                    )}
                </div>
              </Col>

              <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                maxFiles={1}
                className="filepond filepond-input-single"
                labelIdle="Fatura (PDF) yükle"
                acceptedFileTypes={["application/pdf"]}
                onaddfile={handleFileProcess}
                onremovefile={handleRemoveFile}
              />
            </Row>

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
                disabled={approveEarningLoading || !base64File}
              >
                Kaydet
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EarningInvoiceUploadModal;
