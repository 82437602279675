import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  ticket: {},
  ticketLoading: true,
  ticketRefresh: true,

  tickets: [],
  ticketsLoading: true,
  ticketsRefresh: true,

  createTicketLoading: false,
  updateTicketLoading: false,
  deleteTicketLoading: false,
};

export const fetchTicket = createAsyncThunk(
  "ticket/fetchTicket",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    try {
      const response = await baseApi.get(`/Ticket/${id}`);

      return response.data.result;
    } catch (error) {
      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const fetchTickets = createAsyncThunk(
  "ticket/fetchTickets",
  async () => {
    try {
      const response = await baseApi.get(`/Ticket`);

      return response.data.result;
    } catch (error) {
      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const createTicket = createAsyncThunk(
  "ticket/createTicket",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: 0,
      ticketNo: values.ticketNo,
      agencyId: values.agencyId,
      subject: values.subject,
      createDatetime: values.createDatetime,
      answerDatetime: values.answerDatetime,
      ticketStatus: values.ticketStatus,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Ticket`, data);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const updateTicket = createAsyncThunk(
  "ticket/updateTicket",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      ticketNo: values.ticketNo,
      agencyId: values.agencyId,
      subject: values.subject,
      createDatetime: values.createDatetime,
      answerDatetime: values.answerDatetime,
      ticketStatus: values.ticketStatus,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Ticket/${values.id}`, data);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const deleteTicket = createAsyncThunk(
  "ticket/deleteTicket",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.delete(`/Ticket/${id}`);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const ticketSlice = createSlice({
  name: "ticket",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicket.pending, (state, action) => {
        state.ticket = {};
        state.ticketLoading = true;
      })
      .addCase(fetchTicket.fulfilled, (state, action) => {
        state.ticket = action.payload;
        state.ticketLoading = false;
      })
      .addCase(fetchTicket.rejected, (state, action) => {
        state.ticket = {};
        state.ticketLoading = false;
      });

    builder
      .addCase(fetchTickets.pending, (state, action) => {
        state.tickets = [];
        state.ticketsLoading = true;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        state.tickets = action.payload;
        state.ticketsLoading = false;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.tickets = [];
        state.ticketsLoading = false;
      });

    builder
      .addCase(createTicket.pending, (state, action) => {
        state.createTicketLoading = true;
      })
      .addCase(createTicket.fulfilled, (state, action) => {
        state.createTicketLoading = false;
        state.ticketsRefresh = !state.ticketsRefresh;
      })
      .addCase(createTicket.rejected, (state, action) => {
        state.createTicketLoading = false;
      });

    builder
      .addCase(updateTicket.pending, (state, action) => {
        state.updateTicketLoading = true;
        state.ticketsLoading = true;
      })
      .addCase(updateTicket.fulfilled, (state, action) => {
        state.updateTicketLoading = false;
        state.ticketsRefresh = !state.ticketsRefresh;
      })
      .addCase(updateTicket.rejected, (state, action) => {
        state.updateTicketLoading = false;
      });

    builder
      .addCase(deleteTicket.pending, (state, action) => {
        state.deleteTicketLoading = true;
      })
      .addCase(deleteTicket.fulfilled, (state, action) => {
        state.deleteTicketLoading = false;
        state.ticketsRefresh = !state.ticketsRefresh;
      })
      .addCase(deleteTicket.rejected, (state, action) => {
        state.deleteTicketLoading = false;
      });
  },
});

export default ticketSlice.reducer;
