import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../slices/apps/customerSlice";
import { fetchAgencies } from "../../slices/apps/agencySlices";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// sayfalar, modallar vb.
import CustomerTable from "./CustomerTable";

const Customers = () => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  const { customersRefresh } = useSelector((state) => {
    return {
      customersRefresh: state.customer.customersRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchCustomers());
      if (userProfile.data.Role == "SuperAdmin") {
        await dispatch(fetchAgencies());
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, customersRefresh]);

  document.title = "Müşteri Listesi | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <CustomerTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Customers;
