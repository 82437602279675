import React, { Fragment, useEffect, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchEarningReceipt } from "../../slices/apps/agencySlices";

const EarningReceiptModal = ({ id }) => {
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      await dispatch(fetchEarningReceipt(id));
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    }
  }

  return (
    <Fragment>
      <span
        id={`earningReceipt-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-money-withdraw text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`earningReceipt-tooltipTop-${id}`}
      >
        Dekont Görüntüle
      </UncontrolledTooltip>
    </Fragment>
  );
};

export default EarningReceiptModal;
