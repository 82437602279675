import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchTickets } from "../../slices/apps/ticketSlice";

// sayfalar, modallar vs.
import TicketTable from "./TicketTable";

const Ticket = () => {
  const dispatch = useDispatch();

  const { ticketsRefresh } = useSelector((state) => {
    return {
      ticketsRefresh: state.ticket.ticketsRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchTickets());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, ticketsRefresh]);

  document.title = "Talepler | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <TicketTable />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Ticket;
