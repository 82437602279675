import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner,
  InputGroup,
} from "reactstrap";

// redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  authCheck,
  loginUser,
  resetLoginFlag,
} from "../../slices/apps/authSlice";

// router
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// diğer importlar
import { Bounce, toast } from "react-toastify";

// görsel importlar
import Logo from "../../assets/images/logo/global-assistance-logo.png";

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, loading, errorMsg } = useSelector((state) => {
    return {
      error: state.auth.error,
      loading: state.auth.loading,
      errorMsg: state.auth.errorMsg,
    };
  }, shallowEqual);

  const [passwordShow, setPasswordShow] = useState(false);

  // kullanıcı giriş yapmış ise ana sayfaya yönlendirir.
  useEffect(() => {
    const authCheckFunc = async () => {
      try {
        const resultAction = await dispatch(authCheck());
        if (resultAction.meta.requestStatus === "fulfilled") {
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Failed:", error);
      }
    };
    authCheckFunc();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Lütfen geçerli bir e-posta adresi giriniz")
        .required("Lütfen e-posta adresinizi giriniz"),
      password: Yup.string()
        .required("Lütfen şifrenizi giriniz")
        .min(6, "Lütfen şifrenizi giriniz"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  // giriş başarısız ise error mesaj kutusu 5sn gözükür kaybolur
  useEffect(() => {
    if (errorMsg) {
      toast.error(error, {
        style: { background: "#e30a17" },
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 1000);
    }
  }, [dispatch, errorMsg]);

  document.title = "Giriş Yap | Global Yol Yardım";
  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "#FDFDFD",
        }}
      >
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="text-center pt-4 pb-4 text-white-50 bg-dark">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img src={Logo} alt="Global Yol Yardım" height="85" />
                      </Link>
                    </div>
                  </div>
                  <div className="p-lg-5 p-4">
                    <div>
                      <div className="mb-3">
                        <h5 className="text-primary ">Giriş Yap</h5>
                      </div>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            E-posta
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="E-posta giriniz"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            )}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Şifrenizi mi unuttunuz?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Şifre
                          </Label>
                          <InputGroup>
                            <span
                              className="input-group-text"
                              style={{ width: "44px" }}
                              id="inputGroupPrepend2"
                            >
                              <i
                                onClick={() => setPasswordShow(!passwordShow)}
                                style={{
                                  cursor: "pointer",
                                  color: "gray",
                                }}
                                className={
                                  passwordShow
                                    ? "ri-eye-off-fill align-middle"
                                    : "ri-eye-fill align-middle"
                                }
                              ></i>
                            </span>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Şifreniz"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                          </InputGroup>
                          {validation.touched.password &&
                            validation.errors.password && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {validation.errors.password}
                              </div>
                            )}
                        </div>

                        <div className="mt-4">
                          {!loading ? (
                            <Button
                              color="primary"
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Giriş Yap
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              disabled={error ? null : loading ? true : false}
                              className="btn btn-primary w-100"
                            >
                              <Spinner size="sm" className="me-2" /> Yükleniyor
                            </Button>
                          )}
                        </div>

                        <hr className="my-4 text-muted" />

                        <div className="text-center">
                          <Button
                            color="danger"
                            disabled={error ? null : loading ? true : false}
                            className="btn btn-danger w-100"
                            onClick={() => {
                              navigate("/agency-register");
                            }}
                          >
                            Acente Başvurusu
                          </Button>

                          <hr className="my-4 text-muted" />

                          <h5 className="text-primary mt-2 mb-0">
                            0 850 242 70 42
                          </h5>
                          <h5 className="text-primary mt-2 mb-0">
                            info@globalyolyardim.com
                          </h5>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer mb-2">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p
                    className="mb-2"
                    style={{ color: "#808080", letterSpacing: "0.8px" }}
                  >
                    Global Yol Yardım &copy; {new Date().getFullYear()} | 0 850
                    242 70 42 | info@globalyolyardim.com
                    <br />
                    <i className="mdi mdi-heart text-danger"></i> by{" "}
                    <a
                      href="https://cemrek.com.tr"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Çemrek Bilgi Teknolojileri
                    </a>
                    <br />
                    <a
                      href="https://globalassistance.com.tr/kvkk"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Kişisel Verilerin Korunması Kanunu (KVKK)
                    </a>{" "}
                    |{" "}
                    <a
                      href="https://globalassistance.com.tr/mesafeli-satis-sozlesmesi"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Mesafeli Satış Sözleşmesi
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
