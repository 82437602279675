import React from "react";
import { Card, CardBody, Col } from "reactstrap";

// redux
import { shallowEqual, useSelector } from "react-redux";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

const Widgets = () => {
  const { userProfile } = useProfile();

  const { agreementBenefitReport } = useSelector((state) => {
    return {
      agreementBenefitReport: state.dashboard.agreementBenefitReport,
    };
  }, shallowEqual);

  const values = [
    {
      id: 1,
      cardColor: "primary",
      label: "Toplam Sözleşme",
      percentage: agreementBenefitReport?.AggreementCount || "0",
      counter: agreementBenefitReport?.AggreementBenefit || "0",
      bgcolor: "primary",
      icon: "ri-bar-chart-2-line",
    },
    {
      id: 2,
      cardColor: "info",
      label: "Aktif Sözleşme",
      percentage: agreementBenefitReport?.ActiveAggreementCount || "0",
      counter: agreementBenefitReport?.ActiveAggreementBenefit || "0",
      bgcolor: "info",
      icon: "ri-checkbox-circle-line",
    },
    {
      id: 3,
      cardColor: "warning",
      label: "Bekleyen Sözleşme",
      percentage: agreementBenefitReport?.WaitingAggreementCount || "0",
      counter: agreementBenefitReport?.WaitingAggreementBenefit || "0",
      bgcolor: "warning",
      icon: "ri-time-line",
    },
  ];

  const valuesForAdmin = [
    {
      id: 0,
      cardColor: "primary",
      label: "Toplu Sözleşme",
      percentage: agreementBenefitReport?.BulkAggreementCount || "0",
      counter: agreementBenefitReport?.BulkAggreementTotal || "0",
      bgcolor: "primary",
      icon: "ri-bar-chart-2-line",
    },
    {
      id: 1,
      cardColor: "primary",
      label: "Toplam Sözleşme",
      percentage: agreementBenefitReport?.AggreementCount || "0",
      counter: agreementBenefitReport?.AggreementBenefit || "0",
      bgcolor: "primary",
      icon: "ri-bar-chart-2-line",
    },
    {
      id: 2,
      cardColor: "info",
      label: "Aktif Sözleşme",
      percentage: agreementBenefitReport?.ActiveAggreementCount || "0",
      counter: agreementBenefitReport?.ActiveAggreementBenefit || "0",
      bgcolor: "info",
      icon: "ri-checkbox-circle-line",
    },
    {
      id: 3,
      cardColor: "warning",
      label: "Bekleyen Sözleşme",
      percentage: agreementBenefitReport?.WaitingAggreementCount || "0",
      counter: agreementBenefitReport?.WaitingAggreementBenefit || "0",
      bgcolor: "warning",
      icon: "ri-time-line",
    },
  ];

  return (
    <React.Fragment>
      {userProfile?.data?.Role == "SuperAdmin"
        ? valuesForAdmin.map((item, key) => (
            <Col xl={3} md={6} key={key}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        {item.label}
                      </p>
                    </div>
                    <div className="flex-shrink-0"></div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h5>{item.percentage} Adet</h5>
                      <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                        <span className="counter-value" data-target="559.25">
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(item.counter)}{" "}
                          ₺
                        </span>
                      </h4>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span
                        className={
                          "avatar-title rounded fs-3 bg-" +
                          item.bgcolor +
                          "-subtle"
                        }
                      >
                        <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))
        : values.map((item, key) => (
            <Col xl={4} md={6} key={key}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        {item.label}
                      </p>
                    </div>
                    <div className="flex-shrink-0"></div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h5>{item.percentage} Adet</h5>
                      <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                        <span className="counter-value" data-target="559.25">
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(item.counter)}{" "}
                          ₺
                        </span>
                      </h4>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span
                        className={
                          "avatar-title rounded fs-3 bg-" +
                          item.bgcolor +
                          "-subtle"
                        }
                      >
                        <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
    </React.Fragment>
  );
};

export default Widgets;
