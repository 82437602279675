import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner,
  InputGroup,
  Alert,
} from "reactstrap";

// redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { authCheck, registerAgency } from "../../slices/apps/authSlice";

// router
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import ReactInputMask from "react-input-mask";

// data dosyaları
import CityJson from "../../common/data/city.json";

// görsel importlar
import Logo from "../../assets/images/logo/global-assistance-logo.png";

const city = CityJson.map((data) => ({
  label: data.il_adi,
  value: data.il_adi,
  districts: data.ilceler
    .map((ilce) => ({
      label: ilce.ilce_adi,
      value: ilce.ilce_adi,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
})).sort((a, b) => a.label.localeCompare(b.label));

// regex, değişkenler
const phoneRegExp = /^(?:[1-9][0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}|[1-9][0-9]{9})$/;
const ibanRegex = /^TR\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{2}$/;

const AgencyRegister = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordShow, setPasswordShow] = useState(false);

  const { registerAgencyLoading } = useSelector((state) => {
    return {
      registerAgencyLoading: state.auth.registerAgencyLoading,
    };
  }, shallowEqual);

  // kullanıcı giriş yapmış ise ana sayfaya yönlendirir.
  useEffect(() => {
    const authCheckFunc = async () => {
      try {
        const resultAction = await dispatch(authCheck());
        if (resultAction.meta.requestStatus === "fulfilled") {
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Failed:", error);
      }
    };
    authCheckFunc();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      companyName: "",
      agencyEmail: "",
      agencyPhoneNumber: "",
      address: "",
      city: "Adana",
      district: "",
      postCode: "",
      taxNumber: "",
      taxOffice: "",
      bankIban: "",
      bankName: "",
      agencyType: 0,
      referenceCode: "",

      nameSurname: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Lütfen firma adı girin"),
      referenceCode: Yup.string().nullable(),

      agencyEmail: Yup.string()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .required("Lütfen e-posta adresi girin"),
      agencyPhoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
      address: Yup.string()
        .required("Lütfen adres bilgisi girin")
        .min(2, "Lütfen adres bilgisi girin"),
      city: Yup.string().required("Lütfen il seçin"),
      district: Yup.string().required("Lütfen ilçe seçin"),
      postCode: Yup.string(),
      taxNumber: Yup.string()
        .matches(/^[0-9]+$/, "VKN/TCKN sadece sayılardan oluşmalıdır")
        .min(10, "VKN/TCKN en az 10 karakter olmalıdır")
        .max(11, "VKN/TCKN en fazla 11 karakter olmalıdır")
        .required("Lütfen VKN/TCKN girin"),
      taxOffice: Yup.string().required("Lütfen vergi dairesi girin"),
      bankIban: Yup.string()
        .required("Lütfen IBAN girin")
        .matches(ibanRegex, "Geçerli bir IBAN girin"),
      bankName: Yup.string().required("Lütfen banka adı girin"),
      agencyType: Yup.number()
        .required("Lütfen acente tipi seçin")
        .integer("Lütfen acente tipi seçin"),

      nameSurname: Yup.string().required("Lütfen kullanıcı ad soyad girin"),
      phoneNumber: Yup.string()
        .required("Lütfen kullanıcı telefon numarası girin")
        .matches(
          phoneRegExp,
          "Lütfen geçerli bir kullanıcı telefon numarası girin"
        ),
      email: Yup.string()
        .required("Lütfen kullanıcı e-posta adresi girin")
        .email("Lütfen geçerli bir kullanıcı e-posta adresi girin"),
      password: Yup.string()
        .required("Lütfen şifre girin")
        .min(6, "Lütfen şifre girin"),
    }),
    onSubmit: async (values) => {
      const resultAction = await dispatch(registerAgency(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        navigate("/login");
      }
    },
  });

  document.title = "Kayıt Ol | Global Yol Yardım";
  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "#FDFDFD",
        }}
      >
        <div className="auth-page-content overflow-hidden ">
          <Container>
            <Row className="justify-content-center">
              <Col md={10} lg={10} xl={8}>
                <Card className="overflow-hidden">
                  <div className="text-center pt-4 pb-4 text-white-50 bg-dark">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img src={Logo} alt="Global Yol Yardım" height="85" />
                      </Link>
                    </div>
                  </div>
                  <div className="p-lg-5 p-4">
                    <div>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <Row className="align-items-start">
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="agencyType"
                              >
                                Acente Tipi
                              </Label>
                              <div className="form-check">
                                <Input
                                  name="agencyType"
                                  id="agencyType1"
                                  className="form-check-input"
                                  type="radio"
                                  value={"0"}
                                  checked={
                                    parseInt(validation.values.agencyType) === 0
                                  }
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="agencyType1"
                                >
                                  Acente
                                </Label>
                              </div>
                              <div className="form-check">
                                <Input
                                  name="agencyType"
                                  id="agencyType2"
                                  className="form-check-input"
                                  type="radio"
                                  value={"1"}
                                  checked={
                                    parseInt(validation.values.agencyType) === 1
                                  }
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="agencyType2"
                                >
                                  Yol Yardım / Kurtarma
                                </Label>
                              </div>
                            </div>
                          </Col>

                          <h4 className="mt-4">Firma Kullanıcı Bilgileri</h4>
                          <hr className="mb-4 text-muted" />

                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="nameSurname"
                                className="form-label"
                              >
                                Ad Soyad
                              </Label>
                              <Input
                                name="nameSurname"
                                className="form-control"
                                placeholder="Ad Soyad"
                                type="text"
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameSurname || ""}
                                invalid={
                                  validation.touched.nameSurname &&
                                  validation.errors.nameSurname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.nameSurname &&
                                validation.errors.nameSurname && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameSurname}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Şifre
                              </Label>
                              <InputGroup>
                                <span
                                  className="input-group-text"
                                  style={{ width: "44px" }}
                                  id="inputGroupPrepend2"
                                >
                                  <i
                                    onClick={() =>
                                      setPasswordShow(!passwordShow)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color: "gray",
                                    }}
                                    className={
                                      passwordShow
                                        ? "ri-eye-off-fill align-middle"
                                        : "ri-eye-fill align-middle"
                                    }
                                  ></i>
                                </span>
                                <Input
                                  name="password"
                                  value={validation.values.password || ""}
                                  type={passwordShow ? "text" : "password"}
                                  className="form-control pe-5"
                                  placeholder="Şifreniz"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                              </InputGroup>
                              {validation.touched.password &&
                                validation.errors.password && (
                                  <div
                                    type="invalid"
                                    className="d-block invalid-feedback"
                                  >
                                    {validation.errors.password}
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="phoneNumber"
                              >
                                Telefon Numarası
                              </Label>
                              <InputGroup>
                                <span
                                  className="input-group-text "
                                  style={{
                                    width: "44px",
                                    justifyContent: "center",
                                  }}
                                  id="inputGroupPrepend2"
                                >
                                  0
                                </span>
                                <ReactInputMask
                                  mask="999 999 99 99"
                                  maskChar=" "
                                  name="phoneNumber"
                                  placeholder="Telefon Numarası"
                                  className={
                                    validation.touched.phoneNumber &&
                                    validation.errors.phoneNumber
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={validation.handleChange}
                                  value={validation.values.phoneNumber || ""}
                                  style={{ zIndex: 0 }}
                                />
                              </InputGroup>
                              {validation.touched.phoneNumber &&
                                validation.errors.phoneNumber && (
                                  <div
                                    type="invalid"
                                    className="d-block invalid-feedback"
                                  >
                                    {validation.errors.phoneNumber}
                                  </div>
                                )}
                              <small style={{ color: "#cb1425" }}>
                                *Onay SMS'i gönderilecektir.
                              </small>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                E-posta Adresi
                              </Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="E-posta Adresi"
                                type="text"
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <h4 className="mt-4">Firma Bilgileri</h4>
                          <hr className="mb-4 text-muted" />

                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="companyName"
                                className="form-label"
                              >
                                Firma Adı
                              </Label>
                              <Input
                                name="companyName"
                                className="form-control"
                                placeholder="Firma Adı"
                                type="text"
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyName || ""}
                                invalid={
                                  validation.touched.companyName &&
                                  validation.errors.companyName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.companyName &&
                                validation.errors.companyName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.companyName}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="referenceCode"
                                className="form-label"
                              >
                                Referans Kodu
                              </Label>
                              <Input
                                name="referenceCode"
                                className="form-control"
                                placeholder="Referans Kodu"
                                type="text"
                                autoComplete="off"
                                maxLength={50}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.referenceCode || ""}
                                invalid={
                                  validation.touched.referenceCode &&
                                  validation.errors.referenceCode
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.referenceCode &&
                                validation.errors.referenceCode && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.referenceCode}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="taxNumber">
                                VKN/TCKN
                              </Label>
                              <InputGroup>
                                <input
                                  name="taxNumber"
                                  placeholder="VKN/TCKN"
                                  inputMode="numeric"
                                  type="text"
                                  autoComplete="off"
                                  maxLength={11}
                                  className={
                                    validation.touched.taxNumber &&
                                    validation.errors.taxNumber
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );

                                    validation.setFieldValue(
                                      "taxNumber",
                                      value
                                    );
                                  }}
                                  value={validation.values.taxNumber || ""}
                                />
                              </InputGroup>
                              {validation.touched.taxNumber &&
                                validation.errors.taxNumber && (
                                  <div
                                    type="invalid"
                                    className="d-block invalid-feedback"
                                  >
                                    {validation.errors.taxNumber}
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="taxOffice" className="form-label">
                                Vergi Dairesi
                              </Label>
                              <Input
                                name="taxOffice"
                                className="form-control"
                                placeholder="Vergi Dairesi"
                                type="text"
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.taxOffice || ""}
                                invalid={
                                  validation.touched.taxOffice &&
                                  validation.errors.taxOffice
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.taxOffice &&
                                validation.errors.taxOffice && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.taxOffice}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="agencyEmail"
                                className="form-label"
                              >
                                Firma E-posta Adresi
                              </Label>
                              <Input
                                name="agencyEmail"
                                className="form-control"
                                placeholder="Firma E-posta Adresi"
                                type="text"
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.agencyEmail || ""}
                                invalid={
                                  validation.touched.agencyEmail &&
                                  validation.errors.agencyEmail
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.agencyEmail &&
                                validation.errors.agencyEmail && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.agencyEmail}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="agencyPhoneNumber"
                              >
                                Telefon Numarası
                              </Label>
                              <InputGroup>
                                <span
                                  className="input-group-text "
                                  style={{
                                    width: "44px",
                                    justifyContent: "center",
                                  }}
                                  id="inputGroupPrepend2"
                                >
                                  0
                                </span>
                                <ReactInputMask
                                  mask="999 999 99 99"
                                  maskChar=" "
                                  name="agencyPhoneNumber"
                                  placeholder="Telefon Numarası"
                                  className={
                                    validation.touched.agencyPhoneNumber &&
                                    validation.errors.agencyPhoneNumber
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={validation.handleChange}
                                  value={
                                    validation.values.agencyPhoneNumber || ""
                                  }
                                  style={{ zIndex: 0 }}
                                />
                              </InputGroup>
                              {validation.touched.agencyPhoneNumber &&
                                validation.errors.agencyPhoneNumber && (
                                  <div
                                    type="invalid"
                                    className="d-block invalid-feedback"
                                  >
                                    {validation.errors.agencyPhoneNumber}
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label
                                htmlFor="citySelect"
                                className="form-label"
                              >
                                İl
                              </Label>
                              <Select
                                placeholder="İl seçiniz"
                                id="citySelect"
                                name="city"
                                options={city}
                                noOptionsMessage={() => "Seçenek bulunamadı"}
                                isClearable={true}
                                value={city.find(
                                  (item) =>
                                    item.value === validation.values.city
                                )}
                                onChange={(value) => {
                                  validation.setFieldValue(
                                    "city",
                                    value ? value.value : ""
                                  );
                                  validation.setFieldValue("district", "");
                                }}
                                onBlur={validation.handleBlur}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor:
                                      validation.touched.city &&
                                      validation.errors.city &&
                                      !state.isFocused
                                        ? "#e30a17"
                                        : "#e9ebec",
                                  }),
                                }}
                              />
                              {validation.touched.city &&
                                validation.errors.city && (
                                  <div className="d-block invalid-feedback">
                                    {validation.errors.city}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label
                                htmlFor="districtSelect"
                                className="form-label"
                              >
                                İlçe
                              </Label>
                              <Select
                                isDisabled={!validation.values.city}
                                placeholder="İlçe seçiniz"
                                id="districtSelect"
                                name="district"
                                options={
                                  city.find(
                                    (item) =>
                                      item.value === validation.values.city
                                  )?.districts || []
                                }
                                noOptionsMessage={() => "Seçenek bulunamadı"}
                                isClearable={true}
                                value={
                                  city
                                    .find(
                                      (item) =>
                                        item.value === validation.values.city
                                    )
                                    ?.districts.find(
                                      (district) =>
                                        district.value ===
                                        validation.values.district
                                    ) || ""
                                }
                                onChange={(value) => {
                                  validation.setFieldValue(
                                    "district",
                                    value ? value.value : ""
                                  );
                                }}
                                onBlur={validation.handleBlur}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor:
                                      validation.touched.district &&
                                      validation.errors.district &&
                                      !state.isFocused
                                        ? "#e30a17"
                                        : "#e9ebec",
                                  }),
                                }}
                              />
                              {validation.touched.district &&
                                validation.errors.district && (
                                  <div className="d-block invalid-feedback">
                                    {validation.errors.district}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="postCode">
                                Posta Kodu
                              </Label>
                              <InputGroup>
                                <ReactInputMask
                                  mask="99999"
                                  maskChar=" "
                                  name="postCode"
                                  alwaysShowMask
                                  inputMode="numeric"
                                  type="tel"
                                  className={
                                    validation.touched.postCode &&
                                    validation.errors.postCode
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  onChange={validation.handleChange}
                                  value={validation.values.postCode || ""}
                                  style={{ zIndex: 0 }}
                                />
                              </InputGroup>
                              {validation.touched.postCode &&
                                validation.errors.postCode && (
                                  <div
                                    type="invalid"
                                    className="d-block invalid-feedback"
                                  >
                                    {validation.errors.postCode}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label htmlFor="address" className="form-label">
                                Adres
                              </Label>
                              <Input
                                name="address"
                                className="form-control"
                                placeholder="Mahalle, cadde, sokak..."
                                type="textarea"
                                autoComplete="off"
                                rows="2"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                                style={{ resize: "none" }}
                              />
                              {validation.touched.address &&
                                validation.errors.address && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="bankName" className="form-label">
                                Banka Adı
                              </Label>
                              <Input
                                name="bankName"
                                className="form-control"
                                placeholder="Banka Adı"
                                type="text"
                                autoComplete="off"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.bankName || ""}
                                invalid={
                                  validation.touched.bankName &&
                                  validation.errors.bankName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.bankName &&
                                validation.errors.bankName && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.bankName}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="bankIban" className="form-label">
                                IBAN
                              </Label>
                              <ReactInputMask
                                mask="TR99 9999 9999 9999 9999 9999 99"
                                maskChar=" "
                                value={validation.values.bankIban || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                style={{ zIndex: 0 }}
                              >
                                {(inputProps) => (
                                  <Input
                                    {...inputProps}
                                    name="bankIban"
                                    className="form-control"
                                    placeholder="IBAN"
                                    type="text"
                                    autoComplete="off"
                                    invalid={
                                      validation.touched.bankIban &&
                                      validation.errors.bankIban
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              </ReactInputMask>
                              {validation.touched.bankIban &&
                                validation.errors.bankIban && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.bankIban}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>

                          <div className="mt-4">
                            {!registerAgencyLoading ? (
                              <Button
                                color="primary"
                                className="btn btn-primary w-100"
                                type="submit"
                              >
                                Kayıt Ol
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                disabled={registerAgencyLoading ? true : false}
                                className="btn btn-primary w-100"
                              >
                                <Spinner size="sm" className="me-2" />{" "}
                                Yükleniyor
                              </Button>
                            )}
                          </div>

                          <hr className="my-4 text-muted" />

                          <div className="text-center">
                            <Link
                              to="/login"
                              className="text-primary text-decoration-underline fst-normal fw-medium"
                            >
                              Giriş Yap
                            </Link>

                            <hr className="my-4 text-muted" />

                            <h5 className="text-primary mt-2 mb-0">
                              0 850 242 70 42
                            </h5>
                            <h5 className="text-primary mt-2 mb-0">
                              info@globalyolyardim.com
                            </h5>
                          </div>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer mb-2">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p
                    className="mb-2"
                    style={{ color: "#808080", letterSpacing: "0.8px" }}
                  >
                    Global Yol Yardım &copy; {new Date().getFullYear()} | 0 850
                    242 70 42 | info@globalyolyardim.com
                    <br />
                    <i className="mdi mdi-heart text-danger"></i> by{" "}
                    <a
                      href="https://cemrek.com.tr"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Çemrek Bilgi Teknolojileri
                    </a>
                    <br />
                    <a
                      href="https://globalassistance.com.tr/kvkk"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Kişisel Verilerin Korunması Kanunu (KVKK)
                    </a>{" "}
                    |{" "}
                    <a
                      href="https://globalassistance.com.tr/mesafeli-satis-sozlesmesi"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#007bff" }}
                    >
                      Mesafeli Satış Sözleşmesi
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AgencyRegister);
