import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

import "../../assets/scss/agreement.css";

// diğer importlar
import classnames from "classnames";

// router
import { Link } from "react-router-dom";

// diğer importlar
import parse from "html-react-parser";

const PackageDetailModal = ({ item }) => {
  const [modal, setModal] = useState(false);
  const [tabs] = useState(["0", "1"]);
  const [activeTab, setActiveTab] = useState("0");

  function tog_large(e) {
    setModal(e);
  }

  // tab değeri değiştirme
  const tabChange = (newTab) => {
    if (activeTab !== newTab) {
      setActiveTab(newTab);
    }
  };

  return (
    <Fragment>
      <span
        id={`package-tooltipTop-${item.id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-search text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`package-tooltipTop-${item.id}`}
      >
        Paket Bilgilerini Görüntüle
      </UncontrolledTooltip>

      <Modal
        size="xl"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          {item.packageName}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={7}>
              <Card>
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === tabs[0],
                        })}
                        onClick={() => {
                          tabChange("0");
                        }}
                        type="button"
                      >
                        Açıklama
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === tabs[1],
                        })}
                        onClick={() => {
                          tabChange("1");
                        }}
                        type="button"
                      >
                        Sözleşme
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
              </Card>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={tabs[0]}>
                  <div className="agreement-content p-2 pt-0">
                    {parse(item.description || "")}
                  </div>
                </TabPane>
                <TabPane tabId={tabs[1]}>
                  <div className="agreement-content p-2 pt-0">
                    {parse(item.agreement || "")}
                  </div>
                </TabPane>
              </TabContent>
            </Col>
            <Col lg={5}>
              <div className="sticky-side-div mb-4 mt-2">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      {item.totalLimit > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Sözleşmeyi Kapsayan 1 Yıllık Tutar:</span>
                              <span className="fw-light">
                                {item.totalLimitDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {new Intl.NumberFormat("tr-TR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item.totalLimit)}{" "}
                              TL
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.accident > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Kaza Durumunda:</span>
                              <span className="fw-light">
                                {item.accidentDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.accident}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.fault > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Arıza Durumunda:</span>
                              <span className="fw-light">{item.faultDesc}</span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.fault}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.tire > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Lastik Patlamasında:</span>
                              <span className="fw-light">{item.tireDesc}</span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.tire}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.fuel > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Yakıt Bitmesinde:</span>
                              <span className="fw-light">{item.fuelDesc}</span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.fuel}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.windshield > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Ön Cam Patlaması/Kırılması Durumunda:</span>
                              <span className="fw-light">
                                {item.windshieldDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.windshield}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.landslide > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Heyelan Durumunda:</span>
                              <span className="fw-light">
                                {item.landslideDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.landslide}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.headlight > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Far Arıza Durumunda:</span>
                              <span className="fw-light">
                                {item.headlightDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.headlight}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.health > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Sağlık Sorunları Durumunda:</span>
                              <span className="fw-light">
                                {item.healthDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.health}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.tired > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>
                                Yorgunluktan Kaynaklı Tek Sürücülü Araçlar İçin:
                              </span>
                              <span className="fw-light">{item.tiredDesc}</span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.tired}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.evFault > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>
                                Elektrikli Araçların Yolda Kalması Durumunda:
                              </span>
                              <span className="fw-light">
                                {item.evFaultDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.evFault}
                            </span>
                          </td>
                        </tr>
                      )}

                      {item.wildAnimal > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>
                                Yabani Hayvanların Aracı Hareket Edemez Hale
                                Getirdiği Durumunda:
                              </span>
                              <span className="fw-light">
                                {item.wildAnimalDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {item.wildAnimal}
                            </span>
                          </td>
                        </tr>
                      )}

                      <tr className="table-active">
                        <th>Fiyat:</th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {new Intl.NumberFormat("tr-TR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(item.price)}{" "}
                            TL
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default PackageDetailModal;
