import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  customer: {},
  customerLoading: true,
  customerRefresh: true,

  customers: [],
  customersLoading: true,
  customersRefresh: true,

  customersAgency: [],
  customersAgencyLoading: true,
  customersAgencyRefresh: true,

  createCustomerLoading: false,
  updateCustomerLoading: false,
  deleteCustomerLoading: false,
};

export const fetchCustomer = createAsyncThunk(
  "customer/fetchCustomer",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.get(`/Customer/${id}`);

      toast.dismiss();

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchCustomers = createAsyncThunk(
  "customer/fetchCustomers",
  async () => {
    try {
      const response = await baseApi.get(`/Customer`);

      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description ||
          error.response?.status ||
          error.response?.data?.errorMessage
      );
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: 0,
      customerName: values.customerName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      isActive: true,
      taxNumber: values.taxNumber.trim(),
      taxOffice: values.taxOffice,
      city: values.city,
      district: values.district,
      address: values.address,
      agencyId: values.agencyId,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Customer`, data);

      toast.update(loadingToast, {
        render: "Kaydedildi.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      customerName: values.customerName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      isActive: values.isActive,
      taxNumber: values.taxNumber.trim(),
      taxOffice: values.taxOffice,
      city: values.city,
      district: values.district,
      address: values.address,
      agencyId: values.agencyId,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Customer/${values.id}`, data);

      toast.update(loadingToast, {
        render: "Kaydedildi.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

// pasif
export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.delete(`/Customer/${id}`);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

// pasif
export const fetchCustomersOfAgency = createAsyncThunk(
  "customer/fetchCustomersOfAgency",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    try {
      const response = await baseApi.get(`/Customer/CustomersOfAgency/${id}`);

      return response.data.result;
    } catch (error) {
      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state, action) => {
        state.customer = {};
        state.customerLoading = true;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
        state.customerLoading = false;
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.customer = {};
        state.customerLoading = false;
      });

    builder
      .addCase(fetchCustomers.pending, (state, action) => {
        state.customers = [];
        state.customersLoading = true;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customers = action.payload;
        state.customersLoading = false;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.customers = [];
        state.customersLoading = false;
      });

    builder
      .addCase(createCustomer.pending, (state, action) => {
        state.createCustomerLoading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.createCustomerLoading = false;
        state.customersRefresh = !state.customersRefresh;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.createCustomerLoading = false;
      });

    builder
      .addCase(updateCustomer.pending, (state, action) => {
        state.updateCustomerLoading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.updateCustomerLoading = false;
        state.customersRefresh = !state.customersRefresh;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.updateCustomerLoading = false;
      });

    builder
      .addCase(deleteCustomer.pending, (state, action) => {
        state.deleteCustomerLoading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.deleteCustomerLoading = false;
        state.customersRefresh = !state.customersRefresh;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.deleteCustomerLoading = false;
      });

    builder
      .addCase(fetchCustomersOfAgency.pending, (state, action) => {
        state.customersAgency = [];
        state.customersAgencyLoading = true;
      })
      .addCase(fetchCustomersOfAgency.fulfilled, (state, action) => {
        state.customersAgency = action.payload;
        state.customersAgencyLoading = false;
      })
      .addCase(fetchCustomersOfAgency.rejected, (state, action) => {
        state.customersAgency = [];
        state.customersAgencyLoading = false;
      });
  },
});

export default customerSlice.reducer;
