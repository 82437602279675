import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchCustomer } from "../../slices/apps/customerSlice";

// görsel importlar
import avtar10 from "../../assets/images/avatar.png";

const CustomerInfoModal = ({ id }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);

  const { customer } = useSelector((state) => {
    return {
      customer: state.customer.customer,
    };
  }, shallowEqual);

  // states

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchCustomer(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  return (
    <Fragment>
      <span
        id={`customer-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-search text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip placement="top" target={`customer-tooltipTop-${id}`}>
        Müşteri Bilgilerini Görüntüle
      </UncontrolledTooltip>

      <Modal
        size="md"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Müşteri Bilgisi - {customer?.CustomerName}
        </ModalHeader>

        <ModalBody className="text-center">
          <div className="avatar-md mb-3 mx-auto">
            <img
              src={avtar10}
              alt=""
              id="candidate-img"
              className="img-thumbnail rounded-circle shadow-none"
            />
          </div>

          <h5 id="candidate-name" className="mb-2 text-dark">
            {customer?.CustomerName} - {customer?.IsActive ? "Aktif" : "Pasif"}
          </h5>

          <div className="fw-medium fs-12 text-dark mb-2">
            <div>
              <i className="mdi mdi-phone align-middle me-1"></i>
              <b>
                +90{" "}
                {customer?.PhoneNumber?.toString().replace(
                  /(\d{3})(\d{3})(\d{2})(\d{2})/,
                  "$1 $2 $3 $4"
                )}
              </b>
            </div>
            {customer?.Email && (
              <div>
                <i className="mdi mdi-email align-middle me-1"></i>
                <b>{customer?.Email}</b>
              </div>
            )}
          </div>

          <div className="sticky-side-div mb-4 mt-2 text-start">
            <div className="table-responsive">
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>VKN/TCKN:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {customer?.TaxNumber ? customer?.TaxNumber : "-"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>Vergi Dairesi:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {customer?.TaxOffice ? customer?.TaxOffice : "-"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>İl/İlçe:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {customer?.City ? customer?.City : "-"}/
                        {customer?.District ? customer?.District : "-"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>Adres:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {customer?.Address ? customer?.Address : "-"}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CustomerInfoModal;
