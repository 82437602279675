import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAgencies } from "../../slices/apps/agencySlices";

// sayfalar, modallar vb.

const Announcements = () => {
  const dispatch = useDispatch();

  const { agenciesRefresh } = useSelector((state) => {
    return {
      agenciesRefresh: state.agency.agenciesRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchAgencies());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, agenciesRefresh]);

  document.title = "Duyuru Listesi | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Announcements;
