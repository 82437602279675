import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { resendAgreementSms } from "../../slices/apps/agreementSlice";

// data dosyaları
import Question from "../../assets/images/question.png";

const AgreementSmsModal = ({ id }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);

  // states
  const { resendAgreementSmsLoading } = useSelector((state) => {
    return {
      resendAgreementSmsLoading: state.agreement.resendAgreementSmsLoading,
    };
  }, shallowEqual);

  const sendSMS = async () => {
    try {
      const resultAction = await dispatch(resendAgreementSms(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(false);
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      setModal(true);
    } else setModal(false);
  }

  return (
    <Fragment>
      <span
        id={`agreement-sms-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-paper-plane text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`agreement-sms-tooltipTop-${id}`}
      >
        SMS Gönder
      </UncontrolledTooltip>

      <Modal
        size="md"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          SMS Gönder
        </ModalHeader>

        <ModalBody className="text-center text-dark">
          <img
            src={Question}
            alt="SMS Question"
            id="candidate-img"
            className="img-fluid mb-4"
            style={{ height: "80px" }}
          />
          <h4 className="text-dark">
            Sözleşme örneği SMS olarak iletilsin mi?
          </h4>
          <div className="d-flex align-items-center gap-3 mt-5 justify-content-center">
            <Button
              className="btn btn-sm"
              color="primary"
              disabled={resendAgreementSmsLoading}
              onClick={sendSMS}
            >
              Onayla
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AgreementSmsModal;
