import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  payment: {},
  paymentLoading: true,
  paymentRefresh: true,

  payments: [],
  paymentsLoading: true,
  paymentsRefresh: true,

  cardInfo: {},
  cardInfoLoading: true,
  cardInfoRefresh: true,

  createPaymentLoading: false,
  updatePaymentLoading: false,
  deletePaymentLoading: false,
};

export const fetchPayment = createAsyncThunk(
  "payment/fetchPayment",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    try {
      const response = await baseApi.get(`/Payment/${id}`);

      return response.data.result;
    } catch (error) {
      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const fetchPayments = createAsyncThunk(
  "payment/fetchPayments",
  async () => {
    try {
      const response = await baseApi.get(`/Payment`);

      return response.data.result;
    } catch (error) {
      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const createPayment = createAsyncThunk(
  "payment/createPayment",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: 0,
      paymentNo: values.paymentNo,
      customerId: values.customerId,
      aggreementId: values.agreementId,
      amount: values.amount,
      agencyId: values.agencyId,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Payment`, data);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const updatePayment = createAsyncThunk(
  "payment/updatePayment",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      paymentNo: values.paymentNo,
      customerId: values.customerId,
      aggreementId: values.agreementId,
      amount: values.amount,
      agencyId: values.agencyId,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Payment/${values.id}`, data);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const deletePayment = createAsyncThunk(
  "payment/deletePayment",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.delete(`/Payment/${id}`);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const findBin = createAsyncThunk("payment/findBin", async (bin) => {
  if (!bin) throw new Error("Bin bulunamadı!");

  try {
    const response = await baseApi.get(`/Payment/Bin?bin=${bin}`);

    return response.data.Value;
  } catch (error) {
    throw new Error(error.response.data.errorMessage);
  }
});

export const paymentSlice = createSlice({
  name: "payment",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayment.pending, (state, action) => {
        state.payment = {};
        state.paymentLoading = true;
      })
      .addCase(fetchPayment.fulfilled, (state, action) => {
        state.payment = action.payload;
        state.paymentLoading = false;
      })
      .addCase(fetchPayment.rejected, (state, action) => {
        state.payment = {};
        state.paymentLoading = false;
      });

    builder
      .addCase(fetchPayments.pending, (state, action) => {
        state.payments = [];
        state.paymentsLoading = true;
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.payments = action.payload;
        state.paymentsLoading = false;
      })
      .addCase(fetchPayments.rejected, (state, action) => {
        state.payments = [];
        state.paymentsLoading = false;
      });

    builder
      .addCase(createPayment.pending, (state, action) => {
        state.createPaymentLoading = true;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.createPaymentLoading = false;
        state.paymentsRefresh = !state.paymentsRefresh;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.createPaymentLoading = false;
      });

    builder
      .addCase(updatePayment.pending, (state, action) => {
        state.updatePaymentLoading = true;
        state.paymentsLoading = true;
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        state.updatePaymentLoading = false;
        state.paymentsRefresh = !state.paymentsRefresh;
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.updatePaymentLoading = false;
      });

    builder
      .addCase(deletePayment.pending, (state, action) => {
        state.deletePaymentLoading = true;
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.deletePaymentLoading = false;
        state.paymentsRefresh = !state.paymentsRefresh;
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.deletePaymentLoading = false;
      });

    builder
      .addCase(findBin.pending, (state, action) => {
        state.cardInfo = {};
        state.cardInfoLoading = true;
      })
      .addCase(findBin.fulfilled, (state, action) => {
        state.cardInfo = action.payload;
        state.cardInfoLoading = false;
      })
      .addCase(findBin.rejected, (state, action) => {
        state.cardInfo = {};
        state.cardInfoLoading = false;
      });
  },
});

export default paymentSlice.reducer;
