import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchEarningRequestDetail } from "../../slices/apps/agencySlices";

// diğer importlar
import moment from "moment/moment";

import EarningAgreementTable from "./EarningAgreementTable";

const EarningAgencyDetailModal = ({ id, agreementName }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);

  // states
  const { earningRequestDetail } = useSelector((state) => {
    return {
      earningRequestDetail: state.agency.earningRequestDetail,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchEarningRequestDetail(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  return (
    <Fragment>
      <span
        id={`approve-earning-detail-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-search text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`approve-earning-detail-tooltipTop-${id}`}
      >
        Ödeme Detayları
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          {agreementName} - Ödeme Detayları
        </ModalHeader>

        <ModalBody>
          <div className="sticky-side-div mb-4 mt-2 text-start">
            <div className="table-responsive">
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>Fatura Numarası:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {earningRequestDetail?.Invoice?.InvoiceNumber
                          ? earningRequestDetail?.Invoice?.InvoiceNumber
                          : "-"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>Fatura Tarihi:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {earningRequestDetail?.Invoice?.InvoiceDate
                          ? moment(
                              earningRequestDetail?.Invoice?.InvoiceDate
                            ).format("DD.MM.YYYY")
                          : "-"}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>Tutar:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {new Intl.NumberFormat("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(earningRequestDetail?.Invoice?.Amount)}{" "}
                        TL
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <EarningAgreementTable data={earningRequestDetail?.Agreements} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EarningAgencyDetailModal;
