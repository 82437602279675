import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  approveEarning,
  fetchEarningRequestDetail,
} from "../../slices/apps/agencySlices";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

// Gerekli eklentiyi kayıt edin
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

// react select
import Select from "react-select";

// diğer importlar
import moment from "moment/moment";
import EarningAgreementTable from "./EarningAgreementTable";

// regex, değişkenler
const statusTypes = [
  {
    value: 2,
    label: "Reddedildi",
  },

  {
    value: 1,
    label: "Onaylandı",
  },
];

// data dosyaları

const EarningApproveModal = ({ id, isOk, isError, agreementName }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [base64File, setBase64File] = useState("");

  // states
  const { earningRequestDetail } = useSelector((state) => {
    return {
      earningRequestDetail: state.agency.earningRequestDetail,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchEarningRequestDetail(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      setFiles([]);
      setBase64File("");
      validation.resetForm();

      fetchData();
    } else setModal(false);
  }

  // Sözleşme validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      invoiceId: id,
      requestStatusType: "",
      receiptPdfBase64: "",
      reasonDesc: "",
    },

    validationSchema: Yup.object({
      requestStatusType: Yup.number()
        .required("Lütfen durum seçin")
        .integer("Lütfen durum seçin"),
      reasonDesc: Yup.string(),
    }),

    onSubmit: async (values) => {
      const data = {
        ...values,
        receiptPdfBase64: base64File,
      };

      try {
        const resultAction = await dispatch(approveEarning(data));

        if (resultAction.meta.requestStatus === "fulfilled") {
          setModal(false);
        }
      } catch (error) {
        console.error("Failed:", error);
      }
    },
  });

  const handleFileProcess = (error, file) => {
    if (error) {
      console.error("File processing failed", error);
    } else {
      const base64File = file.getFileEncodeDataURL();
      const base64Value = base64File.replace(/^data:.*;base64,/, "");
      setBase64File(base64Value);
    }
  };

  const handleRemoveFile = (error, file) => {
    if (error) {
      console.error("File removal failed", error);
    } else {
      setFiles([]);
      setBase64File("");
    }
  };

  if (isError) {
    return (
      <Fragment>
        <span
          style={{
            display: "flex",
            padding: "3px",
          }}
        >
          <i
            className="bx bxs-x-circle text-danger"
            style={{
              fontSize: "20px",
            }}
          />
        </span>
      </Fragment>
    );
  }

  if (isOk) {
    return (
      <Fragment>
        <span
          id={`approve-earning-detail-tooltipTop-${id}`}
          style={{
            display: "flex",
            cursor: "pointer",
            padding: "3px",
          }}
          onClick={() => tog_large(true)}
        >
          <i
            className="bx bx-search text-dark"
            style={{
              fontSize: "20px",
            }}
          />
        </span>

        <UncontrolledTooltip
          placement="top"
          target={`approve-earning-detail-tooltipTop-${id}`}
        >
          Ödeme Detayları
        </UncontrolledTooltip>

        <Modal
          size="lg"
          isOpen={modal}
          toggle={() => {
            tog_large(false);
          }}
        >
          <ModalHeader
            className="modal-title "
            id="myLargeModalLabel"
            toggle={() => {
              tog_large(false);
            }}
          >
            {agreementName} - Ödeme Detayları
          </ModalHeader>

          <ModalBody>
            <div className="sticky-side-div mb-4 mt-2 text-start">
              <div className="table-responsive">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <th>
                        <div className="d-flex flex-column">
                          <span>Fatura Numarası:</span>
                        </div>
                      </th>
                      <td className="text-end">
                        <span className="fw-semibold" id="cart-total">
                          {earningRequestDetail?.Invoice?.InvoiceNumber
                            ? earningRequestDetail?.Invoice?.InvoiceNumber
                            : "-"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div className="d-flex flex-column">
                          <span>Fatura Tarihi:</span>
                        </div>
                      </th>
                      <td className="text-end">
                        <span className="fw-semibold" id="cart-total">
                          {earningRequestDetail?.Invoice?.InvoiceDate
                            ? moment(
                                earningRequestDetail?.Invoice?.InvoiceDate
                              ).format("DD.MM.YYYY")
                            : "-"}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <div className="d-flex flex-column">
                          <span>Tutar:</span>
                        </div>
                      </th>
                      <td className="text-end">
                        <span className="fw-semibold" id="cart-total">
                          {new Intl.NumberFormat("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(earningRequestDetail?.Invoice?.Amount)}{" "}
                          TL
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <EarningAgreementTable data={earningRequestDetail?.Agreements} />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <span
        id={`approve-earning-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-check-circle text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`approve-earning-tooltipTop-${id}`}
      >
        Ödeme Onayla
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          {agreementName} - Ödeme Onayla
        </ModalHeader>

        <ModalBody>
          <div className="sticky-side-div mb-4 mt-2 text-start">
            <div className="table-responsive">
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>Fatura Numarası:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {earningRequestDetail?.Invoice?.InvoiceNumber
                          ? earningRequestDetail?.Invoice?.InvoiceNumber
                          : "-"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>Fatura Tarihi:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {earningRequestDetail?.Invoice?.InvoiceDate
                          ? moment(
                              earningRequestDetail?.Invoice?.InvoiceDate
                            ).format("DD.MM.YYYY")
                          : "-"}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <div className="d-flex flex-column">
                        <span>Tutar:</span>
                      </div>
                    </th>
                    <td className="text-end">
                      <span className="fw-semibold" id="cart-total">
                        {new Intl.NumberFormat("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(earningRequestDetail?.Invoice?.Amount)}{" "}
                        TL
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <EarningAgreementTable data={earningRequestDetail?.Agreements} />

          <hr />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="g-2 mb-2">
              <Col lg={12}>
                <div className="mb-3">
                  <Label
                    htmlFor="requestStatusTypeSelect"
                    className="form-label"
                  >
                    Durum
                  </Label>
                  <Select
                    placeholder="Durum seçiniz"
                    id="requestStatusTypeSelect"
                    name="requestStatusType"
                    options={statusTypes}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    value={statusTypes.find(
                      (option) =>
                        option.value === validation.values.requestStatusType
                    )}
                    onChange={(value) => {
                      validation.setFieldValue(
                        "requestStatusType",
                        value.value
                      );
                    }}
                    onBlur={validation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          validation.touched.requestStatusType &&
                          validation.errors.requestStatusType &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {validation.touched.requestStatusType &&
                    validation.errors.requestStatusType && (
                      <div className="d-block invalid-feedback">
                        {validation.errors.requestStatusType}
                      </div>
                    )}
                </div>
              </Col>

              {validation.values.requestStatusType == 2 && (
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="reasonDesc" className="form-label">
                      Ret Sebebi
                    </Label>
                    <Input
                      id="reasonDesc"
                      name="reasonDesc"
                      className="form-control pe-5"
                      placeholder="Ret sebebi giriniz"
                      type="textarea"
                      autoComplete="off"
                      rows="2"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.reasonDesc}
                      invalid={
                        validation.touched.reasonDesc &&
                        validation.errors.reasonDesc
                          ? true
                          : false
                      }
                      style={{ resize: "none" }}
                    />
                    {validation.touched.reasonDesc &&
                      validation.errors.reasonDesc && (
                        <FormFeedback type="invalid">
                          {validation.errors.reasonDesc}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              )}

              <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                maxFiles={1}
                className="filepond filepond-input-single"
                labelIdle="Dekont (PDF) yükle"
                acceptedFileTypes={["application/pdf"]}
                onaddfile={handleFileProcess}
                onremovefile={handleRemoveFile}
              />
            </Row>

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
              >
                Kaydet
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EarningApproveModal;
