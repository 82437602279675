import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { uploadReceipt } from "../../slices/apps/agencySlices";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

// Gerekli eklentiyi kayıt edin
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

const EarningReceiptUploadModal = ({ id, isDisabled }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [base64File, setBase64File] = useState("");

  // states
  const { approveEarningLoading } = useSelector((state) => {
    return {
      approveEarningLoading: state.agency.approveEarningLoading,
    };
  }, shallowEqual);

  function tog_large(e) {
    if (e) {
      setFiles([]);
      setBase64File("");
      setModal(true);
    } else setModal(false);
  }

  const handleFileProcess = (error, file) => {
    if (error) {
      console.error("File processing failed", error);
    } else {
      const base64File = file.getFileEncodeDataURL();
      const base64Value = base64File.replace(/^data:.*;base64,/, "");
      setBase64File(base64Value);
    }
  };

  const handleRemoveFile = (error, file) => {
    if (error) {
      console.error("File removal failed", error);
    } else {
      setFiles([]);
      setBase64File("");
    }
  };

  const uploadData = async () => {
    try {
      const resultAction = await dispatch(
        uploadReceipt({ earningRequestId: id, pdfbase64: base64File })
      );

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(false);
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  if (isDisabled) {
    return (
      <Fragment>
        <span
          style={{
            display: "flex",
            padding: "3px",
          }}
        >
          <i
            className="bx bxs-file-export text-muted"
            style={{
              fontSize: "20px",
            }}
          />
        </span>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <span
        id={`receipt-upload-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bxs-file-export text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`receipt-upload-tooltipTop-${id}`}
      >
        Dekont Yükle
      </UncontrolledTooltip>

      <Modal
        size="md"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Dekont Yükle
        </ModalHeader>

        <ModalBody>
          <Row className="g-2 mb-2">
            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={false}
              maxFiles={1}
              className="filepond filepond-input-single"
              labelIdle="Dekont (PDF) yükle"
              acceptedFileTypes={["application/pdf"]}
              onaddfile={handleFileProcess}
              onremovefile={handleRemoveFile}
            />
          </Row>

          <div className="d-flex align-items-center gap-3 mt-4">
            <Button
              className="btn btn-sm ms-auto nexttab nexttab"
              color="primary"
              disabled={approveEarningLoading || !base64File}
              onClick={uploadData}
            >
              Kaydet
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EarningReceiptUploadModal;
