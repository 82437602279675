import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

// react table
import {
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAgencyEarningsAdmin } from "../../slices/apps/agencySlices";

// router
import { Link } from "react-router-dom";

// diğer importlar
import moment from "moment/moment";

// sayfalar, modallar vb.
import EarningApproveModal from "./EarningApproveModal";
import EarningReceiptModal from "./EarningReceiptModal";
import EarningInvoiceModal from "./EarningInvoiceModal";
import EarningReceiptUploadModal from "./EarningReceiptUploadModal";

const statusTypes = {
  Reddedildi: {
    label: "Reddedildi",
    icon: "bx bxs-x-circle",
    className: "text-danger",
  },
  OnayBekliyor: {
    label: "Onay Bekliyor",
    icon: "bx bxs-time",
    className: "text-secondary",
  },
  Onaylandi: {
    label: "Onaylandı",
    icon: "bx bxs-check-circle",
    className: "text-success",
  },
};

// Column Definitions
const columnHelper = createColumnHelper();

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      id="search-bar-0"
      className="form-control border-0 search"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const EarningAdminTable = () => {
  const dispatch = useDispatch();

  const { agencyEarningsAdmin, agencyEarningsAdminLoading } = useSelector(
    (state) => {
      return {
        agencyEarningsAdmin: state.agency.agencyEarningsAdmin,
        agencyEarningsAdminLoading: state.agency.agencyEarningsAdminLoading,
      };
    },
    shallowEqual
  );

  // States
  const [data, setData] = useState([]); // table için data dosyası
  const [globalFilter, setGlobalFilter] = useState(""); // arama çubuğu

  // filtreleme işlemi için
  useEffect(() => {
    setData([]);

    const filteredData = agencyEarningsAdmin.filter((data) => {
      if (
        globalFilter &&
        !Object.values(data).some(
          (value) =>
            typeof value === "string" &&
            (value
              .toLocaleLowerCase()
              .includes(globalFilter.toLocaleLowerCase()) ||
              value
                .toLocaleUpperCase()
                .includes(globalFilter.toLocaleUpperCase()))
        )
      )
        return false;
      return true;
    });

    setData(filteredData);
  }, [globalFilter, agencyEarningsAdmin]);

  // Hooks
  const columns = useMemo(
    () => [
      columnHelper.accessor("actions", {
        header: "",
        size: 80,
        minSize: 80,
        maxSize: 80,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
              <EarningReceiptUploadModal
                id={row.original.Id}
                isDisabled={!row.original.IsPaid}
              />
              <EarningApproveModal
                id={row.original.Id}
                isOk={row.original.RequestStatusType == "Onaylandi"}
                isError={row.original.RequestStatusType == "Reddedildi"}
                agreementName={row.original.CompanyName}
              />
              <EarningInvoiceModal id={row.original.Id} />
              <EarningReceiptModal id={row.original.Id} />
            </div>
          );
        },
      }),
      columnHelper.accessor("IsPaid", {
        header: "",
        size: 140,
        minSize: 140,
        maxSize: 140,
        cell: ({ row }) => {
          const statusInfo = statusTypes[row.original.RequestStatusType];

          return (
            <div>
              <div className="text-truncate fw-bold fs-12">
                <i
                  className={`${statusInfo.icon} align-middle me-1 fs-14 ${statusInfo.className}`}
                ></i>
                {statusInfo.label}
              </div>

              {row.original.IsPaid ? (
                <div className="text-truncate  fw-bold fs-12 ">
                  <i className="bx bxs-check-circle align-middle me-1 fs-14 text-info"></i>
                  Ödendi
                </div>
              ) : (
                <div className="text-truncate fw-bold fs-12 ">
                  <i className="bx bxs-info-circle align-middle me-1 fs-14 text-warning"></i>
                  Ödenmedi
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("CompanyName", {
        header: "Firma Adı",
        size: "auto",
        minSize: 250,
        maxSize: 350,
        enableResizing: false,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fs-12 fw-semibold">
              {row.original.CompanyName}
              <div className="fw-medium fs-12 text-danger text-truncate">
                {row.original.ReasonDesc
                  ? `Ret Sebebi: ${row.original.ReasonDesc}`
                  : ""}
              </div>
            </div>
          );
        },
      }),

      columnHelper.accessor("AgreementCount", {
        header: "Sözleşme Sayısı",
        size: 150,
        minSize: 150,
        maxSize: 150,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-semibold fs-12">
              {row.original.AgreementCount} Adet
            </div>
          );
        },
      }),

      columnHelper.accessor("InvoiceDate", {
        header: "Fatura Bilgisi",
        size: 150,
        minSize: 150,
        maxSize: 150,
        cell: ({ row }) => {
          return (
            <div>
              <div className="text-truncate fw-semibold fs-12">
                <i className="ri-calendar-2-fill align-middle text-dark me-1 fw-medium"></i>
                {row.original.InvoiceDate
                  ? moment(row.original.InvoiceDate).format("DD.MM.YYYY")
                  : "-"}
              </div>
              <div className="text-truncate fw-semibold fs-12">
                <i className="bx bxs-file align-middle text-dark me-1 fw-medium"></i>
                {row.original.InvoiceNumber ? row.original.InvoiceNumber : "-"}
              </div>
            </div>
          );
        },
      }),

      columnHelper.accessor("CreatedDate", {
        header: "Oluşturma Tarihi",
        size: 150,
        minSize: 150,
        maxSize: 150,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-semibold fs-12">
              <i className="ri-calendar-2-fill align-middle text-dark me-1 fw-medium"></i>
              {moment(row.original.CreatedDate).format("DD.MM.YYYY")}
            </div>
          );
        },
      }),
    ],
    []
  );

  // Hooks
  const table = useReactTable({
    data: data,
    columns,
    state: {},
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
  } = table;

  document.title = "Ödeme Talepleri Listesi | Global Yol Yardım";
  return (
    <Fragment>
      {/* <AgencyAddModal modal={addModal} setModal={setAddModal} /> */}
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Ödeme Talepleri Listesi
          </h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown">
              <DropdownToggle
                tag="a"
                className="text-reset"
                role="button"
                caret
              >
                İşlemler
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => dispatch(fetchAgencyEarningsAdmin())}
                >
                  <i className="ri-refresh-line label-icon align-middle me-2"></i>
                  Yenile
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>
        <CardBody>
          <Row className="mb-3">
            <CardBody className="border border-dashed border-end-0 border-start-0 border-top-0">
              <form>
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
                    <div style={{ width: "100px" }}>
                      <select
                        className="form-select mb-3"
                        aria-label="yufus"
                        value={getState().pagination.pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        <option defaultValue="10">10</option>
                        <option defaultValue="25">25</option>
                        <option defaultValue="50">50</option>
                        <option defaultValue="100">100</option>
                        <option defaultValue="200">200</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div
                      className="search-box me-2 mb-2 d-inline-block border"
                      style={{ borderRadius: "0.25em" }}
                    >
                      <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(value)}
                        placeholder={"Ara..."}
                      />
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
          </Row>

          <div
            style={{
              overflowX: "auto",
            }}
          >
            <Table>
              <thead>
                {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="text-muted"
                        style={{
                          width: header.column.columnDef.size,
                          minWidth: header.column.columnDef.minSize,
                          maxWidth: header.column.columnDef.maxSize,
                          letterSpacing: "0.8px",
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              userSelect: "none",
                            }}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <i className="ri-arrow-down-s-line"></i>,
                              desc: <i className="ri-arrow-up-s-line"></i>,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {agencyEarningsAdminLoading ? (
                  <tr>
                    <td colSpan="100%" className="text-center pt-5 pb-5">
                      <Spinner color="primary" />
                      <p className="text-dark">Yükleniyor...</p>
                    </td>
                  </tr>
                ) : data.length === 0 ? (
                  <tr>
                    <td
                      colSpan="100%"
                      className="text-center pt-5 pb-5 text-muted"
                    >
                      <h1>
                        <i className="ri-error-warning-line text-muted"></i>
                      </h1>
                      <p>Kayıt bulunamadı!</p>
                    </td>
                  </tr>
                ) : (
                  getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              style={{
                                width: cell.column.columnDef.size,
                                minWidth: cell.column.columnDef.minSize,
                                maxWidth: cell.column.columnDef.maxSize,
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>

          <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
            <div className="col-sm">
              <div className="text-muted">
                {data?.length > 0
                  ? `Toplam ${
                      table.getFilteredRowModel().rows.length
                    } kayıttan ${
                      table.getFilteredRowModel().rows.length === 0
                        ? 0
                        : table.getState().pagination.pageIndex *
                            table.getState().pagination.pageSize +
                          1
                    }
                  ile ${Math.min(
                    (table.getState().pagination.pageIndex + 1) *
                      table.getState().pagination.pageSize,
                    table.getFilteredRowModel().rows.length
                  )} arası gösteriliyor`
                  : "Kayıt Yok"}
              </div>
            </div>
            {data?.length > 0 && (
              <div className="col-sm-auto">
                <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                  <li
                    className={
                      !getCanPreviousPage() ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link to="#" className="page-link" onClick={previousPage}>
                      {"<"}
                    </Link>
                  </li>
                  {getPageOptions().map((item, key) => {
                    const pageIndex = getState().pagination.pageIndex;
                    const totalPages = getPageOptions().length;
                    const firstPage = item === 0;
                    const lastPage = item === totalPages - 1;
                    const nearCurrentPage = Math.abs(pageIndex - item) <= 1;

                    // Display logic
                    if (firstPage || lastPage || nearCurrentPage) {
                      return (
                        <React.Fragment key={key}>
                          <li className="page-item">
                            <Link
                              to="#"
                              className={
                                pageIndex === item
                                  ? "page-link active"
                                  : "page-link"
                              }
                              onClick={() => setPageIndex(item)}
                            >
                              {item + 1}
                            </Link>
                          </li>
                        </React.Fragment>
                      );
                    } else if (item === 1 && pageIndex > 2) {
                      return null;
                    } else if (
                      item === totalPages - 2 &&
                      pageIndex < totalPages - 3
                    ) {
                      return null;
                    } else {
                      return null;
                    }
                  })}
                  <li
                    className={
                      !getCanNextPage() ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link to="#" className="page-link" onClick={nextPage}>
                      {">"}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default EarningAdminTable;
