import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchCustomer, updateCustomer } from "../../slices/apps/customerSlice";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import ReactInputMask from "react-input-mask";

// regex, değişkenler
const phoneRegExp = /^(?:[1-9][0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}|[1-9][0-9]{9})$/;

// data dosyaları
import CityJson from "../../common/data/city.json";

const city = CityJson.map((data) => ({
  label: data.il_adi,
  value: data.il_adi,
  districts: data.ilceler
    .map((ilce) => ({
      label: ilce.ilce_adi,
      value: ilce.ilce_adi,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
})).sort((a, b) => a.label.localeCompare(b.label));

const CustomerUpdateModal = ({ id }) => {
  const dispatch = useDispatch();

  const { userProfile } = useProfile();

  const [modal, setModal] = useState(false); // Acente ekleme modalı

  const { customer, agencies, updateCustomerLoading } = useSelector((state) => {
    return {
      customer: state.customer.customer,
      updateCustomerLoading: state.customer.updateCustomerLoading,
      agencies: state.agency.agencies,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchCustomer(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);

        customerValidation.resetForm();
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  const customerValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: id,
      customerName: customer?.CustomerName,
      phoneNumber: customer?.PhoneNumber,
      email: customer?.Email,
      isActive: customer?.IsActive,
      taxNumber: customer?.TaxNumber,
      taxOffice: customer?.TaxOffice,
      city: customer?.City,
      district: customer?.District,
      address: customer?.Address,
      agencyId: customer?.AgencyId,
    },

    validationSchema: Yup.object({
      customerName: Yup.string().required("Lütfen müşteri bilgisi girin"),
      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
      email: Yup.string()
        .nullable()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .notRequired(),
      taxNumber: Yup.string()
        .nullable()
        .notRequired()
        .test("is-valid-taxnumber", "VKN/TCKN geçersiz", (value) => {
          if (!value || value.trim() === "") return true;
          return /^[0-9]+$/.test(value);
        })
        .min(10, "VKN/TCKN en az 10 karakter olmalıdır")
        .max(11, "VKN/TCKN en fazla 11 karakter olmalıdır"),
      taxOffice: Yup.string(),
      city: Yup.string().required("Lütfen il seçin"),
      district: Yup.string().required("Lütfen ilçe seçin"),
      address: Yup.string()
        .required("Lütfen adres bilgisi girin")
        .min(2, "Lütfen adres bilgisi girin"),
      agencyId: Yup.string().required("Lütfen acente seçin"),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(updateCustomer(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(false);
      }
    },
  });

  return (
    <Fragment>
      <span
        id={`customer-update-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-edit text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`customer-update-tooltipTop-${id}`}
      >
        Müşteri Bilgilerini Düzenle
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            setModal(false);
          }}
        >
          Müşteri Bilgilerini Düzenle
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              customerValidation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="align-items-start">
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="customerName" className="form-label">
                    Ad Soyad
                  </Label>
                  <Input
                    name="customerName"
                    className="form-control"
                    placeholder="Ad Soyad"
                    type="text"
                    autoComplete="off"
                    onChange={customerValidation.handleChange}
                    onBlur={customerValidation.handleBlur}
                    value={customerValidation.values.customerName || ""}
                    invalid={
                      customerValidation.touched.customerName &&
                      customerValidation.errors.customerName
                        ? true
                        : false
                    }
                  />
                  {customerValidation.touched.customerName &&
                    customerValidation.errors.customerName && (
                      <FormFeedback type="invalid">
                        {customerValidation.errors.customerName}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="agencyIdSelect" className="form-label">
                    Acente
                  </Label>
                  <Select
                    isDisabled={userProfile.data.Role != "SuperAdmin"}
                    placeholder="Acente seçiniz"
                    id="agencyIdSelect"
                    name="agencyId"
                    options={agencies.map((item) => ({
                      label: item.CompanyName,
                      value: item.Id,
                    }))}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    value={agencies
                      .map((item) => ({
                        label: item.CompanyName,
                        value: item.Id,
                      }))
                      .find(
                        (item) =>
                          item.value === customerValidation.values.agencyId
                      )}
                    onChange={(value) => {
                      customerValidation.setFieldValue("agencyId", value.value);
                    }}
                    onBlur={customerValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          customerValidation.touched.agencyId &&
                          customerValidation.errors.agencyId &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {customerValidation.touched.agencyId &&
                    customerValidation.errors.agencyId && (
                      <div className="d-block invalid-feedback">
                        {customerValidation.errors.agencyId}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="phoneNumber">
                    Telefon Numarası
                  </Label>
                  <InputGroup>
                    <span
                      className="input-group-text "
                      style={{
                        width: "44px",
                        justifyContent: "center",
                      }}
                      id="inputGroupPrepend2"
                    >
                      0
                    </span>
                    <ReactInputMask
                      mask="999 999 99 99"
                      maskChar=" "
                      name="phoneNumber"
                      placeholder="Telefon Numarası"
                      className={
                        customerValidation.touched.phoneNumber &&
                        customerValidation.errors.phoneNumber
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={customerValidation.handleChange}
                      value={customerValidation.values.phoneNumber || ""}
                      style={{ zIndex: 0 }}
                    />
                  </InputGroup>
                  {customerValidation.touched.phoneNumber &&
                    customerValidation.errors.phoneNumber && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {customerValidation.errors.phoneNumber}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="email" className="form-label">
                    E-posta Adresi
                  </Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="E-posta Adresi"
                    type="text"
                    autoComplete="off"
                    onChange={customerValidation.handleChange}
                    onBlur={customerValidation.handleBlur}
                    value={customerValidation.values.email || ""}
                    invalid={
                      customerValidation.touched.email &&
                      customerValidation.errors.email
                        ? true
                        : false
                    }
                  />
                  {customerValidation.touched.email &&
                    customerValidation.errors.email && (
                      <FormFeedback type="invalid">
                        {customerValidation.errors.email}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="taxNumber">
                    VKN/TCKN
                  </Label>
                  <InputGroup>
                    <input
                      name="taxNumber"
                      placeholder="VKN/TCKN"
                      inputMode="numeric"
                      type="text"
                      autoComplete="off"
                      maxLength={11}
                      className={
                        customerValidation.touched.taxNumber &&
                        customerValidation.errors.taxNumber
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, "");

                        customerValidation.setFieldValue("taxNumber", value);
                      }}
                      value={customerValidation.values.taxNumber || ""}
                    />
                  </InputGroup>
                  {customerValidation.touched.taxNumber &&
                    customerValidation.errors.taxNumber && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {customerValidation.errors.taxNumber}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="taxOffice" className="form-label">
                    Vergi Dairesi
                  </Label>
                  <Input
                    name="taxOffice"
                    className="form-control"
                    placeholder="Vergi Dairesi"
                    type="text"
                    autoComplete="off"
                    onChange={customerValidation.handleChange}
                    onBlur={customerValidation.handleBlur}
                    value={customerValidation.values.taxOffice || ""}
                    invalid={
                      customerValidation.touched.taxOffice &&
                      customerValidation.errors.taxOffice
                        ? true
                        : false
                    }
                  />
                  {customerValidation.touched.taxOffice &&
                    customerValidation.errors.taxOffice && (
                      <FormFeedback type="invalid">
                        {customerValidation.errors.taxOffice}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="citySelect" className="form-label">
                    İl
                  </Label>
                  <Select
                    placeholder="İl seçiniz"
                    id="citySelect"
                    name="city"
                    options={city}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    isClearable={true}
                    value={
                      city.find(
                        (item) => item.value === customerValidation.values.city
                      ) || ""
                    }
                    onChange={(value) => {
                      customerValidation.setFieldValue(
                        "city",
                        value ? value.value : ""
                      );
                      customerValidation.setFieldValue("district", "");
                    }}
                    onBlur={customerValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          customerValidation.touched.city &&
                          customerValidation.errors.city &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {customerValidation.touched.city &&
                    customerValidation.errors.city && (
                      <div className="d-block invalid-feedback">
                        {customerValidation.errors.city}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="districtSelect" className="form-label">
                    İlçe
                  </Label>
                  <Select
                    isDisabled={!customerValidation.values.city}
                    placeholder="İlçe seçiniz"
                    id="districtSelect"
                    name="district"
                    options={
                      city.find(
                        (item) => item.value === customerValidation.values.city
                      )?.districts || []
                    }
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    isClearable={true}
                    value={
                      city
                        .find(
                          (item) =>
                            item.value === customerValidation.values.city
                        )
                        ?.districts.find(
                          (district) =>
                            district.value ===
                            customerValidation.values.district
                        ) || ""
                    }
                    onChange={(value) => {
                      customerValidation.setFieldValue(
                        "district",
                        value ? value.value : ""
                      );
                    }}
                    onBlur={customerValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          customerValidation.touched.district &&
                          customerValidation.errors.district &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {customerValidation.touched.district &&
                    customerValidation.errors.district && (
                      <div className="d-block invalid-feedback">
                        {customerValidation.errors.district}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="address" className="form-label">
                    Adres
                  </Label>
                  <Input
                    name="address"
                    className="form-control"
                    placeholder="Mahalle, cadde, sokak..."
                    type="textarea"
                    autoComplete="off"
                    rows="2"
                    onChange={customerValidation.handleChange}
                    onBlur={customerValidation.handleBlur}
                    value={customerValidation.values.address || ""}
                    invalid={
                      customerValidation.touched.address &&
                      customerValidation.errors.address
                        ? true
                        : false
                    }
                    style={{ resize: "none" }}
                  />
                  {customerValidation.touched.address &&
                    customerValidation.errors.address && (
                      <FormFeedback type="invalid">
                        {customerValidation.errors.address}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              {userProfile.data.Role == "SuperAdmin" && (
                <Col sm={12}>
                  <div className="mb-3 mt-3">
                    <ul className="list-unstyled mb-0">
                      <li className="d-flex">
                        <div className="flex-grow-1">
                          <label
                            htmlFor="isActive"
                            className="form-check-label fs-13"
                          >
                            Aktif
                          </label>
                        </div>
                        <div className="flex-shrink-0">
                          <div className="form-check form-switch">
                            <Input
                              name="isActive"
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="isActive"
                              checked={
                                customerValidation.values.isActive || false
                              }
                              onChange={(e) => {
                                customerValidation.handleChange(e);
                              }}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              )}
            </Row>

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
                disabled={updateCustomerLoading}
              >
                Kaydet
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CustomerUpdateModal;
