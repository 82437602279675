import React from "react";
import { Card, CardBody, Col } from "reactstrap";

// redux
import { shallowEqual, useSelector } from "react-redux";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

const Widgets2 = () => {
  const { userProfile } = useProfile();

  const { agencyCountReport } = useSelector((state) => {
    return {
      agencyCountReport: state.dashboard.agencyCountReport,
    };
  }, shallowEqual);

  const values = [
    {
      id: 1,
      cardColor: "primary",
      badgeClass: "primary",
      label: "Toplam",
      counter: agencyCountReport?.TotalAgency || "0",
      bgcolor: "primary",
    },
    {
      id: 2,
      cardColor: "success",
      badgeClass: "success",
      label: "Aktif",
      counter: agencyCountReport?.TotalAgencyActive || "0",
      bgcolor: "success",
    },
    {
      id: 3,
      cardColor: "warning",
      badgeClass: "warning",
      label: "Pasif",
      counter: agencyCountReport?.TotalAgencyPassive || "0",
      bgcolor: "warning",
    },
  ];

  return (
    <React.Fragment>
      {values.map((item, key) => (
        <Col xl={4} md={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {item.label}{" "}
                    {userProfile.data.Role == "SuperAdmin"
                      ? "Acente"
                      : "Müşteri"}
                  </p>
                </div>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">
                      {item.counter}{" "}
                      {userProfile.data.Role == "SuperAdmin"
                        ? "Acente"
                        : "Müşteri"}
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={
                      "avatar-title rounded fs-3 bg-" + item.bgcolor + "-subtle"
                    }
                  >
                    <i
                      className={`text-${item.bgcolor} ${
                        userProfile.data.Role == "SuperAdmin"
                          ? "bx bx-buildings"
                          : "bx bx-user"
                      }`}
                    ></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets2;
