import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createUser } from "../../slices/apps/userSlice";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// router
import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import ReactInputMask from "react-input-mask";

// regex, değişkenler
const phoneRegExp =
  /^(?:[1-9][0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}|[1-9][0-9]{9})$/;

// data dosyaları
const rolesType = {
  SuperAdmin: "Yönetici",
  Agency: "Acente",
  SubAgency: "Alt Acente",
  Customer: "Müşteri",
  Salesman: "Pazarlamacı",
  Support: "Destek",
};

const UserAddModal = ({ modal, setModal }) => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  const [passwordShow, setPasswordShow] = useState(false);

  const { roles, agencies, subAgencies, createUserLoading } = useSelector(
    (state) => {
      return {
        roles: state.user.roles,
        agencies: state.agency.agencies,
        subAgencies: state.agency.subAgencies,
        createUserLoading: state.user.createUserLoading,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    setModal(modal);

    if (modal) {
      userValidation.resetForm();
    }
  }, [modal]);

  const userValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      nameSurname: "",
      password: "",
      agencyId: "",
      salesmanCode: "",
      userRole: "",
      phoneNumber: "",
      smsAuthorized: false,
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required("Lütfen e-posta adresi girin")
        .email("Lütfen geçerli bir e-posta adresi girin"),
      nameSurname: Yup.string().required("Lütfen ad soyad girin"),
      password: Yup.string()
        .required("Lütfen şifre girin")
        .min(6, "Lütfen şifre girin"),
      agencyId: Yup.string()
        .nonNullable("Lütfen acente seçin")
        .test("is-agency-required", "Lütfen acente seçin", function (value) {
          const { userRole } = this.parent;

          if (
            userRole === "SuperAdmin" ||
            userRole === "Salesman" ||
            userRole === "Support"
          ) {
            return true;
          }
          return !!value;
        }),
      salesmanCode: Yup.string().test(
        "is-agency-required",
        "Lütfen pazarlamacı referans kodu girin",
        function (value) {
          const { userRole } = this.parent;
          if (userRole != "Salesman") {
            return true;
          }
          return !!value;
        }
      ),
      userRole: Yup.string().required("Lütfen rol seçin"),
      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(createUser(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(false);
      }
    },
  });

  return (
    <Modal
      size="lg"
      isOpen={modal}
      toggle={() => {
        setModal(false);
      }}
    >
      <ModalHeader
        className="modal-title "
        id="myLargeModalLabel"
        toggle={() => {
          setModal(false);
        }}
      >
        Yeni Kullanıcı Ekle
      </ModalHeader>

      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            userValidation.handleSubmit();
            return false;
          }}
          action="#"
          autoComplete="off"
        >
          <Row className="align-items-start">
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="nameSurname" className="form-label">
                  Ad Soyad
                </Label>
                <Input
                  name="nameSurname"
                  className="form-control"
                  placeholder="Ad Soyad"
                  type="text"
                  autoComplete="off"
                  onChange={userValidation.handleChange}
                  onBlur={userValidation.handleBlur}
                  value={userValidation.values.nameSurname || ""}
                  invalid={
                    userValidation.touched.nameSurname &&
                    userValidation.errors.nameSurname
                      ? true
                      : false
                  }
                />
                {userValidation.touched.nameSurname &&
                  userValidation.errors.nameSurname && (
                    <FormFeedback type="invalid">
                      {userValidation.errors.nameSurname}
                    </FormFeedback>
                  )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="password-input">
                  Şifre
                </Label>
                <InputGroup>
                  <span
                    className="input-group-text"
                    style={{ width: "44px" }}
                    id="inputGroupPrepend2"
                  >
                    <i
                      onClick={() => setPasswordShow(!passwordShow)}
                      style={{
                        cursor: "pointer",
                        color: "gray",
                      }}
                      className={
                        passwordShow
                          ? "ri-eye-off-fill align-middle"
                          : "ri-eye-fill align-middle"
                      }
                    ></i>
                  </span>
                  <Input
                    name="password"
                    value={userValidation.values.password || ""}
                    type={passwordShow ? "text" : "password"}
                    className="form-control pe-5"
                    placeholder="Şifreniz"
                    onChange={userValidation.handleChange}
                    onBlur={userValidation.handleBlur}
                    invalid={
                      userValidation.touched.password &&
                      userValidation.errors.password
                        ? true
                        : false
                    }
                  />
                </InputGroup>
                {userValidation.touched.password &&
                  userValidation.errors.password && (
                    <div type="invalid" className="d-block invalid-feedback">
                      {userValidation.errors.password}
                    </div>
                  )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="phoneNumber">
                  Telefon Numarası
                </Label>
                <InputGroup>
                  <span
                    className="input-group-text "
                    style={{
                      width: "44px",
                      justifyContent: "center",
                    }}
                    id="inputGroupPrepend2"
                  >
                    0
                  </span>
                  <ReactInputMask
                    mask="999 999 99 99"
                    maskChar=" "
                    name="phoneNumber"
                    placeholder="Telefon Numarası"
                    onChange={userValidation.handleChange}
                    value={userValidation.values.phoneNumber || ""}
                    className={
                      userValidation.touched.phoneNumber &&
                      userValidation.errors.phoneNumber
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    style={{ zIndex: 0 }}
                  />
                </InputGroup>
                {userValidation.touched.phoneNumber &&
                  userValidation.errors.phoneNumber && (
                    <div type="invalid" className="d-block invalid-feedback">
                      {userValidation.errors.phoneNumber}
                    </div>
                  )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="email" className="form-label">
                  E-posta Adresi
                </Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="E-posta Adresi"
                  type="text"
                  autoComplete="off"
                  onChange={userValidation.handleChange}
                  onBlur={userValidation.handleBlur}
                  value={userValidation.values.email || ""}
                  invalid={
                    userValidation.touched.email && userValidation.errors.email
                      ? true
                      : false
                  }
                />
                {userValidation.touched.email &&
                  userValidation.errors.email && (
                    <FormFeedback type="invalid">
                      {userValidation.errors.email}
                    </FormFeedback>
                  )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="userRoleSelect" className="form-label">
                  Rol
                </Label>
                <Select
                  placeholder="Rol seçiniz"
                  id="userRoleSelect"
                  name="userRole"
                  options={roles
                    .filter((role) => role != "Customer")
                    .map((role) => ({
                      label: rolesType[role] || role,
                      value: role,
                    }))}
                  noOptionsMessage={() => "Seçenek bulunamadı"}
                  value={
                    roles
                      .filter((role) => role != "Customer")
                      .map((role) => ({
                        label: rolesType[role] || role,
                        value: role,
                      }))
                      .find(
                        (item) => item.value === userValidation.values.userRole
                      ) || ""
                  }
                  onChange={(value) => {
                    userValidation.setFieldValue("userRole", value.value);
                    userValidation.setFieldValue("agencyId", null);
                    userValidation.setFieldValue("salesmanCode", "");
                    userValidation.setFieldValue("smsAuthorized", false);
                  }}
                  onBlur={userValidation.handleBlur}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        userValidation.touched.userRole &&
                        userValidation.errors.userRole &&
                        !state.isFocused
                          ? "#e30a17"
                          : "#e9ebec",
                    }),
                  }}
                />
                {userValidation.touched.userRole &&
                  userValidation.errors.userRole && (
                    <div className="d-block invalid-feedback">
                      {userValidation.errors.userRole}
                    </div>
                  )}
              </div>
            </Col>

            {userValidation.values.userRole == "Salesman" && (
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="salesmanCode" className="form-label">
                    Pazarlamacı Referans Kodu
                  </Label>
                  <Input
                    name="salesmanCode"
                    className="form-control"
                    placeholder="Pazarlamacı Referans Kodu"
                    type="text"
                    autoComplete="off"
                    onChange={userValidation.handleChange}
                    onBlur={userValidation.handleBlur}
                    value={userValidation.values.salesmanCode || ""}
                    invalid={
                      userValidation.touched.salesmanCode &&
                      userValidation.errors.salesmanCode
                        ? true
                        : false
                    }
                  />
                  {userValidation.touched.salesmanCode &&
                    userValidation.errors.salesmanCode && (
                      <FormFeedback type="invalid">
                        {userValidation.errors.salesmanCode}
                      </FormFeedback>
                    )}
                </div>
              </Col>
            )}

            {userValidation.values.userRole != "SuperAdmin" &&
              userValidation.values.userRole != "Salesman" &&
              userValidation.values.userRole != "Support" &&
              userValidation.values.userRole == "Agency" && (
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="agencyIdSelect" className="form-label">
                      Acente
                    </Label>
                    <Select
                      placeholder="Acente seçiniz"
                      id="agencyIdSelect"
                      name="agencyId"
                      options={agencies.map((item) => ({
                        label: item.CompanyName,
                        value: item.Id,
                      }))}
                      noOptionsMessage={() => "Seçenek bulunamadı"}
                      value={
                        agencies
                          .map((item) => ({
                            label: item.CompanyName,
                            value: item.Id,
                          }))
                          .find(
                            (item) =>
                              item.value === userValidation.values.agencyId
                          ) || ""
                      }
                      onChange={(value) => {
                        userValidation.setFieldValue("agencyId", value.value);
                      }}
                      onBlur={userValidation.handleBlur}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor:
                            userValidation.touched.agencyId &&
                            userValidation.errors.agencyId &&
                            !state.isFocused
                              ? "#e30a17"
                              : "#e9ebec",
                        }),
                      }}
                    />
                    {userValidation.touched.agencyId &&
                      userValidation.errors.agencyId && (
                        <div className="d-block invalid-feedback">
                          {userValidation.errors.agencyId}
                        </div>
                      )}
                  </div>
                </Col>
              )}

            {userValidation.values.userRole != "SuperAdmin" &&
              userValidation.values.userRole != "Salesman" &&
              userValidation.values.userRole != "Support" &&
              userValidation.values.userRole == "SubAgency" && (
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="agencyIdSelect" className="form-label">
                      Acente
                    </Label>
                    <Select
                      placeholder="Acente seçiniz"
                      id="agencyIdSelect"
                      name="agencyId"
                      options={subAgencies.map((item) => ({
                        label: item.CompanyName,
                        value: item.Id,
                      }))}
                      noOptionsMessage={() => "Seçenek bulunamadı"}
                      value={
                        subAgencies
                          .map((item) => ({
                            label: item.CompanyName,
                            value: item.Id,
                          }))
                          .find(
                            (item) =>
                              item.value === userValidation.values.agencyId
                          ) || ""
                      }
                      onChange={(value) => {
                        userValidation.setFieldValue("agencyId", value.value);
                      }}
                      onBlur={userValidation.handleBlur}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor:
                            userValidation.touched.agencyId &&
                            userValidation.errors.agencyId &&
                            !state.isFocused
                              ? "#e30a17"
                              : "#e9ebec",
                        }),
                      }}
                    />
                    {userValidation.touched.agencyId &&
                      userValidation.errors.agencyId && (
                        <div className="d-block invalid-feedback">
                          {userValidation.errors.agencyId}
                        </div>
                      )}
                  </div>
                </Col>
              )}

            {(userValidation.values.userRole == "SuperAdmin" ||
              userValidation.values.userRole == "Support") && (
              <Col sm={12}>
                <div className="mb-3 mt-3">
                  <ul className="list-unstyled mb-0">
                    <li className="d-flex">
                      <div className="flex-grow-1">
                        <label
                          htmlFor="smsAuthorized"
                          className="form-check-label fs-13"
                        >
                          SMS Yetkilisi
                        </label>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch">
                          <Input
                            name="smsAuthorized"
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="smsAuthorized"
                            checked={
                              userValidation.values.smsAuthorized || false
                            }
                            onChange={(e) => {
                              userValidation.handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            )}
          </Row>

          <div className="d-flex align-items-center gap-3 mt-4">
            <Button
              className="btn btn-sm ms-auto nexttab nexttab"
              color="primary"
              type="submit"
              disabled={createUserLoading}
            >
              Kaydet
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UserAddModal;
