import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { resendAgreementInvoice } from "../../slices/apps/agreementSlice";

// data dosyaları
import Invoice from "../../assets/images/invoice.png";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

const AgreementInvoiceModal = ({ id, isDisabled }) => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  // states
  const [modal, setModal] = useState(false);

  // states
  const { resendAgreementInvoiceLoading } = useSelector((state) => {
    return {
      resendAgreementInvoiceLoading:
        state.agreement.resendAgreementInvoiceLoading,
    };
  }, shallowEqual);

  const sendInvoice = async () => {
    try {
      const resultAction = await dispatch(resendAgreementInvoice(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(false);
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      setModal(true);
    } else setModal(false);
  }

  if (userProfile.data.Role != "SuperAdmin" ||isDisabled) {
    return (
      <span
        style={{
          display: "flex",
          padding: "3px",
        }}
      >
        <i
          className="bx bxs-file-export text-muted"
          style={{
            fontSize: "20px",
          }}
        />
      </span>
    );
  }

  return (
    <Fragment>
      <span
        id={`agreement-invoice-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bxs-file-export text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`agreement-invoice-tooltipTop-${id}`}
      >
        Faturalandır
      </UncontrolledTooltip>

      <Modal
        size="md"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Faturalandır
        </ModalHeader>

        <ModalBody className="text-center text-dark">
          <img
            src={Invoice}
            alt="Invoice"
            id="candidate-img"
            className="img-fluid mb-4"
            style={{ height: "80px" }}
          />
          <h4 className="text-dark">
            Bu işlem sonucunda fatura oluşturulacak ve işlem geri
            alınamayacaktır. İşlemi onaylıyor musunuz?
          </h4>
          <div className="d-flex align-items-center gap-3 mt-5 justify-content-center">
            <Button
              className="btn btn-sm"
              color="primary"
              disabled={resendAgreementInvoiceLoading}
              onClick={sendInvoice}
            >
              Onayla
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AgreementInvoiceModal;
