import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { approveAgency, fetchAgency } from "../../slices/apps/agencySlices";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// data dosyaları
const ratioInfo = [
  {
    label: "%20",
    value: 20,
  },
  {
    label: "%25",
    value: 25,
  },
  {
    label: "%30",
    value: 30,
  },
  {
    label: "%35",
    value: 35,
  },
  {
    label: "%40",
    value: 40,
  },
  {
    label: "%45",
    value: 45,
  },
  {
    label: "%50",
    value: 50,
  },
  {
    label: "%55",
    value: 55,
  },
  {
    label: "%60",
    value: 60,
  },
  {
    label: "%65",
    value: 65,
  },
  {
    label: "%70",
    value: 70,
  },
];

const AgencyApproveModal = ({ id, isApproved }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);

  const { agency, approveAgencyLoading } = useSelector((state) => {
    return {
      agency: state.agency.agency,
      approveAgencyLoading: state.agency.approveAgencyLoading,
    };
  }, shallowEqual);

  // states

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchAgency(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);

        agencyValidation.resetForm();
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  // Acente validation
  const agencyValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      agencyId: id,
      commissionRate: agency?.CommissionRate,
    },

    validationSchema: Yup.object({
      commissionRate: Yup.number()
        .required("Lütfen hak ediş oranı seçin")
        .integer("Lütfen hak ediş oranı seçin"),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(approveAgency(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        tog_large(false);
      }
    },
  });

  if (isApproved) {
    return (
      <>
        <span
          style={{
            display: "flex",
            padding: "3px",
          }}
        >
          <i
            className="bx bxs-check-square"
            style={{
              fontSize: "20px",
              color: "transparent",
            }}
          />
        </span>
      </>
    );
  }

  return (
    <Fragment>
      <span
        id={`agency-approve-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bxs-check-square text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`agency-approve-tooltipTop-${id}`}
      >
        Acente Onayla
      </UncontrolledTooltip>

      <Modal
        size="md"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Acente Onayla
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              agencyValidation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="align-items-start">
              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="commissionRateSelect" className="form-label">
                    Acente Hak Ediş Oranı
                  </Label>
                  <Select
                    placeholder="Acente Hak Ediş Oranı seçiniz"
                    id="commissionRateSelect"
                    name="commissionRate"
                    options={ratioInfo}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    value={
                      ratioInfo.find(
                        (option) =>
                          option.value ===
                          agencyValidation.values.commissionRate
                      ) || ""
                    }
                    onChange={(value) => {
                      agencyValidation.setFieldValue(
                        "commissionRate",
                        value.value
                      );
                    }}
                    onBlur={agencyValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          agencyValidation.touched.commissionRate &&
                          agencyValidation.errors.commissionRate &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {agencyValidation.touched.commissionRate &&
                    agencyValidation.errors.commissionRate && (
                      <div className="d-block invalid-feedback">
                        {agencyValidation.errors.commissionRate}
                      </div>
                    )}
                </div>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
                disabled={approveAgencyLoading}
              >
                Onayla
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AgencyApproveModal;
