import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createPackage, fetchPackages } from "../../slices/apps/packageSlice";

// router
import { useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// diğer importlar
import { NumericFormat } from "react-number-format";

// sayfalar, modallar vb.

// quill
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import "./quill-styles.css";

const PackageAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quillRef: descriptionQuillRef, quill: descriptionQuill } = useQuill({
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["color"],
      ],
    },
  });
  const { quillRef: agreementQuillRef, quill: agreementQuill } = useQuill({
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["color"],
      ],
    },
  });

  const { packagesRefresh } = useSelector((state) => {
    return {
      packagesRefresh: state.package.packagesRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchPackages());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    packageValidation.resetForm();
  }, [dispatch, packagesRefresh]);

  const packageValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      packageName: "",
      price: 0,

      totalLimit: 0,
      totalLimitDesc: "",
      towingLimit: 0,
      towingLimitDesc: "",
      windshield: 0,
      windshieldDesc: "",
      accident: 0,
      accidentDesc: "",
      fault: 0,
      faultDesc: "",
      tire: 0,
      tireDesc: "",
      fuel: 0,
      fuelDesc: "",
      landslide: 0,
      landslideDesc: "",
      headlight: 0,
      headlightDesc: "",
      health: 0,
      healthDesc: "",
      tired: 0,
      tiredDesc: "",
      evFault: 0,
      evFaultDesc: "",
      wildAnimal: 0,
      wildAnimalDesc: "",

      description: "",
      agreement: "",
      picturePath: "",
      isActive: true,
      isBulkSale: false,
    },

    validationSchema: Yup.object({
      packageName: Yup.string().required("Lütfen paket adını girin"),
      price: Yup.string().required("Lütfen fiyat girin"),

      totalLimit: Yup.string().required("Lütfen fiyat girin"),
      totalLimitDesc: Yup.string().required("Lütfen açıklama girin"),

      windshield: Yup.number()
        .required("Lütfen ön cam limiti girin")
        .integer("Ön cam limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      windshieldDesc: Yup.string().required("Lütfen açıklama girin"),

      accident: Yup.number()
        .required("Lütfen kaza limitini girin")
        .integer("Kaza limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      accidentDesc: Yup.string().required("Lütfen açıklama girin"),

      fault: Yup.number()
        .required("Lütfen arıza limitini girin")
        .integer("Arıza limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      faultDesc: Yup.string().required("Lütfen açıklama girin"),

      tire: Yup.number()
        .required("Lütfen lastik limitini girin")
        .integer("Lastik limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      tireDesc: Yup.string().required("Lütfen açıklama girin"),

      fuel: Yup.number()
        .required("Lütfen yakıt limiti girin")
        .integer("Yakıt limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      fuelDesc: Yup.string().required("Lütfen açıklama girin"),

      landslide: Yup.number()
        .required("Lütfen heyelan limiti girin")
        .integer("Heyelan limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      landslideDesc: Yup.string().required("Lütfen açıklama girin"),

      headlight: Yup.number()
        .required("Lütfen far arıza limitini girin")
        .integer("Far arıza limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      headlightDesc: Yup.string().required("Lütfen açıklama girin"),

      health: Yup.number()
        .required("Lütfen sağlık sorunu limitini girin")
        .integer("Sağlık sorunu limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      healthDesc: Yup.string().required("Lütfen açıklama girin"),

      tired: Yup.number()
        .required("Lütfen yorgunluk kaynaklı tek sürücü araç limitini girin")
        .integer("Yorgunluk kaynaklı tek sürücü araç limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      tiredDesc: Yup.string().required("Lütfen açıklama girin"),

      evFault: Yup.number()
        .required("Lütfen elektrikli araç limitini girin")
        .integer("Elektrikli araç limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      evFaultDesc: Yup.string().required("Lütfen açıklama girin"),

      wildAnimal: Yup.number()
        .required("Lütfen yabani hayvan limitini girin")
        .integer("Yabani hayvan limiti tam sayı olmalıdır")
        .min(0, "Toplam limit 0 veya daha büyük olmalıdır"),
      wildAnimalDesc: Yup.string().required("Lütfen açıklama girin"),

      description: Yup.string().required("Lütfen açıklama girin"),
      agreement: Yup.string().required("Lütfen sözleşme girin"),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(createPackage(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        navigate("/package-admin");
      }
    },
  });

  useEffect(() => {
    if (descriptionQuill) {
      descriptionQuill.on("text-change", () => {
        packageValidation.setFieldValue(
          "description",
          descriptionQuill.root.innerHTML
        );
      });
    }
  }, [descriptionQuill]);

  useEffect(() => {
    if (agreementQuill) {
      agreementQuill.on("text-change", () => {
        packageValidation.setFieldValue(
          "agreement",
          agreementQuill.root.innerHTML
        );
      });
    }
  }, [agreementQuill]);

  document.title = "Paket Ekle | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <Card>
                <CardHeader>
                  <h5>Paket Ekle</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      packageValidation.handleSubmit();
                      return false;
                    }}
                    action="#"
                    autoComplete="off"
                  >
                    <Row className="align-items-start">
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="price">
                            Fiyat
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="price"
                              id="price"
                              className={
                                packageValidation.touched.price &&
                                packageValidation.errors.price
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.price}
                              decimalSeparator=","
                              thousandSeparator="."
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "price",
                                  floatValue || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.price &&
                            packageValidation.errors.price && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.price}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="packageName" className="form-label">
                            Paket Adı
                          </Label>
                          <Input
                            name="packageName"
                            className="form-control"
                            placeholder="Paket Adı"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.packageName || ""}
                            invalid={
                              packageValidation.touched.packageName &&
                              packageValidation.errors.packageName
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.packageName &&
                            packageValidation.errors.packageName && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.packageName}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <hr className="my-4 text-muted" />
                      <Label className="form-label fs-14">Durumlar</Label>
                      <Col sm={3} md={2}>
                        <div className="mb-3 mt-3">
                          <ul className="list-unstyled mb-0">
                            <li className="d-flex">
                              <div className="flex-grow-1">
                                <label
                                  htmlFor="isBulkSale"
                                  className="form-check-label fs-13"
                                >
                                  Toplu Paket
                                </label>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="form-check form-switch">
                                  <Input
                                    name="isBulkSale"
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="isBulkSale"
                                    checked={
                                      packageValidation.values.isBulkSale ||
                                      false
                                    }
                                    onChange={(e) => {
                                      packageValidation.handleChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Col>

                      <hr className="my-4 text-muted" />
                      <Label className="form-label fs-14">
                        Sözleşmeyi Kapsayan 1 Yıllık Tutar
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="totalLimit">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="totalLimit"
                              id="totalLimit"
                              className={
                                packageValidation.touched.totalLimit &&
                                packageValidation.errors.totalLimit
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.totalLimit}
                              decimalSeparator=","
                              thousandSeparator="."
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "totalLimit",
                                  floatValue || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.totalLimit &&
                            packageValidation.errors.totalLimit && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.totalLimit}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label
                            htmlFor="totalLimitDesc"
                            className="form-label"
                          >
                            Açıklama
                          </Label>
                          <Input
                            name="totalLimitDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={
                              packageValidation.values.totalLimitDesc || ""
                            }
                            invalid={
                              packageValidation.touched.totalLimitDesc &&
                              packageValidation.errors.totalLimitDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.totalLimitDesc &&
                            packageValidation.errors.totalLimitDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.totalLimitDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14">Kaza Durumunda</Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="accident">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="accident"
                              id="accident"
                              className={
                                packageValidation.touched.accident &&
                                packageValidation.errors.accident
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.accident}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "accident",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.accident &&
                            packageValidation.errors.accident && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.accident}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="accidentDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="accidentDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.accidentDesc || ""}
                            invalid={
                              packageValidation.touched.accidentDesc &&
                              packageValidation.errors.accidentDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.accidentDesc &&
                            packageValidation.errors.accidentDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.accidentDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Arıza Durumunda
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="fault">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="fault"
                              id="fault"
                              className={
                                packageValidation.touched.fault &&
                                packageValidation.errors.fault
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.fault}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "fault",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.fault &&
                            packageValidation.errors.fault && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.fault}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="faultDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="faultDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.faultDesc || ""}
                            invalid={
                              packageValidation.touched.faultDesc &&
                              packageValidation.errors.faultDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.faultDesc &&
                            packageValidation.errors.faultDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.faultDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Lastik Patlamasında
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="tire">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="tire"
                              id="tire"
                              className={
                                packageValidation.touched.tire &&
                                packageValidation.errors.tire
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.tire}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "tire",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.tire &&
                            packageValidation.errors.tire && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.tire}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="tireDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="tireDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.tireDesc || ""}
                            invalid={
                              packageValidation.touched.tireDesc &&
                              packageValidation.errors.tireDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.tireDesc &&
                            packageValidation.errors.tireDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.tireDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Yakıt Bitmesinde
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="fuel">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="fuel"
                              id="fuel"
                              className={
                                packageValidation.touched.fuel &&
                                packageValidation.errors.fuel
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.fuel}
                              decimalSeparator=","
                              thousandSeparator="."
                              decimalScale={0}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "fuel",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.fuel &&
                            packageValidation.errors.fuel && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.fuel}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="fuelDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="fuelDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.fuelDesc || ""}
                            invalid={
                              packageValidation.touched.fuelDesc &&
                              packageValidation.errors.fuelDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.fuelDesc &&
                            packageValidation.errors.fuelDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.fuelDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Ön Cam Patlaması/Kırılması Durumunda
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="windshield">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="windshield"
                              id="windshield"
                              className={
                                packageValidation.touched.windshield &&
                                packageValidation.errors.windshield
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.windshield}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "windshield",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.windshield &&
                            packageValidation.errors.windshield && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.windshield}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label
                            htmlFor="windshieldDesc"
                            className="form-label"
                          >
                            Açıklama
                          </Label>
                          <Input
                            name="windshieldDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={
                              packageValidation.values.windshieldDesc || ""
                            }
                            invalid={
                              packageValidation.touched.windshieldDesc &&
                              packageValidation.errors.windshieldDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.windshieldDesc &&
                            packageValidation.errors.windshieldDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.windshieldDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Heyelan Durumunda
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="landslide">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="landslide"
                              id="landslide"
                              className={
                                packageValidation.touched.landslide &&
                                packageValidation.errors.landslide
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.landslide}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "landslide",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.landslide &&
                            packageValidation.errors.landslide && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.landslide}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="landslideDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="landslideDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.landslideDesc || ""}
                            invalid={
                              packageValidation.touched.landslideDesc &&
                              packageValidation.errors.landslideDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.landslideDesc &&
                            packageValidation.errors.landslideDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.landslideDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Far Arıza Durumunda
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="headlight">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="headlight"
                              id="headlight"
                              className={
                                packageValidation.touched.headlight &&
                                packageValidation.errors.headlight
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.headlight}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "headlight",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.headlight &&
                            packageValidation.errors.headlight && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.headlight}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="headlightDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="headlightDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.headlightDesc || ""}
                            invalid={
                              packageValidation.touched.headlightDesc &&
                              packageValidation.errors.headlightDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.headlightDesc &&
                            packageValidation.errors.headlightDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.headlightDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Sağlık Sorunları Durumunda
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="health">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="health"
                              id="health"
                              className={
                                packageValidation.touched.health &&
                                packageValidation.errors.health
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.health}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "health",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.health &&
                            packageValidation.errors.health && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.health}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="healthDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="healthDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.healthDesc || ""}
                            invalid={
                              packageValidation.touched.healthDesc &&
                              packageValidation.errors.healthDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.healthDesc &&
                            packageValidation.errors.healthDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.healthDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Yorgunluktan Kaynaklı Tek Sürücü Araçlar İçin
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="tired">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="tired"
                              id="tired"
                              className={
                                packageValidation.touched.tired &&
                                packageValidation.errors.tired
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.tired}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "tired",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.tired &&
                            packageValidation.errors.tired && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.tired}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="tiredDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="tiredDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.tiredDesc || ""}
                            invalid={
                              packageValidation.touched.tiredDesc &&
                              packageValidation.errors.tiredDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.tiredDesc &&
                            packageValidation.errors.tiredDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.tiredDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Elektrikli Araçların Yolda Kalması Durumunda
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="evFault">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="evFault"
                              id="evFault"
                              className={
                                packageValidation.touched.evFault &&
                                packageValidation.errors.evFault
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.evFault}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "evFault",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.evFault &&
                            packageValidation.errors.evFault && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.evFault}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="evFaultDesc" className="form-label">
                            Açıklama
                          </Label>
                          <Input
                            name="evFaultDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.evFaultDesc || ""}
                            invalid={
                              packageValidation.touched.evFaultDesc &&
                              packageValidation.errors.evFaultDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.evFaultDesc &&
                            packageValidation.errors.evFaultDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.evFaultDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Label className="form-label fs-14 mt-2">
                        Yabani Hayvanların Aracı Haraket Edemez Hale Getirmesi
                        Durumunda
                      </Label>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="wildAnimal">
                            Limit
                          </Label>
                          <InputGroup>
                            <NumericFormat
                              name="wildAnimal"
                              id="wildAnimal"
                              className={
                                packageValidation.touched.wildAnimal &&
                                packageValidation.errors.wildAnimal
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              value={packageValidation.values.wildAnimal}
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegative={false}
                              decimalScale={0}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                packageValidation.setFieldValue(
                                  "wildAnimal",
                                  Math.floor(floatValue) || 0
                                );
                              }}
                              inputMode="numeric"
                            />
                          </InputGroup>
                          {packageValidation.touched.wildAnimal &&
                            packageValidation.errors.wildAnimal && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.wildAnimal}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label
                            htmlFor="wildAnimalDesc"
                            className="form-label"
                          >
                            Açıklama
                          </Label>
                          <Input
                            name="wildAnimalDesc"
                            className="form-control"
                            placeholder="Açıklama"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={
                              packageValidation.values.wildAnimalDesc || ""
                            }
                            invalid={
                              packageValidation.touched.wildAnimalDesc &&
                              packageValidation.errors.wildAnimalDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.wildAnimalDesc &&
                            packageValidation.errors.wildAnimalDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.wildAnimalDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <hr className="my-4 text-muted" />

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Açıklama:
                            {packageValidation.touched.description &&
                              packageValidation.errors.description && (
                                <div
                                  type="invalid"
                                  className="d-block invalid-feedback"
                                >
                                  {packageValidation.errors.description}
                                </div>
                              )}
                          </Label>
                          <div className="snow-editor" style={{ height: 400 }}>
                            <div
                              ref={descriptionQuillRef}
                              style={{ height: "85%" }}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3 mt-3">
                          <Label className="form-label">
                            Sözleşme:
                            {packageValidation.touched.agreement &&
                              packageValidation.errors.agreement && (
                                <div
                                  type="invalid"
                                  className="d-block invalid-feedback"
                                >
                                  {packageValidation.errors.agreement}
                                </div>
                              )}
                          </Label>
                          <div className="snow-editor" style={{ height: 400 }}>
                            <div
                              ref={agreementQuillRef}
                              style={{ height: "85%" }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="d-flex align-items-center gap-3 mt-4">
                      <Button
                        className="btn btn-sm ms-auto nexttab nexttab"
                        color="primary"
                        type="submit"
                      >
                        Kaydet
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PackageAdd;
