import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

// router
import { Link } from "react-router-dom";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchPackages } from "../../slices/apps/packageSlice";

// sayfalar, modallar vb.
import PackageDetailModal from "./PackageDetailModal";

// görsel importlar
import Logo from "../../assets/images/logo/logo512.png";
import Package0 from "../../assets/images/store/paket.png";
import Package1 from "../../assets/images/store/paket1.png";
import Package2 from "../../assets/images/store/paket2.png";
import Package3 from "../../assets/images/store/paket3.png";
import Package4 from "../../assets/images/store/paket4.jpg";
import Package5 from "../../assets/images/store/paket5.jpg";
import Package6 from "../../assets/images/store/paket6.jpg";
import Package7 from "../../assets/images/store/paket7.jpg";
import Package8 from "../../assets/images/store/paket8.jpg";
import Package9 from "../../assets/images/store/paket9.jpg";
import Package10 from "../../assets/images/store/paket10.jpg";
import Taksi from "../../assets/images/store/paket_taksi.png";
import Servis from "../../assets/images/store/paket_servis.png";

const Package = () => {
  const dispatch = useDispatch();
  const cardBodyRef = useRef(null);
  const [fontSize, setFontSize] = useState("12px");

  const images = [
    Package1,
    Package2,
    Package3,
    Package4,
    Package5,
    Package6,
    Package7,
    Package8,
    Package9,
    Package10,
  ];

  const otherImages = [Taksi, Servis];

  const { packages, packagesLoading } = useSelector((state) => {
    return {
      packages: state.package.packages,
      packagesLoading: state.package.packagesLoading,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchPackages());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (cardBodyRef.current) {
        const cardWidth = cardBodyRef.current.clientWidth;
        const newFontSize =
          cardWidth < 340
            ? "10px"
            : cardWidth >= 340 && cardWidth < 400
            ? "11px"
            : "12px";

        setFontSize(newFontSize);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  document.title = "Paketler | Global Yol Yardım";
  if (packagesLoading) {
    return (
      <React.Fragment>
        <Spinner
          className="position-absolute top-50 start-50"
          animation="border"
          color="primary"
        />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row className="justify-content-center">
                  <Col lg={5}>
                    <div className="text-center pb-2 mb-4">
                      <h3 className="fw-semibold fs-22">Paketler</h3>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col xl={10}>
                    <Row>
                      {packages?.map((item, key) => (
                        <Col sm={12} md={6} lg={6} xxl={4} key={key}>
                          <div ref={cardBodyRef}>
                            <Card>
                              <CardBody style={{ padding: 0 }}>
                                {/* Resim Alanı */}
                                <div
                                  style={{
                                    height: "300px",
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  onMouseOver={(e) => {
                                    const imageContainer =
                                      e.currentTarget.querySelector(
                                        ".image-container"
                                      );
                                    imageContainer.style.filter =
                                      "blur(20px) brightness(100%)";
                                    const overlay =
                                      e.currentTarget.querySelector(".overlay");
                                    overlay.style.opacity = "1";
                                  }}
                                  onMouseOut={(e) => {
                                    const imageContainer =
                                      e.currentTarget.querySelector(
                                        ".image-container"
                                      );
                                    imageContainer.style.filter = "none";
                                    const overlay =
                                      e.currentTarget.querySelector(".overlay");
                                    overlay.style.opacity = "0";
                                  }}
                                >
                                  {/* Resim */}
                                  <img
                                    src={
                                      item.packageName.includes("Taksi")
                                        ? otherImages[0]
                                        : item.packageName.includes("Servis")
                                        ? otherImages[1]
                                        : images[key] || Package0
                                    }
                                    alt={item.packageName}
                                    className="image-container"
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      objectFit: "contain",
                                      transition: "0.3s ease-in-out",
                                    }}
                                  />
                                  {/* Overlay */}
                                  <div
                                    className="overlay"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Koyu overlay
                                      color: "#fff",
                                      display: "flex",
                                      // justifyContent: "center",
                                      alignItems: "center",
                                      opacity: 0,
                                      transition: "opacity 0.3s ease-in-out",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    <span
                                      className="flex-start"
                                      style={{
                                        fontSize: fontSize,
                                        fontWeight: "bold",
                                        padding: "10px",
                                      }}
                                    >
                                      <ul
                                        style={{
                                          paddingLeft: "20px",
                                          marginBottom: 0,
                                        }}
                                      >
                                        {item.accident > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.accidentDesc}
                                          </li>
                                        )}
                                        {item.fault > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.faultDesc}
                                          </li>
                                        )}
                                        {item.tire > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.tireDesc}
                                          </li>
                                        )}
                                        {item.fuel > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.fuelDesc}
                                          </li>
                                        )}
                                        {item.windshield > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.windshieldDesc}
                                          </li>
                                        )}
                                        {item.landslide > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.landslideDesc}
                                          </li>
                                        )}
                                        {item.headlight > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.headlightDesc}
                                          </li>
                                        )}
                                        {item.health > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.healthDesc}
                                          </li>
                                        )}
                                        {item.tired > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.tiredDesc}
                                          </li>
                                        )}
                                        {item.evFault > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.evFaultDesc}
                                          </li>
                                        )}
                                        {item.wildAnimal > 0 && (
                                          <li
                                            style={{
                                              fontSize: fontSize,
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            {item.wildAnimalDesc}
                                          </li>
                                        )}
                                      </ul>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="text-center"
                                  style={{ padding: "10px" }}
                                >
                                  <PackageDetailModal item={item} />
                                </div>
                                <div className="text-center">
                                  <h2
                                    style={{ fontWeight: "bold" }}
                                    className="text-primary"
                                  >
                                    {new Intl.NumberFormat("tr-TR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(item.price)}
                                    <sup>
                                      <small>₺</small>
                                    </sup>
                                  </h2>
                                </div>
                              </CardBody>

                              {/* Satın Al Butonu */}
                              <div>
                                <Link
                                  to="/payment"
                                  state={{ id: item.id }}
                                  className="btn btn-primary w-100 waves-effect waves-light"
                                  style={{
                                    borderRadius: "0",
                                    padding: "14px",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  Satın Al
                                </Link>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Package;
