import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchAgencies,
  fetchAgency,
  fetchAgencyCommission,
  updateAgency,
} from "../../slices/apps/agencySlices";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import ReactInputMask from "react-input-mask";

// regex, değişkenler
const phoneRegExp =
  /^(?:[1-9][0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}|[1-9][0-9]{9})$/;
const ibanRegex = /^TR\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{2}$/;

// data dosyaları
import CityJson from "../../common/data/city.json";

const city = CityJson.map((data) => ({
  label: data.il_adi,
  value: data.il_adi,
  districts: data.ilceler
    .map((ilce) => ({
      label: ilce.ilce_adi,
      value: ilce.ilce_adi,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
})).sort((a, b) => a.label.localeCompare(b.label));

const agencyInfo = [
  {
    label: "Acente",
    value: 0,
  },
  {
    label: "Yol Yardım / Kurtarma",
    value: 1,
  },
];

const ratioInfo = [
  {
    label: "%5",
    value: 5,
  },
  {
    label: "%10",
    value: 10,
  },
  {
    label: "%15",
    value: 15,
  },
  {
    label: "%20",
    value: 20,
  },
  {
    label: "%25",
    value: 25,
  },
  {
    label: "%30",
    value: 30,
  },
  {
    label: "%35",
    value: 35,
  },
  {
    label: "%40",
    value: 40,
  },
  {
    label: "%45",
    value: 45,
  },
  {
    label: "%50",
    value: 50,
  },
  {
    label: "%55",
    value: 55,
  },
  {
    label: "%60",
    value: 60,
  },
  {
    label: "%65",
    value: 65,
  },
  {
    label: "%70",
    value: 70,
  },
];

const SubAgencyUpdateModal = ({ id }) => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  // states
  const [modal, setModal] = useState(false);

  const {
    agencies,
    agencyCommission,
    agencyCommissionLoading,
    agency,
    updateAgencyLoading,
  } = useSelector((state) => {
    return {
      agencies: state.agency.agencies,
      agencyCommission: state.agency.agencyCommission,
      agencyCommissionLoading: state.agency.agencyCommissionLoading,
      agency: state.agency.agency,
      updateAgencyLoading: state.agency.updateAgencyLoading,
    };
  }, shallowEqual);

  const fetchDataAgency = async () => {
    try {
      const resultAction = await dispatch(
        fetchAgencyCommission(userProfile?.data?.AgencyID)
      );

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataAgencyForAdmin = async (id) => {
    try {
      await dispatch(fetchAgencyCommission(id));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataAdmin = async () => {
    try {
      const resultAction = await dispatch(fetchAgencies());

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchAgency(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        agencyValidation.resetForm();

        if (userProfile?.data?.Role == "Agency") {
          fetchDataAgency();
        } else {
          fetchDataAdmin();
        }
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  // Acente validation
  const agencyValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: id,
      mainAgencyId: agency?.MainAgencyId,
      companyName: agency?.CompanyName,
      companyCode: agency?.CompanyCode,
      taxNumber: agency?.TaxNumber,
      taxOffice: agency?.TaxOffice,
      agencyType: agency?.AgencyType,
      isActive: agency?.IsActive,
      isApproved: agency?.IsApproved,
      commissionRate: agency?.CommissionRate,
      referenceCode: agency?.ReferenceCode,

      phoneNumber: agency?.PhoneNumber,
      email: agency?.Email,
      address: agency?.Address,
      city: agency?.City,
      district: agency?.District,
      postCode: agency?.PostCode,

      bankIban: agency?.BankIban,
      bankName: agency?.BankName,
    },

    validationSchema: Yup.object({
      mainAgencyId: Yup.string().required("Lütfen acente seçin"),
      companyName: Yup.string().required("Lütfen firma adı girin"),
      referenceCode: Yup.string().nullable(),
      taxNumber: Yup.string()
        .matches(/^[0-9]+$/, "VKN/TCKN sadece sayılardan oluşmalıdır")
        .min(10, "VKN/TCKN en az 10 karakter olmalıdır")
        .max(11, "VKN/TCKN en fazla 11 karakter olmalıdır")
        .required("Lütfen VKN/TCKN girin"),
      taxOffice: Yup.string().required("Lütfen vergi dairesi girin"),
      agencyType: Yup.number()
        .required("Lütfen acente tipi seçin")
        .integer("Lütfen acente tipi seçin"),
      commissionRate: Yup.number()
        .required("Lütfen hak ediş oranı seçin")
        .integer("Lütfen hak ediş oranı seçin"),

      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
      email: Yup.string()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .required("Lütfen e-posta adresi girin"),
      address: Yup.string()
        .required("Lütfen adres bilgisi girin")
        .min(2, "Lütfen adres bilgisi girin"),
      city: Yup.string().required("Lütfen il seçin"),
      district: Yup.string().required("Lütfen ilçe seçin"),
      postCode: Yup.string(),

      bankIban: Yup.string()
        .required("Lütfen IBAN girin")
        .matches(ibanRegex, "Geçerli bir IBAN girin"),
      bankName: Yup.string().required("Lütfen banka adı girin"),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(updateAgency(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        tog_large(false);
      }
    },
  });

  useEffect(() => {
    if (agencyValidation.values.mainAgencyId) {
      fetchDataAgencyForAdmin(agencyValidation.values.mainAgencyId);
    }
  }, [agencyValidation.values.mainAgencyId]);

  return (
    <Fragment>
      <span
        id={`company-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bxs-edit text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip placement="top" target={`company-tooltipTop-${id}`}>
        Acente Bilgilerini Düzenle
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Acente Bilgilerini Düzenle
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              agencyValidation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="align-items-start">
              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="taxNumber">
                    VKN/TCKN
                  </Label>
                  <InputGroup>
                    <input
                      name="taxNumber"
                      placeholder="VKN/TCKN"
                      inputMode="numeric"
                      type="text"
                      autoComplete="off"
                      maxLength={11}
                      className={
                        agencyValidation.touched.taxNumber &&
                        agencyValidation.errors.taxNumber
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, "");

                        agencyValidation.setFieldValue("taxNumber", value);
                      }}
                      value={agencyValidation.values.taxNumber || ""}
                    />
                  </InputGroup>
                  {agencyValidation.touched.taxNumber &&
                    agencyValidation.errors.taxNumber && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {agencyValidation.errors.taxNumber}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="taxOffice" className="form-label">
                    Vergi Dairesi
                  </Label>
                  <Input
                    name="taxOffice"
                    className="form-control"
                    placeholder="Vergi Dairesi"
                    type="text"
                    autoComplete="off"
                    onChange={agencyValidation.handleChange}
                    onBlur={agencyValidation.handleBlur}
                    value={agencyValidation.values.taxOffice || ""}
                    invalid={
                      agencyValidation.touched.taxOffice &&
                      agencyValidation.errors.taxOffice
                        ? true
                        : false
                    }
                  />
                  {agencyValidation.touched.taxOffice &&
                    agencyValidation.errors.taxOffice && (
                      <FormFeedback type="invalid">
                        {agencyValidation.errors.taxOffice}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="companyName" className="form-label">
                    Firma Adı
                  </Label>
                  <Input
                    name="companyName"
                    className="form-control"
                    placeholder="Firma Adı"
                    type="text"
                    autoComplete="off"
                    onChange={agencyValidation.handleChange}
                    onBlur={agencyValidation.handleBlur}
                    value={agencyValidation.values.companyName || ""}
                    invalid={
                      agencyValidation.touched.companyName &&
                      agencyValidation.errors.companyName
                        ? true
                        : false
                    }
                  />
                  {agencyValidation.touched.companyName &&
                    agencyValidation.errors.companyName && (
                      <FormFeedback type="invalid">
                        {agencyValidation.errors.companyName}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              {userProfile?.data?.Role == "SuperAdmin" && (
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="mainAgencyIdSelect" className="form-label">
                      Acente
                    </Label>
                    <Select
                      placeholder="Acente seçiniz"
                      id="mainAgencyIdSelect"
                      name="mainAgencyId"
                      options={agencies.map((item) => ({
                        label: item.CompanyName,
                        value: item.Id,
                      }))}
                      noOptionsMessage={() => "Seçenek bulunamadı"}
                      value={
                        agencies
                          .map((item) => ({
                            label: item.CompanyName,
                            value: item.Id,
                          }))
                          .find(
                            (item) =>
                              item.value ===
                              agencyValidation.values.mainAgencyId
                          ) || ""
                      }
                      onChange={(value) => {
                        agencyValidation.setFieldValue(
                          "mainAgencyId",
                          value.value
                        );
                      }}
                      onBlur={agencyValidation.handleBlur}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor:
                            agencyValidation.touched.mainAgencyId &&
                            agencyValidation.errors.mainAgencyId &&
                            !state.isFocused
                              ? "#e30a17"
                              : "#e9ebec",
                        }),
                      }}
                    />
                    {agencyValidation.touched.mainAgencyId &&
                      agencyValidation.errors.mainAgencyId && (
                        <div className="d-block invalid-feedback">
                          {agencyValidation.errors.mainAgencyId}
                        </div>
                      )}
                  </div>
                </Col>
              )}

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="commissionRateSelect" className="form-label">
                    Acente Hak Ediş Oranı
                  </Label>
                  <Select
                    placeholder="Acente Hak Ediş Oranı seçiniz"
                    id="commissionRateSelect"
                    name="commissionRate"
                    options={ratioInfo}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    value={
                      ratioInfo.find(
                        (option) =>
                          option.value ===
                          agencyValidation.values.commissionRate
                      ) || ""
                    }
                    onChange={(value) => {
                      agencyValidation.setFieldValue(
                        "commissionRate",
                        value.value
                      );
                    }}
                    onBlur={agencyValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          agencyValidation.touched.commissionRate &&
                          agencyValidation.errors.commissionRate &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {agencyValidation.touched.commissionRate &&
                    agencyValidation.errors.commissionRate && (
                      <div className="d-block invalid-feedback">
                        {agencyValidation.errors.commissionRate}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="agencyTypeSelect" className="form-label">
                    Acente Tipi
                  </Label>
                  <Select
                    placeholder="Acente tipi seçiniz"
                    id="agencyTypeSelect"
                    name="agencyType"
                    options={agencyInfo}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    value={
                      agencyInfo.find(
                        (option) =>
                          option.value === agencyValidation.values.agencyType
                      ) || ""
                    }
                    onChange={(value) => {
                      agencyValidation.setFieldValue("agencyType", value.value);
                    }}
                    onBlur={agencyValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          agencyValidation.touched.agencyType &&
                          agencyValidation.errors.agencyType &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {agencyValidation.touched.agencyType &&
                    agencyValidation.errors.agencyType && (
                      <div className="d-block invalid-feedback">
                        {agencyValidation.errors.agencyType}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="email" className="form-label">
                    E-posta Adresi
                  </Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="E-posta Adresi"
                    type="text"
                    autoComplete="off"
                    onChange={agencyValidation.handleChange}
                    onBlur={agencyValidation.handleBlur}
                    value={agencyValidation.values.email || ""}
                    invalid={
                      agencyValidation.touched.email &&
                      agencyValidation.errors.email
                        ? true
                        : false
                    }
                  />
                  {agencyValidation.touched.email &&
                    agencyValidation.errors.email && (
                      <FormFeedback type="invalid">
                        {agencyValidation.errors.email}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="phoneNumber">
                    Telefon Numarası
                  </Label>
                  <InputGroup>
                    <span
                      className="input-group-text "
                      style={{
                        width: "44px",
                        justifyContent: "center",
                      }}
                      id="inputGroupPrepend2"
                    >
                      0
                    </span>
                    <ReactInputMask
                      mask="999 999 99 99"
                      maskChar=" "
                      name="phoneNumber"
                      placeholder="Telefon Numarası"
                      className={
                        agencyValidation.touched.phoneNumber &&
                        agencyValidation.errors.phoneNumber
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={agencyValidation.handleChange}
                      value={agencyValidation.values.phoneNumber || ""}
                      style={{ zIndex: 0 }}
                    />
                  </InputGroup>
                  {agencyValidation.touched.phoneNumber &&
                    agencyValidation.errors.phoneNumber && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {agencyValidation.errors.phoneNumber}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={4}>
                <div className="mb-3">
                  <Label htmlFor="citySelect" className="form-label">
                    İl
                  </Label>
                  <Select
                    placeholder="İl seçiniz"
                    id="citySelect"
                    name="city"
                    options={city}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    isClearable={true}
                    value={
                      city.find(
                        (item) => item.value === agencyValidation.values.city
                      ) || ""
                    }
                    onChange={(value) => {
                      agencyValidation.setFieldValue(
                        "city",
                        value ? value.value : ""
                      );
                      agencyValidation.setFieldValue("district", "");
                    }}
                    onBlur={agencyValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          agencyValidation.touched.city &&
                          agencyValidation.errors.city &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {agencyValidation.touched.city &&
                    agencyValidation.errors.city && (
                      <div className="d-block invalid-feedback">
                        {agencyValidation.errors.city}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={4}>
                <div className="mb-3">
                  <Label htmlFor="districtSelect" className="form-label">
                    İlçe
                  </Label>
                  <Select
                    isDisabled={!agencyValidation.values.city}
                    placeholder="İlçe seçiniz"
                    id="districtSelect"
                    name="district"
                    options={
                      city.find(
                        (item) => item.value === agencyValidation.values.city
                      )?.districts || []
                    }
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    isClearable={true}
                    value={
                      city
                        .find(
                          (item) => item.value === agencyValidation.values.city
                        )
                        ?.districts.find(
                          (district) =>
                            district.value === agencyValidation.values.district
                        ) || ""
                    }
                    onChange={(value) => {
                      agencyValidation.setFieldValue(
                        "district",
                        value ? value.value : ""
                      );
                    }}
                    onBlur={agencyValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          agencyValidation.touched.district &&
                          agencyValidation.errors.district &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {agencyValidation.touched.district &&
                    agencyValidation.errors.district && (
                      <div className="d-block invalid-feedback">
                        {agencyValidation.errors.district}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={4}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="postCode">
                    Posta Kodu
                  </Label>
                  <InputGroup>
                    <ReactInputMask
                      mask="99999"
                      maskChar=" "
                      name="postCode"
                      alwaysShowMask
                      inputMode="numeric"
                      type="tel"
                      className={
                        agencyValidation.touched.postCode &&
                        agencyValidation.errors.postCode
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={agencyValidation.handleChange}
                      value={agencyValidation.values.postCode || ""}
                      style={{ zIndex: 0 }}
                    />
                  </InputGroup>
                  {agencyValidation.touched.postCode &&
                    agencyValidation.errors.postCode && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {agencyValidation.errors.postCode}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="address" className="form-label">
                    Adres
                  </Label>
                  <Input
                    name="address"
                    className="form-control"
                    placeholder="Mahalle, cadde, sokak..."
                    type="textarea"
                    autoComplete="off"
                    rows="2"
                    onChange={agencyValidation.handleChange}
                    onBlur={agencyValidation.handleBlur}
                    value={agencyValidation.values.address || ""}
                    invalid={
                      agencyValidation.touched.address &&
                      agencyValidation.errors.address
                        ? true
                        : false
                    }
                    style={{ resize: "none" }}
                  />
                  {agencyValidation.touched.address &&
                    agencyValidation.errors.address && (
                      <FormFeedback type="invalid">
                        {agencyValidation.errors.address}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="bankName" className="form-label">
                    Banka Adı
                  </Label>
                  <Input
                    name="bankName"
                    className="form-control"
                    placeholder="Banka Adı"
                    type="text"
                    autoComplete="off"
                    onChange={agencyValidation.handleChange}
                    onBlur={agencyValidation.handleBlur}
                    value={agencyValidation.values.bankName || ""}
                    invalid={
                      agencyValidation.touched.bankName &&
                      agencyValidation.errors.bankName
                        ? true
                        : false
                    }
                  />
                  {agencyValidation.touched.bankName &&
                    agencyValidation.errors.bankName && (
                      <FormFeedback type="invalid">
                        {agencyValidation.errors.bankName}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="bankIban" className="form-label">
                    IBAN
                  </Label>
                  <ReactInputMask
                    mask="TR99 9999 9999 9999 9999 9999 99"
                    maskChar=" "
                    value={agencyValidation.values.bankIban || ""}
                    onChange={agencyValidation.handleChange}
                    onBlur={agencyValidation.handleBlur}
                    style={{ zIndex: 0 }}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        name="bankIban"
                        className="form-control"
                        placeholder="IBAN"
                        type="text"
                        autoComplete="off"
                        invalid={
                          agencyValidation.touched.bankIban &&
                          agencyValidation.errors.bankIban
                            ? true
                            : false
                        }
                      />
                    )}
                  </ReactInputMask>
                  {agencyValidation.touched.bankIban &&
                    agencyValidation.errors.bankIban && (
                      <FormFeedback type="invalid">
                        {agencyValidation.errors.bankIban}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="commissionRateSelect" className="form-label">
                    Acente Aktif/Pasif
                  </Label>
                  <ul className="list-unstyled mb-0 mt-2">
                    <li className="d-flex">
                      <div className="flex-grow-1">
                        <label
                          htmlFor="isActive"
                          className="form-check-label fs-13"
                        >
                          Aktif
                        </label>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch">
                          <Input
                            name="isActive"
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="isActive"
                            checked={agencyValidation.values.isActive || false}
                            onChange={(e) => {
                              agencyValidation.handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
                disabled={updateAgencyLoading}
              >
                Kaydet
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default SubAgencyUpdateModal;
