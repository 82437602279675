import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchAgreement,
  updateAgreement,
} from "../../slices/apps/agreementSlice";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

// diğer importlar
import classnames from "classnames";
import ReactInputMask from "react-input-mask";

// regex, değişkenler

// data dosyaları
const carsInfo = [
  {
    typeId: 1,
    typeName: "Otomobil",
    brands: [
      { brandId: 1, brandName: "ALFA ROMEO" },
      { brandId: 2, brandName: "ASTON MARTIN" },
      { brandId: 3, brandName: "AUDI" },
      { brandId: 4, brandName: "SAAB" },
      { brandId: 5, brandName: "SEAT" },
      { brandId: 6, brandName: "BMW" },
      { brandId: 7, brandName: "BUICK" },
      { brandId: 8, brandName: "BMC" },
      { brandId: 9, brandName: "DAEWOO" },
      { brandId: 10, brandName: "CADILLAC" },
      { brandId: 11, brandName: "CHEVROLET" },
      { brandId: 12, brandName: "CHRYSLER" },
      { brandId: 13, brandName: "CITROEN" },
      { brandId: 14, brandName: "DAF" },
      { brandId: 15, brandName: "DAIHATSU" },
      { brandId: 16, brandName: "DODGE/USA" },
      { brandId: 17, brandName: "FERRARI" },
      { brandId: 18, brandName: "FIAT" },
      { brandId: 19, brandName: "FORD/OTOSAN" },
      { brandId: 20, brandName: "HONDA" },
      { brandId: 21, brandName: "ISUZU" },
      { brandId: 22, brandName: "LANCIA" },
      { brandId: 23, brandName: "LINCOLN" },
      { brandId: 24, brandName: "LADA" },
      { brandId: 25, brandName: "MASERATI" },
      { brandId: 26, brandName: "MAZDA" },
      { brandId: 27, brandName: "MERCEDES" },
      { brandId: 28, brandName: "MERCURY" },
      { brandId: 29, brandName: "MITSUBISHI" },
      { brandId: 30, brandName: "TOFAS-FIAT" },
      { brandId: 31, brandName: "OTOKAR/MAGIRUS" },
      { brandId: 32, brandName: "NISSAN" },
      { brandId: 33, brandName: "OPEL" },
      { brandId: 34, brandName: "PEUGEOT" },
      { brandId: 35, brandName: "PONTIAC" },
      { brandId: 36, brandName: "PORSCHE" },
      { brandId: 37, brandName: "TATA" },
      { brandId: 38, brandName: "RENAULT (OYAK)" },
      { brandId: 39, brandName: "RENAULT" },
      { brandId: 40, brandName: "RANGE ROVER" },
      { brandId: 41, brandName: "SSANGYONG" },
      { brandId: 42, brandName: "SUZUKI" },
      { brandId: 43, brandName: "SKODA" },
      { brandId: 44, brandName: "SUBARU" },
      { brandId: 45, brandName: "SCANIA" },
      { brandId: 46, brandName: "TOYOTA" },
      { brandId: 47, brandName: "VOLKSWAGEN" },
      { brandId: 48, brandName: "VOLVO" },
      { brandId: 49, brandName: "FORD /USA" },
      { brandId: 50, brandName: "HYUNDAI" },
      { brandId: 51, brandName: "JAGUAR" },
      { brandId: 52, brandName: "JAC" },
      { brandId: 53, brandName: "GULERYUZ" },
      { brandId: 54, brandName: "LAMBORGHINI" },
      { brandId: 55, brandName: "LOTUS" },
      { brandId: 56, brandName: "MORGAN" },
      { brandId: 57, brandName: "PROTON" },
      { brandId: 58, brandName: "MAN" },
      { brandId: 59, brandName: "ZIRAI TRAKTOR" },
      { brandId: 60, brandName: "VOLVO-TR" },
      { brandId: 61, brandName: "LAND ROVER" },
      { brandId: 62, brandName: "MINI" },
      { brandId: 63, brandName: "NEOPLAN" },
      { brandId: 64, brandName: "DACIA" },
      { brandId: 65, brandName: "SETRA" },
      { brandId: 66, brandName: "SMART" },
      { brandId: 67, brandName: "CATERHAM" },
      { brandId: 68, brandName: "INFINITI" },
      { brandId: 69, brandName: "TESLA" },
      { brandId: 70, brandName: "KTM" },
      { brandId: 71, brandName: "CHANGAN" },
      { brandId: 72, brandName: "FISKER" },
      { brandId: 73, brandName: "SHUANGHUAN" },
      { brandId: 74, brandName: "TCV" },
      { brandId: 75, brandName: "OTOYOLIVECOFIAT" },
      { brandId: 76, brandName: "TURKKAR" },
      { brandId: 77, brandName: "TEZELLER" },
      { brandId: 78, brandName: "TEMSA" },
      { brandId: 79, brandName: "ASKAM/FARGO/DESOTO" },
      { brandId: 80, brandName: "HUMMER" },
      { brandId: 81, brandName: "AR-BUS" },
      { brandId: 82, brandName: "IKCO" },
      { brandId: 83, brandName: "BENTLEY" },
      { brandId: 84, brandName: "KARSAN" },
      { brandId: 85, brandName: "PIAGGIO" },
      { brandId: 86, brandName: "HUANGHAI" },
      { brandId: 87, brandName: "VISEON" },
      { brandId: 88, brandName: "KENWORTH" },
      { brandId: 89, brandName: "IRIZAR" },
      { brandId: 90, brandName: "SAIPA" },
      { brandId: 91, brandName: "LEXUS" },
      { brandId: 92, brandName: "SOLARIS" },
      { brandId: 93, brandName: "BOZANKAYA" },
      { brandId: 94, brandName: "MULTICAR" },
      { brandId: 95, brandName: "ADRIA" },
      { brandId: 96, brandName: "HAIMA" },
      { brandId: 97, brandName: "MILLER" },
      { brandId: 98, brandName: "SCHMIDT" },
      { brandId: 99, brandName: "RAVON" },
      { brandId: 100, brandName: "EMT" },
      { brandId: 101, brandName: "ZOTYE" },
      { brandId: 102, brandName: "PILOTCAR" },
      { brandId: 103, brandName: "MCLAREN" },
      { brandId: 104, brandName: "ALPINE" },
      { brandId: 105, brandName: "MG" },
      { brandId: 106, brandName: "XEV" },
      { brandId: 107, brandName: "SERES" },
      { brandId: 108, brandName: "CUPRA" },
      { brandId: 109, brandName: "DONG FENG" },
      { brandId: 110, brandName: "TRUMPCHI/GAC" },
      { brandId: 111, brandName: "ORA" },
      { brandId: 112, brandName: "BRILLIANCE" },
      { brandId: 113, brandName: "MOTORSIKLET" },
      { brandId: 114, brandName: "ALKE" },
      { brandId: 115, brandName: "MEGA" },
      { brandId: 116, brandName: "AIXAM" },
      { brandId: 117, brandName: "FOLKVAN" },
      { brandId: 118, brandName: "PGO" },
      { brandId: 119, brandName: "MJT" },
      { brandId: 120, brandName: "SCAM" },
      { brandId: 121, brandName: "DFM" },
      { brandId: 122, brandName: "HFKANUNI" },
      { brandId: 123, brandName: "MAHINDRA" },
      { brandId: 124, brandName: "CHERY" },
      { brandId: 125, brandName: "TURKAR" },
      { brandId: 126, brandName: "KOENIGSEGG" },
      { brandId: 127, brandName: "GONOW" },
      { brandId: 128, brandName: "ASTRA" },
      { brandId: 129, brandName: "AVIA" },
      { brandId: 130, brandName: "VEICOLI" },
      { brandId: 131, brandName: "SINOTRUK" },
      { brandId: 132, brandName: "BREDAMENARIBUS" },
      { brandId: 133, brandName: "MAYBACH" },
      { brandId: 134, brandName: "PETERBILT" },
      { brandId: 135, brandName: "WIESMANN" },
      { brandId: 136, brandName: "HYMER" },
      { brandId: 137, brandName: "ROLLS-ROYCE" },
      { brandId: 138, brandName: "AKIA" },
      { brandId: 139, brandName: "THE LONDON TAXI" },
      { brandId: 140, brandName: "GREAT WALL" },
      { brandId: 141, brandName: "GROVE" },
      { brandId: 142, brandName: "HISCAR" },
      { brandId: 143, brandName: "BYD" },
      { brandId: 144, brandName: "ZOOMLION" },
      { brandId: 145, brandName: "CODA" },
      { brandId: 146, brandName: "TATRA" },
      { brandId: 147, brandName: "TADANO FAUN" },
      { brandId: 148, brandName: "MENARINIBUS" },
      { brandId: 149, brandName: "PIMAKINA" },
      { brandId: 150, brandName: "DS" },
      { brandId: 151, brandName: "MAZ" },
      { brandId: 152, brandName: "SKYWELL" },
      { brandId: 153, brandName: "KIA" },
      { brandId: 154, brandName: "BUGATTI" },
      { brandId: 155, brandName: "GEELY" },
      { brandId: 156, brandName: "FAW" },
      { brandId: 157, brandName: "ISOBUS" },
      { brandId: 158, brandName: "DFSK" },
      { brandId: 159, brandName: "GMC" },
      { brandId: 160, brandName: "GAZ" },
      { brandId: 161, brandName: "TOG" },
    ],
  },
  {
    typeId: 2,
    typeName: "4 Teker Hafif Ticari",
    brands: [
      { brandId: 1, brandName: "ALFA ROMEO" },
      { brandId: 2, brandName: "ASTON MARTIN" },
      { brandId: 3, brandName: "AUDI" },
      { brandId: 4, brandName: "SAAB" },
      { brandId: 5, brandName: "SEAT" },
      { brandId: 6, brandName: "BMW" },
      { brandId: 7, brandName: "BUICK" },
      { brandId: 8, brandName: "BMC" },
      { brandId: 9, brandName: "DAEWOO" },
      { brandId: 10, brandName: "CADILLAC" },
      { brandId: 11, brandName: "CHEVROLET" },
      { brandId: 12, brandName: "CHRYSLER" },
      { brandId: 13, brandName: "CITROEN" },
      { brandId: 14, brandName: "DAF" },
      { brandId: 15, brandName: "DAIHATSU" },
      { brandId: 16, brandName: "DODGE/USA" },
      { brandId: 17, brandName: "FERRARI" },
      { brandId: 18, brandName: "FIAT" },
      { brandId: 19, brandName: "FORD/OTOSAN" },
      { brandId: 20, brandName: "HONDA" },
      { brandId: 21, brandName: "ISUZU" },
      { brandId: 22, brandName: "LANCIA" },
      { brandId: 23, brandName: "LINCOLN" },
      { brandId: 24, brandName: "LADA" },
      { brandId: 25, brandName: "MASERATI" },
      { brandId: 26, brandName: "MAZDA" },
      { brandId: 27, brandName: "MERCEDES" },
      { brandId: 28, brandName: "MERCURY" },
      { brandId: 29, brandName: "MITSUBISHI" },
      { brandId: 30, brandName: "TOFAS-FIAT" },
      { brandId: 31, brandName: "OTOKAR/MAGIRUS" },
      { brandId: 32, brandName: "NISSAN" },
      { brandId: 33, brandName: "OPEL" },
      { brandId: 34, brandName: "PEUGEOT" },
      { brandId: 35, brandName: "PONTIAC" },
      { brandId: 36, brandName: "PORSCHE" },
      { brandId: 37, brandName: "TATA" },
      { brandId: 38, brandName: "RENAULT (OYAK)" },
      { brandId: 39, brandName: "RENAULT" },
      { brandId: 40, brandName: "RANGE ROVER" },
      { brandId: 41, brandName: "SSANGYONG" },
      { brandId: 42, brandName: "SUZUKI" },
      { brandId: 43, brandName: "SKODA" },
      { brandId: 44, brandName: "SUBARU" },
      { brandId: 45, brandName: "SCANIA" },
      { brandId: 46, brandName: "TOYOTA" },
      { brandId: 47, brandName: "VOLKSWAGEN" },
      { brandId: 48, brandName: "VOLVO" },
      { brandId: 49, brandName: "FORD /USA" },
      { brandId: 50, brandName: "HYUNDAI" },
      { brandId: 51, brandName: "JAGUAR" },
      { brandId: 52, brandName: "JAC" },
      { brandId: 53, brandName: "GULERYUZ" },
      { brandId: 54, brandName: "LAMBORGHINI" },
      { brandId: 55, brandName: "LOTUS" },
      { brandId: 56, brandName: "MORGAN" },
      { brandId: 57, brandName: "PROTON" },
      { brandId: 58, brandName: "MAN" },
      { brandId: 59, brandName: "ZIRAI TRAKTOR" },
      { brandId: 60, brandName: "VOLVO-TR" },
      { brandId: 61, brandName: "LAND ROVER" },
      { brandId: 62, brandName: "MINI" },
      { brandId: 63, brandName: "NEOPLAN" },
      { brandId: 64, brandName: "DACIA" },
      { brandId: 65, brandName: "SETRA" },
      { brandId: 66, brandName: "SMART" },
      { brandId: 67, brandName: "CATERHAM" },
      { brandId: 68, brandName: "INFINITI" },
      { brandId: 69, brandName: "TESLA" },
      { brandId: 70, brandName: "KTM" },
      { brandId: 71, brandName: "CHANGAN" },
      { brandId: 72, brandName: "FISKER" },
      { brandId: 73, brandName: "SHUANGHUAN" },
      { brandId: 74, brandName: "TCV" },
      { brandId: 75, brandName: "OTOYOLIVECOFIAT" },
      { brandId: 76, brandName: "TURKKAR" },
      { brandId: 77, brandName: "TEZELLER" },
      { brandId: 78, brandName: "TEMSA" },
      { brandId: 79, brandName: "ASKAM/FARGO/DESOTO" },
      { brandId: 80, brandName: "HUMMER" },
      { brandId: 81, brandName: "AR-BUS" },
      { brandId: 82, brandName: "IKCO" },
      { brandId: 83, brandName: "BENTLEY" },
      { brandId: 84, brandName: "KARSAN" },
      { brandId: 85, brandName: "PIAGGIO" },
      { brandId: 86, brandName: "HUANGHAI" },
      { brandId: 87, brandName: "VISEON" },
      { brandId: 88, brandName: "KENWORTH" },
      { brandId: 89, brandName: "IRIZAR" },
      { brandId: 90, brandName: "SAIPA" },
      { brandId: 91, brandName: "LEXUS" },
      { brandId: 92, brandName: "SOLARIS" },
      { brandId: 93, brandName: "BOZANKAYA" },
      { brandId: 94, brandName: "MULTICAR" },
      { brandId: 95, brandName: "ADRIA" },
      { brandId: 96, brandName: "HAIMA" },
      { brandId: 97, brandName: "MILLER" },
      { brandId: 98, brandName: "SCHMIDT" },
      { brandId: 99, brandName: "RAVON" },
      { brandId: 100, brandName: "EMT" },
      { brandId: 101, brandName: "ZOTYE" },
      { brandId: 102, brandName: "PILOTCAR" },
      { brandId: 103, brandName: "MCLAREN" },
      { brandId: 104, brandName: "ALPINE" },
      { brandId: 105, brandName: "MG" },
      { brandId: 106, brandName: "XEV" },
      { brandId: 107, brandName: "SERES" },
      { brandId: 108, brandName: "CUPRA" },
      { brandId: 109, brandName: "DONG FENG" },
      { brandId: 110, brandName: "TRUMPCHI/GAC" },
      { brandId: 111, brandName: "ORA" },
      { brandId: 112, brandName: "BRILLIANCE" },
      { brandId: 113, brandName: "MOTORSIKLET" },
      { brandId: 114, brandName: "ALKE" },
      { brandId: 115, brandName: "MEGA" },
      { brandId: 116, brandName: "AIXAM" },
      { brandId: 117, brandName: "FOLKVAN" },
      { brandId: 118, brandName: "PGO" },
      { brandId: 119, brandName: "MJT" },
      { brandId: 120, brandName: "SCAM" },
      { brandId: 121, brandName: "DFM" },
      { brandId: 122, brandName: "HFKANUNI" },
      { brandId: 123, brandName: "MAHINDRA" },
      { brandId: 124, brandName: "CHERY" },
      { brandId: 125, brandName: "TURKAR" },
      { brandId: 126, brandName: "KOENIGSEGG" },
      { brandId: 127, brandName: "GONOW" },
      { brandId: 128, brandName: "ASTRA" },
      { brandId: 129, brandName: "AVIA" },
      { brandId: 130, brandName: "VEICOLI" },
      { brandId: 131, brandName: "SINOTRUK" },
      { brandId: 132, brandName: "BREDAMENARIBUS" },
      { brandId: 133, brandName: "MAYBACH" },
      { brandId: 134, brandName: "PETERBILT" },
      { brandId: 135, brandName: "WIESMANN" },
      { brandId: 136, brandName: "HYMER" },
      { brandId: 137, brandName: "ROLLS-ROYCE" },
      { brandId: 138, brandName: "AKIA" },
      { brandId: 139, brandName: "THE LONDON TAXI" },
      { brandId: 140, brandName: "GREAT WALL" },
      { brandId: 141, brandName: "GROVE" },
      { brandId: 142, brandName: "HISCAR" },
      { brandId: 143, brandName: "BYD" },
      { brandId: 144, brandName: "ZOOMLION" },
      { brandId: 145, brandName: "CODA" },
      { brandId: 146, brandName: "TATRA" },
      { brandId: 147, brandName: "TADANO FAUN" },
      { brandId: 148, brandName: "MENARINIBUS" },
      { brandId: 149, brandName: "PIMAKINA" },
      { brandId: 150, brandName: "DS" },
      { brandId: 151, brandName: "MAZ" },
      { brandId: 152, brandName: "SKYWELL" },
      { brandId: 153, brandName: "KIA" },
      { brandId: 154, brandName: "BUGATTI" },
      { brandId: 155, brandName: "GEELY" },
      { brandId: 156, brandName: "FAW" },
      { brandId: 157, brandName: "ISOBUS" },
      { brandId: 158, brandName: "DFSK" },
      { brandId: 159, brandName: "GMC" },
      { brandId: 160, brandName: "GAZ" },
      { brandId: 161, brandName: "TOG" },
    ],
  },
];

const AgreementUpdateModal = ({ id }) => {
  const dispatch = useDispatch();

  const { userProfile } = useProfile();

  // states
  const [modal, setModal] = useState(false);

  const { agreement, updateAgreementLoading, agencies } = useSelector(
    (state) => {
      return {
        agreement: state.agreement.agreement,
        updateAgreementLoading: state.agreement.updateAgreementLoading,
        agencies: state.agency.agencies,
      };
    },
    shallowEqual
  );

  // states

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchAgreement(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);

        agreementValidation.resetForm();
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  // Sözleşme validation
  const agreementValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: id,
      aggrementNo: agreement?.AggrementNo,
      aggreementName: agreement?.AggreementName,
      createDatetime: agreement?.CreateDatetime,
      description: agreement?.Description,
      startDate: agreement?.StartDate,
      endDate: agreement?.EndDate,
      price: agreement?.Price,
      isPaid: agreement?.IsPaid,
      isActive: agreement?.IsActive,
      earning: agreement?.Earning,
      agencyId: agreement?.AgencyId,
      customerId: agreement?.CustomerId,
      paymentOrderNumber: agreement?.PaymentOrderNumber,
      posTransactionID: agreement?.PosTransactionID,

      totalLimit: agreement?.TotalLimit,
      towingLimit: agreement?.TowingLimit,
      windshield: agreement?.Windshield,
      ageLimit: agreement?.AgeLimit,
      accident: agreement?.Accident,
      fault: agreement?.Fault,
      tire: agreement?.Tire,
      fuel: agreement?.Fuel,
      landslide: agreement?.Landslide,
      headlight: agreement?.Headlight,
      health: agreement?.Health,
      tired: agreement?.Tired,
      evFault: agreement?.EVFault,
      wildAnimal: agreement?.WildAnimal,

      totalRight: agreement?.TotalRight,

      carCategory: agreement?.CarCategory,
      carBrand: agreement?.CarBrand,
      carModel: agreement?.CarModel,
      carPlate: agreement?.CarPlate,
      carYear: agreement?.CarYear,
    },

    validationSchema: Yup.object({
      startDate: Yup.string().required("Lütfen başlangıç tarihi girin"),
      endDate: Yup.string().required("Lütfen bitiş tarihi girin"),
      agencyId: Yup.string().required("Lütfen bir acente seçin"),

      carCategory: Yup.string(),
      carBrand: Yup.string(),
      carModel: Yup.string(),
      carPlate: Yup.string().required("Lütfen plaka giriniz"),
      carYear: Yup.number()
        .typeError("Model yılı girin")
        .required("Model yılı girin")
        .max(new Date().getFullYear(), `Geçersiz model yılı`),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(updateAgreement(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        tog_large(false);
      }
    },
  });

  if (userProfile.data.Role != "SuperAdmin") {
    return (
      <span
        style={{
          display: "flex",
          padding: "3px",
        }}
      >
        <i
          className="bx bx-edit text-muted"
          style={{
            fontSize: "20px",
          }}
        />
      </span>
    );
  }

  return (
    <Fragment>
      <span
        id={`agreement-update-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-edit text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`agreement-update-tooltipTop-${id}`}
      >
        Sözleşme Bilgilerini Düzenle
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Sözleşme Bilgilerini Düzenle
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              agreementValidation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="align-items-start">
              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Başlangıç Tarihi</Label>
                  <Flatpickr
                    className={
                      agreementValidation.touched.startDate &&
                      agreementValidation.errors.startDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    options={{
                      dateFormat: "d.m.Y",
                      // minDate: "today",
                      locale: { ...Turkish },
                    }}
                    value={
                      agreementValidation.values.startDate
                        ? new Date(agreementValidation.values.startDate)
                        : null
                    }
                    onChange={(date) => {
                      agreementValidation.setFieldValue(
                        "startDate",
                        date.toISOString()
                      );
                    }}
                  />
                  {agreementValidation.touched.startDate &&
                    agreementValidation.errors.startDate && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {agreementValidation.errors.startDate}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Bitiş Tarihi</Label>
                  <Flatpickr
                    className={
                      agreementValidation.touched.endDate &&
                      agreementValidation.errors.endDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    options={{
                      dateFormat: "d.m.Y",
                      // minDate: "today",
                      locale: { ...Turkish },
                    }}
                    value={
                      agreementValidation.values.endDate
                        ? new Date(agreementValidation.values.endDate)
                        : null
                    }
                    onChange={(date) => {
                      agreementValidation.setFieldValue(
                        "endDate",
                        date.toISOString()
                      );
                    }}
                  />
                  {agreementValidation.touched.endDate &&
                    agreementValidation.errors.endDate && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {agreementValidation.errors.endDate}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="agencyIdSelect" className="form-label">
                    Acente
                  </Label>
                  <Select
                    placeholder="Acente seçiniz"
                    id="agencyIdSelect"
                    name="agencyId"
                    options={agencies.map((item) => ({
                      label: item.CompanyName,
                      value: item.Id,
                    }))}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    value={
                      agencies
                        .map((item) => ({
                          label: item.CompanyName,
                          value: item.Id,
                        }))
                        .find(
                          (item) =>
                            item.value === agreementValidation.values.agencyId
                        ) || ""
                    }
                    onChange={(value) => {
                      agreementValidation.setFieldValue(
                        "agencyId",
                        value.value
                      );
                    }}
                    onBlur={agreementValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          agreementValidation.touched.agencyId &&
                          agreementValidation.errors.agencyId &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {agreementValidation.touched.agencyId &&
                    agreementValidation.errors.agencyId && (
                      <div className="d-block invalid-feedback">
                        {agreementValidation.errors.agencyId}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="description" className="form-label">
                    Açıklama
                  </Label>
                  <Input
                    name="description"
                    className="form-control"
                    placeholder="Açıklama"
                    type="text"
                    autoComplete="off"
                    onChange={agreementValidation.handleChange}
                    onBlur={agreementValidation.handleBlur}
                    value={agreementValidation.values.description || ""}
                    invalid={
                      agreementValidation.touched.description &&
                      agreementValidation.errors.description
                        ? true
                        : false
                    }
                  />
                  {agreementValidation.touched.description &&
                    agreementValidation.errors.description && (
                      <FormFeedback type="invalid">
                        {agreementValidation.errors.description}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="carPlate" className="form-label">
                    Plaka
                  </Label>
                  <Input
                    name="carPlate"
                    className="form-control"
                    placeholder="Plaka"
                    type="text"
                    autoComplete="off"
                    maxLength={50}
                    onChange={(e) => {
                      const value = e.target.value.toUpperCase();
                      agreementValidation.setFieldValue("carPlate", value);
                    }}
                    onBlur={agreementValidation.handleBlur}
                    value={agreementValidation.values.carPlate || ""}
                    invalid={
                      agreementValidation.touched.carPlate &&
                      agreementValidation.errors.carPlate
                        ? true
                        : false
                    }
                  />
                  {agreementValidation.touched.carPlate &&
                    agreementValidation.errors.carPlate && (
                      <FormFeedback type="invalid">
                        {agreementValidation.errors.carPlate}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="carCategorySelect" className="form-label">
                    Araç Tipi
                  </Label>
                  <Select
                    placeholder="Araç tipi seçiniz"
                    id="carCategorySelect"
                    name="carCategory"
                    options={carsInfo.map((item) => ({
                      value: item.typeName,
                      label: item.typeName,
                    }))}
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    isClearable={true}
                    value={
                      carsInfo
                        .map((item) => ({
                          value: item.typeName,
                          label: item.typeName,
                        }))
                        .find(
                          (option) =>
                            option.value ===
                            agreementValidation.values.carCategory
                        ) || ""
                    }
                    onChange={(value) => {
                      agreementValidation.setFieldValue(
                        "carCategory",
                        value ? value.value : ""
                      );
                      agreementValidation.setFieldValue("carBrand", "");
                    }}
                    onBlur={agreementValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          agreementValidation.touched.carCategory &&
                          agreementValidation.errors.carCategory &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {agreementValidation.touched.carCategory &&
                    agreementValidation.errors.carCategory && (
                      <div className="d-block invalid-feedback">
                        {agreementValidation.errors.carCategory}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="carYear">
                    Model Yılı
                  </Label>
                  <InputGroup>
                    <ReactInputMask
                      mask="9999"
                      maskChar=" "
                      name="carYear"
                      alwaysShowMask
                      inputMode="numeric"
                      type="tel"
                      className={
                        agreementValidation.touched.carYear &&
                        agreementValidation.errors.carYear
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={agreementValidation.handleChange}
                      value={agreementValidation.values.carYear || ""}
                      style={{ zIndex: 0 }}
                    />
                  </InputGroup>
                  {agreementValidation.touched.carYear &&
                    agreementValidation.errors.carYear && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {agreementValidation.errors.carYear}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="carBrandSelect" className="form-label">
                    Marka
                  </Label>
                  <Select
                    placeholder="Marka seçiniz"
                    id="carBrandSelect"
                    name="carBrand"
                    options={
                      carsInfo
                        .find(
                          (item) =>
                            item.typeName ===
                            agreementValidation.values.carCategory
                        )
                        ?.brands.map((brand) => ({
                          value: brand.brandName,
                          label: brand.brandName,
                        })) || []
                    }
                    noOptionsMessage={() => "Seçenek bulunamadı"}
                    isClearable={true}
                    value={
                      carsInfo
                        .find(
                          (item) =>
                            item.typeName ===
                            agreementValidation.values.carCategory
                        )
                        ?.brands.map((brand) => ({
                          value: brand.brandName,
                          label: brand.brandName,
                        }))
                        .find(
                          (option) =>
                            option.value === agreementValidation.values.carBrand
                        ) || ""
                    }
                    onChange={(value) => {
                      agreementValidation.setFieldValue(
                        "carBrand",
                        value ? value.value : ""
                      );
                    }}
                    onBlur={agreementValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          agreementValidation.touched.carBrand &&
                          agreementValidation.errors.carBrand &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {agreementValidation.touched.carBrand &&
                    agreementValidation.errors.carBrand && (
                      <div className="d-block invalid-feedback">
                        {agreementValidation.errors.carBrand}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="carModel" className="form-label">
                    Model
                  </Label>
                  <Input
                    name="carModel"
                    className="form-control"
                    placeholder="Model"
                    type="text"
                    autoComplete="off"
                    onChange={agreementValidation.handleChange}
                    onBlur={agreementValidation.handleBlur}
                    value={agreementValidation.values.carModel || ""}
                    invalid={
                      agreementValidation.touched.carModel &&
                      agreementValidation.errors.carModel
                        ? true
                        : false
                    }
                  />
                  {agreementValidation.touched.carModel &&
                    agreementValidation.errors.carModel && (
                      <FormFeedback type="invalid">
                        {agreementValidation.errors.carModel}
                      </FormFeedback>
                    )}
                </div>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
                disabled={updateAgreementLoading}
              >
                Kaydet
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AgreementUpdateModal;
