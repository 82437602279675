import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isEInvoice, setIsEInvoice] = useState(false);
  const [isEDispatch, setIsEDispatch] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  const { isUserData } = useSelector((state) => {
    return {
      isUserData: state.auth.isUserData,
    };
  }, shallowEqual);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "SuperAdmin") {
      setIsSuperAdmin(false);
    }
  }, [history, iscurrentState, isSuperAdmin]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Ana Sayfa",
      icon: "ri-dashboard-2-line",
      link: "/",
      roles: ["SuperAdmin", "Agency", "SubAgency"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },

    {
      id: "agreements",
      label: "Sözleşmeler",
      icon: "ri-file-list-line",
      link: "/agreements",
      roles: ["SuperAdmin", "Agency", "SubAgency"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Agreements");
      },
    },

    {
      id: "customers",
      label: "Müşteriler",
      icon: "ri-group-line",
      link: "/customers",
      roles: ["SuperAdmin", "Agency", "SubAgency"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Customers");
      },
    },

    {
      id: "sub-agencies",
      label: "Alt Acenteler",
      icon: "ri-file-list-line",
      link: "/sub-agencies",
      roles: ["Agency"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("SubAgencies");
      },
    },

    {
      id: "paymentClaim",
      label: "Ödemeler",
      icon: "ri-wallet-3-line",
      link: "/payment-claims",
      roles: ["SuperAdmin", "Agency"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("PaymentClaim");
      },
    },

    {
      id: "store",
      label: "Paketler",
      icon: "ri-store-2-line",
      link: "/packages",
      roles: ["SuperAdmin", "Agency", "SubAgency"],
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Store");
      },
    },

    {
      label: "SuperAdmin",
      isHeader: true,
    },

    {
      id: "settings",
      label: "Yönetim",
      icon: "ri-settings-3-line",
      link: "/#",
      roles: ["SuperAdmin"],
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        setIscurrentState("Settings");
        updateIconSidebar(e);
      },
      stateVariables: isSettings,
      subItems: [
        {
          id: "agencies",
          label: "Acenteler",
          link: "/agencies",
          parentId: "settings",
        },
        {
          id: "sub-agencies",
          label: "Alt Acenteler",
          link: "/sub-agencies",
          parentId: "settings",
        },
        {
          id: "users",
          label: "Kullanıcılar",
          link: "/users",
          parentId: "settings",
        },
        {
          id: "package-admin",
          label: "Paketler",
          link: "/package-admin",
          parentId: "settings",
        },
        {
          id: "bulk-sale",
          label: "Toplu Paket",
          link: "/bulk-sale",
          parentId: "settings",
        },
      ],
    },
  ];

  const filteredMenuItems = menuItems.filter((item) => {
    if (item?.isHeader || item?.roles?.includes(isUserData?.Role)) {
      return item;
    }
  });

  return <React.Fragment>{filteredMenuItems}</React.Fragment>;
};
export default Navdata;
