import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

// react table
import {
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../slices/apps/userSlice";

// router
import { Link } from "react-router-dom";

// sayfalar, modallar vb.
import UserAddModal from "./UserAddModal";
import UserUpdateModal from "./UserUpdateModal";
import UserChangePassword from "./UserChangePassword";

const roles = {
  SuperAdmin: "Yönetici",
  Agency: "Acente",
  Customer: "Müşteri",
  Salesman: "Pazarlamacı",
  Support: "Destek",
};

// Column Definitions
const columnHelper = createColumnHelper();

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      id="search-bar-0"
      className="form-control border-0 search"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const UserTable = () => {
  const dispatch = useDispatch();

  // Redux
  const { users, usersLoading } = useSelector((state) => {
    return {
      users: state.user.users,
      usersLoading: state.user.usersLoading,
    };
  }, shallowEqual);

  // States
  const [data, setData] = useState([]); // table için data dosyası
  const [globalFilter, setGlobalFilter] = useState(""); // arama çubuğu

  const [filterRole, setFilterRole] = useState(""); // Rol filtresi
  const [filterStatus, setFilterStatus] = useState(""); // Durum filtresi

  const [addModal, setAddModal] = useState(false); // Kullanıcı ekleme modalı

  // filtreleme işlemi için
  useEffect(() => {
    setData([]);

    const filteredData = users?.filter((data) => {
      if (filterStatus && data.IsActive.toString() != filterStatus)
        return false;
      if (filterRole && data.UserRole != filterRole) return false;
      if (
        globalFilter &&
        !Object.values(data).some(
          (value) =>
            typeof value === "string" &&
            (value
              .toLocaleLowerCase()
              .includes(globalFilter.toLocaleLowerCase()) ||
              value
                .toLocaleUpperCase()
                .includes(globalFilter.toLocaleUpperCase()))
        )
      )
        return false;
      return true;
    });

    setData(filteredData);
  }, [globalFilter, filterRole, filterStatus, users]);

  // Hooks
  const columns = useMemo(
    () => [
      columnHelper.accessor("actions", {
        header: "",
        size: 80,
        minSize: 80,
        maxSize: 80,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <div className="d-flex" style={{ gap: "5px" }}>
              <UserChangePassword id={row.original.Id} />
              <UserUpdateModal id={row.original.Id} />
            </div>
          );
        },
      }),
      columnHelper.accessor("IsActive", {
        header: "Durum",
        size: 120,
        minSize: 120,
        maxSize: 120,
        cell: ({ row }) => {
          return (
            <div>
              {row.original.IsActive ? (
                <div className="text-truncate  fw-bold fs-12 ">
                  <i className="bx bxs-circle align-middle me-1 fs-14 text-success"></i>
                  Aktif
                </div>
              ) : (
                <div className="text-truncate fw-bold fs-12 ">
                  <i className="bx bxs-circle align-middle me-1 fs-14 text-warning"></i>
                  Pasif
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("NameSurname", {
        header: "Ad Soyad",
        size: "auto",
        minSize: 250,
        maxSize: 350,
        enableResizing: false,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-semibold">
              {row.original.NameSurname}
              {row.original.UserRole == "Salesman" && (
                <div className="text-truncate fw-medium fs-12 text-muted">
                  Pazarlamacı Referans Kodu:{" "}
                  <b className="text-muted">{row.original.SalesmanCode}</b>
                </div>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("UserRole", {
        header: "Rol",
        size: 120,
        minSize: 120,
        maxSize: 120,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-bold fs-12 ">
              <i className="bx bxs-user align-middle me-1 fs-14"></i>
              <b className="text-dark">{roles[row.original.UserRole]}</b>
            </div>
          );
        },
      }),
      columnHelper.accessor("PhoneNumber", {
        header: "Telefon Numarası",
        size: 220,
        minSize: 220,
        maxSize: 220,
        enableResizing: false,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-semibold">
              <i className="mdi mdi-phone align-middle me-1"></i>
              <b className="text-truncate fw-semibold">
                {row.original.PhoneNumber
                  ? `+90 ${row.original.PhoneNumber.toString().replace(
                      /(\d{3})(\d{3})(\d{2})(\d{2})/,
                      "$1 $2 $3 $4"
                    )}`
                  : "-"}
              </b>
            </div>
          );
        },
      }),
      columnHelper.accessor("Email", {
        header: "E-Posta Adresi",
        size: 220,
        minSize: 220,
        maxSize: 220,
        enableResizing: false,
        cell: ({ row }) => {
          return (
            <div className="text-truncate fw-semibold">
              <i className="mdi mdi-email align-middle me-1"></i>
              <b className="text-truncate fw-semibold">
                {row.original.Email ? row.original.Email : "-"}
              </b>
            </div>
          );
        },
      }),
    ],
    []
  );

  // Hooks
  const table = useReactTable({
    data: data,
    columns,
    state: {},
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
  } = table;

  return (
    <Fragment>
      <UserAddModal modal={addModal} setModal={setAddModal} />
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Kullanıcı Listesi</h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown">
              <DropdownToggle
                tag="a"
                className="text-reset"
                role="button"
                caret
              >
                İşlemler
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => dispatch(fetchUsers())}>
                  <i className="ri-refresh-line label-icon align-middle me-2"></i>
                  Yenile
                </DropdownItem>
                <DropdownItem onClick={() => setAddModal(true)}>
                  <i className="ri-add-line label-icon align-middle me-2"></i>
                  Yeni Kullanıcı Ekle
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>
        <CardBody>
          <Row className="mb-3">
            <CardBody className="border border-dashed border-end-0 border-start-0 border-top-0">
              <form>
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
                    <div style={{ width: "100px" }}>
                      <select
                        className="form-select mb-3"
                        aria-label="yufus"
                        value={getState().pagination.pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        <option defaultValue="10">10</option>
                        <option defaultValue="25">25</option>
                        <option defaultValue="50">50</option>
                        <option defaultValue="100">100</option>
                        <option defaultValue="200">200</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div
                      className="search-box me-2 mb-2 d-inline-block border"
                      style={{ borderRadius: "0.25em" }}
                    >
                      <DebouncedInput
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(value)}
                        placeholder={"Ara..."}
                      />
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              </form>
              <Row>
                <Col sm={6} md={4} lg={3} xl={2}>
                  <label className="form-label">Durum</label>
                  <select
                    className="form-select mb-3"
                    aria-label="yufus"
                    value={filterStatus}
                    onChange={(e) => {
                      setFilterStatus(e.target.value);
                    }}
                  >
                    <option value="">Tümü</option>
                    <option value={true}>Aktif</option>
                    <option value={false}>Pasif</option>
                  </select>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                  <label className="form-label">Rol</label>
                  <select
                    className="form-select mb-3"
                    aria-label="yufus"
                    value={filterRole}
                    onChange={(e) => {
                      setFilterRole(e.target.value);
                    }}
                  >
                    <option value="">Tümü</option>
                    <option value="SuperAdmin">Yönetici</option>
                    <option value="Agency">Acente</option>
                    <option value="SubAgency">Alt Acente</option>
                    <option value="Salesman">Pazarlamacı</option>
                    <option value="Support">Destek</option>
                  </select>
                </Col>
              </Row>
            </CardBody>
          </Row>

          <div
            style={{
              overflowX: "auto",
            }}
          >
            <Table>
              <thead>
                {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="text-muted"
                        style={{
                          width: header.column.columnDef.size,
                          minWidth: header.column.columnDef.minSize,
                          maxWidth: header.column.columnDef.maxSize,
                          letterSpacing: "0.8px",
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              userSelect: "none",
                            }}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <i className="ri-arrow-down-s-line"></i>,
                              desc: <i className="ri-arrow-up-s-line"></i>,
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {usersLoading ? (
                  <tr>
                    <td colSpan="100%" className="text-center pt-5 pb-5">
                      <Spinner color="primary" />
                      <p className="text-dark">Yükleniyor...</p>
                    </td>
                  </tr>
                ) : data.length === 0 ? (
                  <tr>
                    <td
                      colSpan="100%"
                      className="text-center pt-5 pb-5 text-muted"
                    >
                      <h1>
                        <i className="ri-error-warning-line text-muted"></i>
                      </h1>
                      <p>Kayıt bulunamadı!</p>
                    </td>
                  </tr>
                ) : (
                  getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              style={{
                                width: cell.column.columnDef.size,
                                minWidth: cell.column.columnDef.minSize,
                                maxWidth: cell.column.columnDef.maxSize,
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>

          <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
            <div className="col-sm">
              <div className="text-muted">
                {data.length > 0
                  ? `Toplam ${
                      table.getFilteredRowModel().rows.length
                    } kayıttan ${
                      table.getFilteredRowModel().rows.length === 0
                        ? 0
                        : table.getState().pagination.pageIndex *
                            table.getState().pagination.pageSize +
                          1
                    }
                  ile ${Math.min(
                    (table.getState().pagination.pageIndex + 1) *
                      table.getState().pagination.pageSize,
                    table.getFilteredRowModel().rows.length
                  )} arası gösteriliyor`
                  : "Kayıt Yok"}
              </div>
            </div>
            {data.length > 0 && (
              <div className="col-sm-auto">
                <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                  <li
                    className={
                      !getCanPreviousPage() ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link to="#" className="page-link" onClick={previousPage}>
                      {"<"}
                    </Link>
                  </li>
                  {getPageOptions().map((item, key) => {
                    const pageIndex = getState().pagination.pageIndex;
                    const totalPages = getPageOptions().length;
                    const firstPage = item === 0;
                    const lastPage = item === totalPages - 1;
                    const nearCurrentPage = Math.abs(pageIndex - item) <= 1;

                    // Display logic
                    if (firstPage || lastPage || nearCurrentPage) {
                      return (
                        <React.Fragment key={key}>
                          <li className="page-item">
                            <Link
                              to="#"
                              className={
                                pageIndex === item
                                  ? "page-link active"
                                  : "page-link"
                              }
                              onClick={() => setPageIndex(item)}
                            >
                              {item + 1}
                            </Link>
                          </li>
                        </React.Fragment>
                      );
                    } else if (item === 1 && pageIndex > 2) {
                      return null;
                    } else if (
                      item === totalPages - 2 &&
                      pageIndex < totalPages - 3
                    ) {
                      return null;
                    } else {
                      return null;
                    }
                  })}
                  <li
                    className={
                      !getCanNextPage() ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link to="#" className="page-link" onClick={nextPage}>
                      {">"}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default UserTable;
