import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchAgencyEarnings,
  fetchAgencyEarningsAdmin,
} from "../../slices/apps/agencySlices";

// sayfalar, modallar vb.
import EarningTable from "./EarningTable";
import EarningAdminTable from "./EarningAdminTable";
import EarningAgencyTable from "./EarningAgencyTable";

const PaymentClaim = () => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  const { agencyEarningsRefresh } = useSelector((state) => {
    return {
      agencyEarningsRefresh: state.agency.agencyEarningsRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      if (userProfile.data.Role == "SuperAdmin") {
        await dispatch(fetchAgencyEarningsAdmin());
      } else {
        await dispatch(fetchAgencyEarningsAdmin());
        await dispatch(fetchAgencyEarnings());
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, agencyEarningsRefresh]);

  document.title = "Ödemeler | Global Yol Yardım";
  if (userProfile.data.Role == "SuperAdmin") {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xxl={12}>
                <EarningAdminTable />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xxl={12}>
                <EarningTable />

                <EarningAgencyTable />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
};

export default PaymentClaim;
