import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchSubAgencies } from "../../slices/apps/agencySlices";
import { fetchSalesman } from "../../slices/apps/userSlice";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// sayfalar, modallar vb.
import SubAgencyTable from "./SubAgencyTable";

const SubAgencies = () => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  const { subAgenciesRefresh } = useSelector((state) => {
    return {
      subAgenciesRefresh: state.agency.subAgenciesRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchSubAgencies());

      if (userProfile?.data?.Role == "SuperAdmin") {
        await dispatch(fetchSalesman());
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, subAgenciesRefresh]);

  document.title = "Alt Acente Listesi | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <SubAgencyTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubAgencies;
