import React, { Fragment, useEffect, useRef, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAgreementInvoice } from "../../slices/apps/agreementSlice";

const AgreementViewInvoiceModal = ({ id }) => {
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      await dispatch(fetchAgreementInvoice({ id: id, type: "pdf" }));
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    }
  }

  return (
    <Fragment>
      <span
        id={`agreement-invoice-view-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bxs-file-find text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`agreement-invoice-view-tooltipTop-${id}`}
      >
        Fatura Görüntüle
      </UncontrolledTooltip>
    </Fragment>
  );
};

export default AgreementViewInvoiceModal;
