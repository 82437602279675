import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../slices/apps/userSlice";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const UserChangePassword = ({ id }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const { updateUserLoading } = useSelector((state) => {
    return {
      updateUserLoading: state.auth.updateUserLoading,
    };
  }, shallowEqual);

  function tog_large(e) {
    if (e) {
      validation.resetForm();

      setModal(true);
    } else setModal(false);
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      userId: id,
      oldPassword: "",
      newPassword: "",
    },

    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Lütfen yeni şifre girin")
        .min(6, "Lütfen yeni şifre girin"),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(changePassword(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(false);
      }
    },
  });

  return (
    <Fragment>
      <span
        id={`user-update-pass-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-lock text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`user-update-pass-tooltipTop-${id}`}
      >
        Kullanıcı Şifre Değiştir
      </UncontrolledTooltip>

      <Modal
        size="md"
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            setModal(false);
          }}
        >
          Kullanıcı Şifre Değiştir
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="align-items-start">
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="password-input">
                    Şifre
                  </Label>
                  <InputGroup>
                    <span
                      className="input-group-text"
                      style={{ width: "44px" }}
                      id="inputGroupPrepend2"
                    >
                      <i
                        onClick={() => setPasswordShow(!passwordShow)}
                        style={{
                          cursor: "pointer",
                          color: "gray",
                        }}
                        className={
                          passwordShow
                            ? "ri-eye-off-fill align-middle"
                            : "ri-eye-fill align-middle"
                        }
                      ></i>
                    </span>
                    <Input
                      name="newPassword"
                      value={validation.values.newPassword || ""}
                      type={passwordShow ? "text" : "password"}
                      className="form-control pe-5"
                      placeholder="Yeni Şifre"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.newPassword &&
                        validation.errors.newPassword
                          ? true
                          : false
                      }
                    />
                  </InputGroup>
                  {validation.touched.newPassword &&
                    validation.errors.newPassword && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {validation.errors.newPassword}
                      </div>
                    )}
                </div>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
                disabled={updateUserLoading}
              >
                Kaydet
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default UserChangePassword;
