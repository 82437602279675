import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAgreementDetails } from "../../slices/apps/agreementSlice";

// diğer importlar
import moment from "moment";

// pdf
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  pdf,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// fontlar
import poppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";

// font import
Font.register({
  family: "Poppins",
  src: poppinsRegular,
});

// görsel importlar
import Logo from "../../assets/images/logo/global_assitance_logo.png";

// const styles = StyleSheet.create({
//   container: {
//     flexDirection: "column",
//     gap: 20,
//     padding: 5,
//     maxWidth: "100%",
//     fontFamily: "Poppins",
//   },
//   page: {
//     width: "210mm",
//     height: "297mm",
//     minWidth: "210mm",
//     minHeight: "297mm",
//     backgroundColor: "#ffffff",
//     border: "1px solid lightgray",
//     padding: 10,
//   },
//   header: {
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//     width: "100%",
//   },
//   logo: {
//     height: "auto",
//     width: 90,
//     marginLeft: 10,
//   },
//   address: {
//     flexDirection: "row",
//     justifyContent: "flex-end",
//     alignItems: "center",
//     marginRight: 10,
//     flex: 1, // Eşit genişlik
//   },
//   textRight: {
//     textAlign: "right",
//   },
//   smallText: {
//     fontSize: 10,
//     color: "#CA1224",
//     margin: 0,
//   },
//   infoRow: {
//     flexDirection: "row",
//     alignItems: "center",
//   },
//   infoItem: {
//     flexDirection: "row",
//     alignItems: "center",
//     marginRight: 20,
//     flex: 1, // Eşit genişlik
//   },
//   title: {
//     textAlign: "right",
//     fontWeight: "bold",
//     marginRight: 10,
//     marginBottom: 20,
//     color: "black",
//   },
// });

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 20,
  },
  page: {
    width: "210mm",
    height: "297mm",
    padding: 10,
    backgroundColor: "#ffffff",
    border: "1px solid lightgray",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  logo: {
    width: 90,
    height: "auto",
  },
  address: {
    textAlign: "right",
    fontSize: 10,
    color: "#CA1224",
  },
  table: {
    border: "1px solid #ddd",
    fontSize: 9,
    width: "48%",
  },
  tableHeader: {
    backgroundColor: "#151D33",
    color: "#fff",
    textAlign: "center",
    padding: 5,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid #ddd",
  },
  tableCell: {
    flex: 1,
    padding: 8,
    borderRight: "1px solid #ddd",
    fontSize: 9,
  },
  tableCellBold: {
    fontWeight: "bold",
    backgroundColor: "#E8E8E8",
  },
  content: {
    fontSize: 10,
    marginTop: 20,
  },
});

const MyDocument = ({ logoSrc, agreementDetails }) => (
  <Document>
    <Page size="A4" style={styles.container}>
      <View style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Image src={logoSrc} style={styles.logo} />
          <Text style={styles.address}>
            Ziyabey Cad. Irmak Sk. Kızılcık Plaza No: 624/5 BALGAT/ANKARA
          </Text>
        </View>

        <Text
          style={{
            textAlign: "right",
            fontWeight: "bold",
            marginBottom: 20,
          }}
        >
          GLOBAL YOL YARDIM HİZMET SÖZLEŞMESİ
        </Text>

        {/* Tables */}
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={styles.table}>
            <Text style={styles.tableHeader}>SÖZLEŞME BİLGİLERİ</Text>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.tableCellBold]}>
                SÖZLEŞME NO
              </Text>
              <Text style={styles.tableCell}>
                agreementDetails?.AggrementNo
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.tableCellBold]}>
                PAKET
              </Text>
              <Text style={styles.tableCell}>
                  "Paket"
                 
              </Text>
            </View>
          </View>

          <View style={styles.table}>
            <Text style={styles.tableHeader}>ARAÇ BİLGİLERİ</Text>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.tableCellBold]}>
                PLAKA
              </Text>
              <Text style={styles.tableCell}>
                agreementDetails?.CarPlate
              </Text>
            </View>
          </View>
        </View>

        {/* Content */}
        <View style={styles.content}>
          <Text>
            1- <Text style={{ fontWeight: "bold" }}>MAKSAT:</Text> Araç yol
            yardım sözleşmesi...
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

const AgreementViewModal2 = ({ id }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null); // Sayfa için bir ref

  // states
  const [modal, setModal] = useState(false);

  const { agreementDetails } = useSelector((state) => {
    return {
      agreementDetails: state.agreement.agreementDetails,
    };
  }, shallowEqual);

  // states

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchAgreementDetails(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  const handleGeneratePDF = async () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = 210; // A4 genişliği (mm)
    const pdfHeight = 297; // A4 yüksekliği (mm)
    const canvasWidth = 794; // px (A4'ün 96 DPI'daki genişliği)
    const canvasHeight = 1123; // px (A4'ün 96 DPI'daki yüksekliği)

    if (pageRef.current) {
      const canvas = await html2canvas(pageRef.current, {
        scale: 2,
        width: canvasWidth,
        height: canvasHeight,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    }

    const pdfBlob = pdf.output("blob");
    const blobURL = URL.createObjectURL(pdfBlob);
    window.open(blobURL, "_blank");
  };

  return (
    <Fragment>
      <span
        id={`agreement-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-search text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`agreement-tooltipTop-${id}`}
      >
        Sözleşme Görüntüle
      </UncontrolledTooltip>

      <Modal
        size="xl"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title"
          style={{
            backgroundColor: "whitesmoke",
            paddingBottom: "10px",
          }}
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          
          {agreementDetails?.AggrementNo}
        </ModalHeader>

        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            maxHeight: "calc(90vh - 100px)",
            overflowY: "auto",
            overflowX: "auto",
            backgroundColor: "whitesmoke",
          }}
        >
          <PDFViewer width="100%" height="600px">
            <MyDocument agreementDetails={agreementDetails} />
          </PDFViewer>
        </ModalBody>

        <ModalFooter
          style={{
            backgroundColor: "whitesmoke",
          }}
        >
          <Button
            className="btn btn-sm ms-auto mt-3"
            color="primary"
            onClick={handleGeneratePDF}
          >
            PDF Yazdır
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AgreementViewModal2;
