import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import "./print.css";
import "../../assets/scss/agreement.css";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAgreementDetails } from "../../slices/apps/agreementSlice";

// diğer importlar
import moment from "moment";
import parse from "html-react-parser";

// pdf
import { useReactToPrint } from "react-to-print";

// görsel importlar
import Logo from "../../assets/images/logo/global_assitance_logo.png";

const AgreementViewModal = ({ id }) => {
  const dispatch = useDispatch();
  const onBeforePrintResolve = useRef(null);

  // states
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { agreementDetails } = useSelector((state) => {
    return {
      agreementDetails: state.agreement.agreementDetails,
    };
  }, shallowEqual);

  // states

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchAgreementDetails(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  const handleOnAfterPrint = useCallback(() => {
    setLoading(false);
  }, []);

  const handleOnBeforePrint = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforePrintResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  useEffect(() => {
    if (typeof onBeforePrintResolve.current === "function") {
      onBeforePrintResolve.current();
    }
  }, [onBeforePrintResolve.current]);

  const componentRef = useRef(null);
  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: `${agreementDetails.Aggreement?.AggreementName} - ${agreementDetails.Aggreement?.AggrementNo}`,
    onAfterPrint: handleOnAfterPrint,
    onBeforePrint: handleOnBeforePrint,
  });

  return (
    <Fragment>
      <span
        id={`agreement-tooltipTop-${id}`}
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "3px",
        }}
        onClick={() => tog_large(true)}
      >
        <i
          className="bx bx-search text-dark"
          style={{
            fontSize: "20px",
          }}
        />
      </span>

      <UncontrolledTooltip
        placement="top"
        target={`agreement-tooltipTop-${id}`}
      >
        Sözleşme Görüntüle
      </UncontrolledTooltip>

      <Modal
        size="xl"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title"
          style={{
            backgroundColor: "whitesmoke",
            paddingBottom: "10px",
          }}
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          {agreementDetails.Aggreement?.AggreementName} -{" "}
          {agreementDetails.Aggreement?.AggrementNo}
        </ModalHeader>

        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            maxHeight: "calc(90vh - 100px)",
            overflowY: "auto",
            overflowX: "auto",
            backgroundColor: "whitesmoke",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%",
            }}
          >
            <div
              ref={componentRef}
              className="page"
              style={{
                width: "210mm",
                minWidth: "210mm",
                height: "auto",
                backgroundColor: "#ffffff",
                pageBreakBefore: "always",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <img
                  src={Logo}
                  alt="Global Yol Yardım"
                  style={{
                    height: "auto",
                    width: "90px",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                />

                <div>
                  {/* Adres */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginRight: "10px",
                      flex: 1, // Eşit genişlik
                    }}
                  >
                    <div
                      style={{
                        textAlign: "end",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: "10px",
                          color: "#CA1224",
                        }}
                      >
                        Ziyabey Cad. Irmak Sk. Kızılcık Plaza No: 624/5
                        BALGAT/ANKARA
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* Telefon */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "20px",
                        flex: 1, // Eşit genişlik
                      }}
                    >
                      <i className="ri-phone-fill text-primary fs-18 align-middle me-1"></i>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "10px",
                          color: "#CA1224",
                        }}
                      >
                        0 850 242 70 42
                      </p>
                    </div>

                    {/* E-posta */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "20px",
                        flex: 1, // Eşit genişlik
                      }}
                    >
                      <i className="ri-mail-fill text-primary fs-18 align-middle me-1"></i>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "10px",
                          color: "#CA1224",
                        }}
                      >
                        info@globalyolyardim.com
                      </p>
                    </div>

                    {/* Web sitesi */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                        flex: 1, // Eşit genişlik
                      }}
                    >
                      <i className="bx bx-world text-primary fs-18 align-middle me-1"></i>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "10px",
                          color: "#CA1224",
                        }}
                      >
                        globalyolyardim.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <h6
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                  marginRight: "10px",
                  marginBottom: "10px",
                  color: "black",
                  fontSize: 12,
                }}
              >
                GLOBAL YOL YARDIM HİZMET SÖZLEŞMESİ
              </h6>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {/* Sol Kısım (Sözleşme Bilgileri) */}
                <table
                  style={{
                    width: "48%",
                    borderCollapse: "collapse",
                    border: "1px solid #ddd",
                    fontFamily: "'Arial', sans-serif",
                    fontSize: "9px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        colSpan="4"
                        style={{
                          padding: "8px",
                          textAlign: "center",
                          backgroundColor: "#151D33",
                          color: "#fff",
                          border: "1px solid #151D33",
                        }}
                      >
                        SÖZLEŞME BİLGİLERİ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        SÖZLEŞME NO
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {agreementDetails.Aggreement?.AggrementNo}
                      </td>

                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        PAKET
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {agreementDetails.Aggreement?.AggreementName.replace(
                          "Paket",
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        TARİH
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {moment(
                          agreementDetails.Aggreement?.CreateDatetime
                        ).format("DD.MM.YYYY")}
                      </td>

                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        SÜRE
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        1 Yıl
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        BAŞLANGIÇ
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {moment(agreementDetails.Aggreement?.StartDate).format(
                          "DD.MM.YYYY"
                        )}
                      </td>

                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        BİTİŞ
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {moment(agreementDetails.Aggreement?.EndDate).format(
                          "DD.MM.YYYY"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* Sağ Kısım (Araç Bilgileri) */}
                <table
                  style={{
                    width: "48%",
                    borderCollapse: "collapse",
                    border: "1px solid #ddd",
                    fontFamily: "'Arial', sans-serif",
                    fontSize: "9px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        colSpan="4"
                        style={{
                          padding: "8px",
                          textAlign: "center",
                          backgroundColor: "#151D33",
                          color: "#fff",
                          border: "1px solid #151D33",
                        }}
                      >
                        KULLANICI | ARAÇ BİLGİLERİ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        İSİM SOYİSİM
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {agreementDetails.Customer?.CustomerName}
                      </td>

                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        ADRES
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {agreementDetails.Customer?.District}/
                        {agreementDetails.Customer?.City}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        PLAKA
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {agreementDetails.Aggreement?.CarPlate}
                      </td>

                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        MARKA
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {agreementDetails.Aggreement?.CarBrand}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        MODEL
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {agreementDetails.Aggreement?.CarModel}
                      </td>

                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        TİP
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          borderBottom: "1px solid #ddd",
                          borderRight: "1px solid #ddd",
                          width: "25%",
                          background: "#E8E8E8",
                        }}
                      >
                        {agreementDetails.Aggreement?.CarCategory}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <table
                style={{
                  width: "calc(100% - 20px)", // 20px içeri alacak şekilde toplam genişlik
                  borderCollapse: "collapse",
                  marginTop: "20px",
                  border: "1px solid #ddd",
                  fontFamily: "'Arial', sans-serif",
                  fontSize: "9px",
                  padding: "0", // Kenar boşluğunu kaldırdık
                  marginLeft: "10px", // Sol kenara 10px içeri
                  marginRight: "10px", // Sağ kenara 10px içeri
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        backgroundColor: "#151D33", // Başlık arka plan rengi
                        color: "#fff", // Yazı rengi beyaz
                        borderRight: "1px solid #ddd", // Başlıklar arasında çizgi
                        width: "33%",
                      }}
                    >
                      NET PRİM
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        backgroundColor: "#151D33", // Başlık arka plan rengi
                        color: "#fff", // Yazı rengi beyaz
                        borderRight: "1px solid #ddd", // Başlıklar arasında çizgi
                        width: "33%",
                      }}
                    >
                      KDV
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        backgroundColor: "#151D33", // Başlık arka plan rengi
                        color: "#fff", // Yazı rengi beyaz
                        width: "33%",
                      }}
                    >
                      BÜRÜT PRİM
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderRight: "1px solid #ddd",
                        background: "#E8E8E8",
                      }}
                    >
                      {new Intl.NumberFormat("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(agreementDetails.Aggreement?.Price / 1.2)}{" "}
                      TL
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderRight: "1px solid #ddd",
                        background: "#E8E8E8",
                      }}
                    >
                      %20
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        background: "#E8E8E8",
                      }}
                    >
                      {new Intl.NumberFormat("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(agreementDetails.Aggreement?.Price)}{" "}
                      TL
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <div
                style={{
                  marginTop: "20px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  fontSize: "10px",
                }}
              >
                <div className="agreement-content">
                  {parse(agreementDetails?.AgreementDetail?.AgreementContent || "")}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter
          style={{
            backgroundColor: "whitesmoke",
          }}
        >
          <Button
            className="btn btn-sm ms-auto mt-3"
            color="primary"
            onClick={printFn}
            disabled={loading}
          >
            PDF Yazdır
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AgreementViewModal;
