import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchBulkPackages } from "../../slices/apps/packageSlice";

// sayfalar, modallar vb.
import BulkSaleTable from "./BulkSaleTable";

const BulkSale = () => {
  const dispatch = useDispatch();

  const { bulkPackagesRefresh } = useSelector((state) => {
    return {
      bulkPackagesRefresh: state.package.bulkPackagesRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchBulkPackages());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, bulkPackagesRefresh]);

  document.title = "Toplu Satış Listesi | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <BulkSaleTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BulkSale;
