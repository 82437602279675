import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAllPackages } from "../../slices/apps/packageSlice";

// sayfalar, modallar vb.
import PackageTable from "./PackageTable";

const PackageAdmin = () => {
  const dispatch = useDispatch();

  const { allPackagesRefresh } = useSelector((state) => {
    return {
      allPackagesRefresh: state.package.allPackagesRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchAllPackages());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, allPackagesRefresh]);

  document.title = "Paket Listesi | Global Yol Yardım";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <PackageTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PackageAdmin;
